<template>
  <UiBaseLayout>
    <!-- Properties import manager -->
    <PropertiesImportManager
      :visible="showPropertiesImportManager"
      :properties-j-s-o-n="propertiesJSON"
      :import-file-name="importFileName"
      :properties="properties"
      :property-metadata="[]"
      :selectable-terms="
        () => {
          return [];
        }
      "
      @close="closeImportWizard()"
    />
    <input ref="fileUpload" type="file" hidden @input="handleSelectedFile" />
    <template #footer>
      <VersionFooter class="mt-auto" />
    </template>
    <div class="d-flex h-100">
      <b-tabs
        v-model="activeTabIndex"
        pills
        vertical
        class="border-right border-tabs-color m-0 left-tabs pr-0"
        active-nav-item-class="active-left-tab text-primary"
      >
        <b-tab
          v-for="(type, index) in propertiesByType"
          :key="type"
          class="text-primary"
          :title-link-class="{ 'opacity-50': activeTabIndex !== index }"
          :title="$t(`product.${type.toLowerCase()}s`)"
        />
      </b-tabs>
      <div class="center-table px-2">
        <UiPaddedArea class="p-0">
          <UiToolbar>
            <div class="flex-spacer" />
            <b-input-group class="search-group">
              <b-form-input
                v-model="searchStr"
                class="search-input"
                :placeholder="$t(`product.search_properties`)"
              />
              <b-icon icon="search" class="search-input-icon"></b-icon>
            </b-input-group>
            <div class="flex-spacer" />
            <b-button
              v-if="!isGuest(tenant)"
              variant="primary"
              @click="createNewProperty"
            >
              <i class="fas fa-plus mr-2"></i
              >{{
                $t("product.add", {
                  property: $t(`product.${activeTab.toLowerCase()}`),
                })
              }}
            </b-button>
            <template #side>
              <b-dropdown variant="outline-primary" no-caret right>
                <template #button-content>
                  <b-icon-three-dots />
                </template>
                <template v-if="!isReadOnlyMode && isAdmin(tenant)">
                  <b-dropdown-item>
                    <b-button @click="$refs.fileUpload.click()">
                      {{ $t("product.import-shared-properties") }}
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                </template>
                <b-dropdown-item @click="exportAllProperties">
                  <i class="fas fa-download"></i>
                  {{ $t("product.msg-export-all-properties") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </UiToolbar>
        </UiPaddedArea>
        <UiLoadingArea :is-loading="isLoading">
          <SharedPropertiesIndex
            ref="properties"
            :label="$t('product.dimension')"
            :properties="computedProperties"
            :definitions="definitionListSuggestions"
            :teams="teams"
            :property="activeTab"
            :property-metadata="computedPropertyMetadataByType"
            :columns="computedColumns"
            :search="searchStr"
            :types="computedTypes"
            @property-created="clearSearch"
          />
        </UiLoadingArea>
      </div>
    </div>
  </UiBaseLayout>
</template>

<script>
import { Message } from "element-ui";
import { mapActions, mapState, mapGetters } from "vuex";
import { readFromFile, readZipImportedContent } from "../util";
import VersionFooter from "../components/VersionFooter.vue";
import { SharedPropertiesIndex } from "../components/SharedProperties";
import * as api from "../api";
import { propertyMetadataByType } from "../components/Metadata/PropertyMetadataMixin";
import TeamsMixin from "../components/TeamsMixin";
import PropertiesMixin from "../components/PropertiesMixin";
import PropertiesImportManager from "../components/PropertiesImportManager.vue";

export default {
  name: "SharedProperties",
  components: {
    SharedPropertiesIndex,
    PropertiesImportManager,
    VersionFooter,
  },
  mixins: [TeamsMixin, PropertiesMixin],
  props: {
    tenantSlug: {
      type: [String],
      default: "Sandbox",
    },
  },
  data: () => ({
    searchStr: "",
    teams: [],
    defaultTypes: ["number", "monetary", "date", "datetime", "boolean", "text"],
    unitTypes: ["monetary", "distance", "number", "night"],
    questionnaireTypes: ["number", "text", "text"],
    dimensionColumns: [
      "teams",
      "color",
      "definition_list",
      "name",
      "API",
      "fields",
    ],
    inputColumns: ["teams", "name", "value_type"],
    computeColumns: ["teams", "name"],
    outputColumns: [
      "teams",
      "name",
      "definition_list",
      "value_type",
      "dialect_type",
    ],
    unitColumns: ["teams", "name", "value_type"],
    // import csp
    propertiesJSON: [],
    importFileName: "",
    showPropertiesImportManager: false, // zip | json
    propertiesByType: ["DIMENSION", "INPUT", "COMPUTED", "OUTPUT", "UNIT"],
  }),
  computed: {
    ...mapState("auth", ["tenant"]),
    ...mapState("definitionListSuggestions", {
      definitionListSuggestions: "suggestions",
    }),
    ...mapState("sharedProperty", ["currentType", "isLoading", "properties"]),
    ...mapGetters("sharedProperty", [
      "dimensions",
      "inputs",
      "computes",
      "outputs",
      "units",
    ]),
    activeTab: {
      get() {
        return this.currentType || "DIMENSION";
      },
      set(activeTab) {
        // noinspection JSValidateTypes
        this.setCurrentSharedPropertyType({ type: activeTab });
      },
    },
    activeTabIndex: {
      get() {
        return this.propertiesByType.indexOf(this.activeTab);
      },
      set(activeTabIndex) {
        // noinspection JSValidateTypes
        this.setCurrentSharedPropertyType({
          type: this.propertiesByType[activeTabIndex],
        });
      },
    },
    filteredDimensions() {
      return this.filteredProperties(this.dimensions);
    },
    filteredInputs() {
      return this.filteredProperties(this.inputs);
    },
    filteredComputes() {
      return this.filteredProperties(this.computes);
    },
    filteredOutputs() {
      return this.filteredProperties(this.outputs);
    },
    filteredUnits() {
      return this.filteredProperties(this.units);
    },
    computedProperties() {
      switch (this.activeTab) {
        case "DIMENSION":
          return this.filteredDimensions;
        case "INPUT":
          return this.filteredInputs;
        case "COMPUTED":
          return this.filteredComputes;
        case "OUTPUT":
          return this.filteredOutputs;
        case "UNIT":
          return this.filteredUnits;
        default:
          return [];
      }
    },
    computedColumns() {
      switch (this.activeTab) {
        case "DIMENSION":
          return this.dimensionColumns;
        case "INPUT":
          return this.inputColumns;
        case "COMPUTED":
          return this.computeColumns;
        case "OUTPUT":
          return this.outputColumns;
        case "UNIT":
          return this.unitColumns;
        default:
          return [];
      }
    },
    computedTypes() {
      switch (this.activeTab) {
        case "UNIT":
          return this.unitTypes;
        default:
          return this.defaultTypes;
      }
    },
    computedPropertyMetadataByType() {
      let key = "";
      switch (this.activeTab) {
        case "DIMENSION":
          key = "dimensions";
          break;
        case "INPUT":
          key = "inputs";
          break;
        case "COMPUTED":
          key = "computes";
          break;
        case "OUTPUT":
          key = "outputs";
          break;
        case "UNIT":
          key = "units";
          break;
        default:
          break;
      }
      return propertyMetadataByType(key);
    },
  },
  watch: {
    currentType: {
      handler(currentType) {
        this.fetch(currentType);
      },
    },
  },
  mounted() {
    this.fetch();
    if (this.isOwner(this.tenant) && !this.teams.length) {
      this.fetchTeams();
    }
    if (!this.isOwner(this.tenant)) {
      this.teams = [];
    }
    this.fetchDefinitions();
  },
  methods: {
    // csp import
    closeImportWizard() {
      this.showPropertiesImportManager = false;
      if (this.$refs.fileInput && this.$refs.fileInput.value) {
        this.$refs.fileInput.value = null;
      }
      this.propertiesJSON = [];
    },

    async handleSelectedFile(ev, dropped = false) {
      let json = null;
      const [file] = dropped ? ev.dataTransfer.files : ev.target.files;

      if (!file.name.match(/(.json|.zip)$/)) {
        Message.error("File import must be of type .json or .zip");
        return;
      }

      this.importFileName = file.name;

      this.propertiesJSON = [];
      this.showPropertiesImportManager = true; // zip | json

      if (file.name.endsWith(".zip")) {
        try {
          json = await readZipImportedContent(file, "SharedProperties");
        } catch (err) {
          Message.error(err.message);
        }
      } else {
        json = await readFromFile(file);
      }
      this.propertiesJSON = json;
    },
    ...mapActions("definitionListSuggestions", { fetchDefinitions: "fetch" }),
    ...mapActions("sharedProperty", [
      "setCurrentSharedPropertyType",
      "fetchSharedProperties",
    ]),
    fetch() {
      // noinspection JSValidateTypes
      this.fetchSharedProperties();
    },
    clearSearch() {
      this.searchStr = "";
    },
    propertyMatchSearch(query, value) {
      if (value && typeof value === "string") {
        return value.toLowerCase().includes(query.toLowerCase());
      }
      if (value && typeof value === "object" && value.displayName) {
        return Object.entries(value.displayName).some((val) =>
          this.propertyMatchSearch(query, val[1])
        );
      }
      return false;
    },
    filteredProperties(properties) {
      const propertiesOverloaded = properties.map((p) => {
        return {
          ...p,
          ...({
            teamName: (this.teams.find((g) => g.id === p.team) || {}).label,
          } || "not found"),
          ...({
            definitionName: (
              this.definitionListSuggestions.find(
                (df) => df.key === p.definitionListId
              ) || {}
            ).label,
          } || "not found"),
        };
      });

      if (!this.searchStr || this.searchStr.length === 0)
        return propertiesOverloaded || [];

      return propertiesOverloaded.filter((p) => {
        return (
          this.propertyMatchSearch(this.searchStr, p.name) ||
          this.propertyMatchSearch(this.searchStr, p.metadata)
        );
      });
    },
    async fetchTeams() {
      try {
        const { data } = await api.listTeams();
        if (data) {
          this.teams = data;
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    createNewProperty() {
      if (this.$refs.properties) {
        this.$refs.properties.showCreationRow();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

.only-show-predefined-colors {
  .el-color-dropdown__value,
  .el-color-dropdown__main-wrapper {
    display: none;
  }
}

.form-control {
  max-width: initial;
}

.el-checkbox {
  margin-right: 10px;
}

.error {
  color: #cc0000;
}

.subtitle {
  color: #909399;
  font-weight: bold;
}

.priority {
  width: 100%;
}

.template_header {
  font-size: 1.2em;
  color: #909090;
}

.template_icon {
  width: 2em;
  height: 2em;
}

.template_link span {
  font-size: 18px;
  font-style: italic;
}

.el-button {
  text-transform: none;
  font-weight: 400;
}

.el-button.is-circle {
  border-width: 0;
  background-color: transparent;
  margin: 0;
  padding: 5px;
}

.flipY {
  transform: scaleY(-1);
}

.ui_import {
  position: absolute;
  top: 89px;
  right: 22px;
}

.round-button {
  background: white;
  font-size: 25px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #ccc;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  margin: 0 0 0 0;
  box-shadow: 0 2px 2px 2px rgba(116, 116, 117, 0.05);
}

.select-type {
  height: 41px;
}

.group-gap {
  gap: 8px;
}
</style>