<template>
  <el-dialog
    v-selector
    :visible="isVisible"
    width="95%"
    @close="$emit('close')"
  >
    <h5 slot="title" class="title">
      {{ title ? title : $t(`product.create-new-${lowerProductType}`) }}
    </h5>
    <Loading v-if="isLoading" message="Creating new product…" />
    <div v-if="!selectTemplate" class="container">
      <div class="row justify-content-center align-items-center">
        <form class="pb-4 px-2 col-9">
          <div
            class="form-group col-12"
            :class="{ 'has-danger': errors.length }"
          >
            <label class="product-name">{{
              $te(productNameTranslationKey)
                ? $t(productNameTranslationKey)
                : $t("product.product_name")
            }}</label>
            <input
              id="product_name"
              ref="newProductName"
              v-model="newProductName"
              data-cy="product_name"
              type="text"
              class="form-control ui_input_name"
              maxlength="128"
              :readonly="isLoading"
              :class="{ 'form-control-danger': errors.length }"
              :placeholder="$t(`product.${lowerProductType}-name-placeholder`)"
              @keydown.enter.prevent
              @input="checkValid($event)"
            />
          </div>
          <div
            class="form-group col-12"
            :class="{ 'has-danger': errors.length }"
          >
            <label class="product-name">{{ $t("product.author") }}</label>
            <input
              id="product_author"
              ref="newProductAuthor"
              v-model="newProductAuthor"
              data-cy="product_author"
              type="text"
              class="form-control ui_input_name"
              maxlength="128"
              :readonly="isLoading"
              :class="{ 'form-control-danger': errors.length }"
              @keydown.enter.prevent
              @input="checkValid($event)"
            />
          </div>
          <div
            class="form-group col-12"
            :class="{ 'has-danger': errors.length }"
          >
            <label class="product-name">Team Attribution</label>
            <b-form-select v-model="selectedTeam">
              <b-form-select-option
                v-for="team in availableTeams"
                :key="team.teamId"
                :value="team.id"
              >
                {{ team.label }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div
            class="form-group col-12"
            :class="{ 'has-danger': errors.length }"
          >
            <label class="product-name">Insurance type</label>
            <b-form-select v-model="selectedInsuranceType">
              <b-form-select-option
                v-for="elt in insuranceTypeEnum"
                :key="elt.id"
                :value="elt.value"
              >
                {{ elt.label }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div
            v-if="errors.length"
            class="alert top-content-bar top-content-bar--warning"
            role="alert"
          >
            <div class="top-content-bar-message">{{ errors.join(", ") }}</div>
          </div>
        </form>
      </div>

      <div
        v-if="newProductName"
        class="row justify-content-center align-items-center"
      >
        <div
          v-if="productTypes.PRODUCT === productType"
          class="mask m-4 p-4 text-center"
          data-cy="template_product"
          @click="showTemplates"
        >
          <i class="fas fa-sitemap mb-4"></i>
          <div class="legend">{{ $t("product.template") }}</div>
          <div class="sub-legend">{{ $t("product.template-detail") }}</div>
        </div>
        <div
          v-if="productTypes.QUESTIONNAIRE !== productType"
          class="mask m-4 p-4 text-center ui_button_blank"
          data-cy="blank-product"
          type="primary"
          @click="createNewProduct(false)"
        >
          <i class="far fa-file mb-4"></i>
          <div class="legend">{{ $t("product.blank") }}</div>
          <div class="sub-legend">
            {{ $t(`product.${lowerProductType}-blank-detail`) }}
          </div>
        </div>

        <div
          v-for="subtype in subtypes"
          :key="subtype"
          class="mask m-4 p-4 text-center ui_button_blank"
          type="primary"
          @click="createNewProduct(false, subtype)"
        >
          <i class="far fa-file mb-4"></i>
          <div class="legend">{{ subtype }}</div>
          <div class="sub-legend">
            {{ $t(`product.${lowerProductType}-blank-detail`) }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectTemplate && !isLoading" class="container">
      <span class>
        {{ $t("product.select_template") + " (" + templates.length + ")" }}
      </span>
      <div class="row pt-4">
        <div
          v-for="item in templates"
          :key="item.id"
          class="col-6 mb-4"
          @click="createNewProduct(item.id)"
        >
          <div class="product-template ui_button_template">
            <div class="row">
              <div class="col-12">
                <span class="template-name">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <UiButton v-if="selectTemplate" :disabled="isLoading" @click="back">{{
        $t("action.back")
      }}</UiButton>
      <UiButton :disabled="isLoading" @click="$emit('close')">{{
        $t("action.cancel")
      }}</UiButton>
    </span>
  </el-dialog>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions, mapGetters } from "vuex";
import Loading from "./Loading.vue";
import { productTypes } from "../const/product";

export default {
  name: "NewProductCreationModal",
  components: {
    Loading,
  },
  props: {
    visible: Boolean,
    title: { type: String, default: undefined },
    productType: { type: String, default: productTypes.PRODUCT },
  },
  data: () => ({
    errors: [],
    selectedTemplateType: null,
    newProductName: null,
    DEFAULT_AUTHOR: "AXA",
    newProductAuthor: null,
    isLoading: false,
    isVisible: false,
    templateId: false,
    selectTemplate: false,
    selectedTeam: null,
    selectedInsuranceType: "product",
    productTypes,
  }),
  computed: {
    ...mapGetters("auth", ["isMember", "isOwner"]),
    ...mapState("product", ["product"]),
    ...mapState("auth", ["tenant"]),
    ...mapState("products", ["templates"]),
    ...mapState("teams", ["teams"]),
    lowerProductType() {
      return this.productType.toLowerCase();
    },
    subtypes() {
      return this.productTypes.QUESTIONNAIRE === this.productType
        ? ["Diagnostic Tree", "IFPS", "Other"]
        : [];
    },
    productNameTranslationKey() {
      return `product.${this.productType.toLowerCase()}_name`;
    },
    availableTeams() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.filter(
          (t) =>
            t.tenant === this.tenant &&
            (this.isMember(t.slug) || this.isOwner(this.defaultTeam.slug))
        )
      );
    },
    defaultTeam() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.find((t) => t.slug === this.tenant)
      );
    },
    insuranceTypeEnum() {
      return [
        {
          id: 1,
          value: "clause",
          label: this.$t("product.insurance_type.clause"),
        },
        {
          id: 2,
          value: "policy",
          label: this.$t("product.insurance_type.policy"),
        },
        {
          id: 3,
          value: "product",
          label: this.$t("product.insurance_type.product"),
        },
      ];
    },
  },
  watch: {
    visible(visible) {
      this.selectTemplate = false;
      this.newProductName = null;
      this.newProductAuthor = this.DEFAULT_AUTHOR;
      this.isVisible = visible;
      if (this.isVisible) {
        Vue.nextTick(() => {
          if (this.$refs.newProductName) this.$refs.newProductName.focus();
        });
      }
    },
    teams() {
      this.setDefaultTeam();
    },
  },
  mounted() {
    this.setDefaultTeam();
  },
  methods: {
    ...mapActions("product", [
      "createProduct",
      "createProductFromTemplate",
      "createTemplate",
      "createQuestionnaire",
    ]),
    ...mapActions("products", ["fetchAllTemplates"]),
    async tryCreate(closure) {
      try {
        await closure();
        await this.redirectToCurrentProduct();
        this.$emit("close");
      } catch (error) {
        this.errors = [error.message];
      } finally {
        this.selectTemplate = false;
        this.isLoading = false;
        this.isVisible = false;
        if (this.errors.length > 0) {
          setTimeout(() => {
            this.errors = [];
          }, 3000);
        }
      }
    },
    async redirectToCurrentProduct() {
      await this.$router.push({
        name: "product-home",
        params: { productId: this.product.id, refresh: true },
      });
    },
    async createNewProduct(templateId = false, subtype) {
      this.errors = [];
      if (!this.newProductName) {
        this.errors = [`${this.$t("product.name-required")}`];
        return;
      }
      if (!this.selectedInsuranceType) {
        this.errors = [`${this.$t("product.insurance-type-required")}`];
        return;
      }
      this.templateId = templateId;
      this.isLoading = true;
      const createOptions = {
        name: this.newProductName,
        author: this.newProductAuthor,
        teamId: this.selectedTeam,
        insuranceType: this.selectedInsuranceType,
      };
      switch (this.productType) {
        case productTypes.PRODUCT:
          await this.tryCreate(async () => {
            if (!this.templateId) {
              await this.createProduct(createOptions);
            } else {
              await this.createProductFromTemplate({
                ...createOptions,
                template: this.templateId,
              });
            }
          });
          break;
        case productTypes.TEMPLATE:
          await this.tryCreate(async () => {
            await this.createTemplate(createOptions);
          });
          break;
        case productTypes.QUESTIONNAIRE:
          await this.tryCreate(async () => {
            await this.createQuestionnaire({
              ...createOptions,
              subtype,
            });
          });
          break;
        default:
          throw new Error("Invalid product type");
      }
    },
    async showTemplates() {
      this.selectTemplate = true;
      await this.fetchAllTemplates();
    },
    back() {
      if (this.selectTemplate) {
        this.selectTemplate = false;
      }
    },
    checkValid(e) {
      const regex = /[^\w)(\s-]/gi;
      if (e.target.id === "product_name")
        this.newProductName = e.target.value.replace(regex, "");
      else if (e.target.id === "product_author")
        this.newProductAuthor = e.target.value
          ? e.target.value.replace(regex, "")
          : this.DEFAULT_AUTHOR;
    },
    setDefaultTeam() {
      if (this.availableTeams && this.availableTeams.length) {
        const foundDefaultTeam = this.availableTeams.find(
          (t) => t.slug === this.tenant
        );
        this.selectedTeam = foundDefaultTeam
          ? foundDefaultTeam.id
          : this.availableTeams[0].id;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";
.form {
  background-color: white;
}

.form-control {
  max-width: 100%;
  text-align: center;
}

.el-dialog__header {
  height: 58px;
  width: 1003px;
  border-radius: 4px 4px 0 0;
  background-color: #ededf4;
}
.title {
  height: 25px;
  color: #2b3034;
  font-size: 20px;
  line-height: 25px;
}

.fas,
.far {
  font-size: 30px;
}

.mask {
  box-sizing: border-box;
  height: 170px;
  width: 200px;
  border: 1px solid rgba(43, 48, 52, 0.1);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 143, 0.03);
  border-radius: 10px;

  &:hover {
    border: 1px solid #00008f;
    background-color: rgba(0, 0, 143, 0.03);
    color: #00008f;
    cursor: pointer;

    svg {
      filter: none;
    }
  }
}

.icon-category {
  height: 60px;
  width: 60px;
  color: #8f8f8f;
  filter: invert(63%) sepia(0%) saturate(16%) hue-rotate(156deg) brightness(92%)
    contrast(76%);
}

.icon {
  color: #8f8f8f;
  filter: invert(63%) sepia(0%) saturate(16%) hue-rotate(156deg) brightness(92%)
    contrast(76%);
  height: 45px;
  width: auto;
}

.legend {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 20px;
  text-align: center;
}

.sub-legend {
  word-break: break-word;
}

.product-template {
  box-sizing: border-box;
  padding: 9px;
  border: 1px solid rgba(43, 48, 52, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 143, 0.03);
  cursor: pointer;
  &:hover {
    color: #00008f;
    border: 1px solid #00008f;
    background-color: rgba(0, 0, 143, 0.03);
    svg {
      filter: none;
    }
  }
}

.bl-title {
  height: 20px;
  opacity: 0.5;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.17px;
  line-height: 20px;
}

.template-name {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 20px;
  max-height: 40px;
  overflow: hidden;
  white-space: normal;
  vertical-align: text-bottom;
}

input.form-control {
  box-sizing: border-box;
  height: 43px;
  border: 1px solid rgba(0, 0, 143, 0.2);
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(0, 0, 143, 0.03);

  &::placeholder {
    height: 20px;
    max-width: 90%;
    text-align: left;
    opacity: 0.4;
    color: #00008f;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.17px;
    line-height: 20px;
  }
}

.product-name {
  height: 18px;
  width: 100%;
  opacity: 0.78;
  color: #2b3034;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 18px;
  white-space: nowrap;
}
</style>
