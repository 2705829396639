<template>
  <el-dialog visible :before-close="close">
    <div slot="title">
      {{ $t("product.terms.order-definitions-modal.add-priority") }}
    </div>
    <div class="d-flex align-items-center justify-content-between pr-5">
      <UiInlineDropDown
        class="pr-5"
        label="Definition"
        :toggle-editing="true"
        data-cy="definitionsList"
        :value="definitionId"
        :variant="'default'"
        :items="definitionsFiltered"
        :handle-change="(val) => changeDefinition(val)"
      />
      <UiInlineDropDown
        clearable
        :is-editable="true"
        label="Priority"
        data-cy="prioritySelection"
        :value="priority"
        :items="priorities"
        :handle-change="(val) => changePriority(val)"
        :toggle-editing="true"
      />
    </div>
    <div slot="footer">
      <el-button
        :disabled="!canSave"
        type="primary"
        size="small"
        class="ui_button_save"
        @click="save"
        >{{ $t("product.terms.order-definitions-modal.save") }}</el-button
      >
      <el-button size="small" @click="$emit('close')">
        {{ $t("product.terms.order-definitions-modal.cancel") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Message } from "element-ui";
import { mapState } from "vuex";
import * as api from "../api";
import { noop } from "../util";

export default {
  name: "DefinitionAddPriority",
  props: {
    property: {
      type: String,
      required: true,
    },
    definitions: {
      type: Array,
      required: true,
    },
    priorities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { priority: null, definitionId: null };
  },
  computed: {
    ...mapState("auth", ["tenant"]),
    ...mapState("product", ["product"]),
    definitionsFiltered() {
      const MAX_CHAR = 100;
      return (this.definitions || []).map((df) => ({
        key: df._id,
        label: `${
          (df.displayName && df.displayName.length > MAX_CHAR
            ? `${df.displayName.substring(0, MAX_CHAR)}...`
            : df.displayName) || df.primaryKey
        } - ${
          df.term.name.length > MAX_CHAR
            ? `${df.term.name.substring(0, MAX_CHAR)}...`
            : df.term.name
        }`,
      }));
    },
    canSave() {
      return (
        !!this.priority &&
        this.priority !== "" &&
        !!this.definitionId &&
        this.definitionId !== ""
      );
    },
  },
  methods: {
    changePriority(value) {
      this.priority = value;
    },
    changeDefinition(definitionId) {
      this.definitionId = definitionId;
    },
    async save() {
      try {
        await api.updateTermDefinitionsOrder(
          { id: this.definitionId, priority: this.priority },
          this.property,
          this.product.id,
          this.tenant
        );
        Message.success(
          this.$t("product.terms.order-definitions-modal.saved-successfully")
        );
        this.$emit("update");
        this.close();
      } catch (err) {
        noop(err);
        Message.error(
          this.$t("product.terms.order-definitions-modal.saved-failed")
        );
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
