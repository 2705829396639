<template>
  <div v-selector>
    <div class="sidebar pl-5" :class="{ hidden }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  components: {},
  props: {
    hidden: Boolean,
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  position: relative;
  background: white;
  border-right: 1px solid rgba(0, 0, 143, 0.1);
  box-shadow: 0 30px 10px 2px rgba(0, 0, 143, 0.05);
  z-index: 10;
  height: 100%;
  width: 400px;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  margin-left: 0;
  overflow: visible auto;

  &.hidden {
    margin-left: -400px;
    opacity: 0.1;
  }
}

button {
  cursor: pointer;
  background: white;
  border: 0;
  color: gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.icon {
  width: 16px;
  height: 16px;
}
</style>
