<template>
  <a
    href="#"
    class="SearchResultItem"
    :title="tooltipContent(item)"
    @click="$emit('click', item)"
  >
    <ul class="Path Path--Banner">
      <li class="Path__Item Path__Item--Icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3h7zM7 9H4V5h3v4zm10 6h3v4h-3v-4zm0-10h3v4h-3V5z"
          />
        </svg>
      </li>
      <li class="Path__Item Path__Item--Capitalize">
        {{ item.path[0] }}
      </li>
    </ul>
    <div class="Content">
      <div class="Label">
        <span v-if="item.display_name" class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.display_name)" /><sup
            ><span class="Highlight" v-html="highlight(item.label)" /></sup
        ></span>
        <span v-else class="Label__Value"
          ><span class="Highlight" v-html="highlight(item.label)"
        /></span>
      </div>
      <div class="Action">
        <a class="Action__Link" href="#"
          ><span class="Action__LinkLabel">Go to</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path
              d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"
            />
          </svg>
        </a>
      </div>
    </div>
  </a>
</template>

<script>
import ResultItemMixin from "./ResultItemMixin";

export default {
  name: "ResultItemRule",
  mixins: [ResultItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    tooltipContent() {
      return "Foo / Bar";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "ResultItem.scss";
</style>
