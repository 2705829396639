<template>
  <div class="PropertySidebar">
    <UiButton
      v-if="allowExport"
      variant="secondary"
      icon="el-icon-upload2"
      @click="() => $emit('export-csp')"
    >
      {{ $t("product.msg-export-all-properties") }}
    </UiButton>
    <UiButton
      variant="secondary"
      icon="el-icon-arrow-right"
      @click="() => $emit('expand-toggle')"
    >
      {{ $t("product.msg-expand-all-properties") }}
    </UiButton>
    <LanguageSelector
      label="Source language"
      :current-language="sourceLanguage"
      :available-languages="sourceLanguages"
      @language-change="(v) => $emit('source-language-change', v)"
    />
    <LanguageSelector
      label="Target language"
      :current-language="targetLanguage"
      :available-languages="targetLanguages"
      @language-change="(v) => $emit('target-language-change', v)"
    />
  </div>
</template>

<script>
import LanguageSelector from "../Translation/LanguageSelector";

export default {
  name: "PropertySidebar",
  components: { LanguageSelector },
  props: {
    sourceLanguage: {
      type: String,
      required: true,
    },
    targetLanguage: {
      type: String,
      default: null,
    },
    sourceLanguages: {
      type: Array,
      required: true,
    },
    targetLanguages: {
      type: Array,
      required: true,
    },
    allowExport: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    classNames() {
      const { variant } = this;
      return {
        UiLabel: true,
        "UiLabel--Bold": variant === "bold",
        "UiLabel--Light": variant === "light",
        "UiLabel--Error": variant === "error",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.PropertySidebar {
  display: flex;
  > * {
    margin-left: 2rem;
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
