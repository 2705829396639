/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// eslint-disable-next-line import/no-extraneous-dependencies
import { JSONOperation } from "@axatechlab/cc-json-operations";

export default {
  methods: {
    getValidCCJsonStringEpxr(value = "{}") {
      try {
        const response = JSONOperation.toString(JSON.parse(value));
        const { status, result } = response;
        if (status === "SUCCESS") {
          return result;
        }
        // non-compliant cc-json-operation
        return null;
      } catch (error) {
        // check if expression is already a valid cc-json-operation
        const expressionParsed = JSONOperation.fromString(value);
        const { status } = expressionParsed;
        if (status === "SUCCESS") {
          return value;
        }
        // invalid string
        return null;
      }
    },
    extractVarsFromExpression(expr) {
      const regex = /[+/-\s*()]/gi;
      return expr
        ? expr
            .replace(regex, "!")
            .replace(/!+/g, "!")
            .split("!")
            .filter((v) => Number.isNaN(Number.parseFloat(v)))
        : [];
    },
  },
};
