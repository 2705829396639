<template>
  <div v-selector.view class="d-flex flex-1">
    <RulesSidebar
      :product="product"
      :unused-rules="unusedRules"
      :cycle-rules="cycleRules"
      @update="propagateEvent"
      @addRule="addRule"
      @changeMade="setRefetchFlag"
    />
    <router-view
      ref="router"
      :product="product"
      @update="checkRules"
      @changeMade="setRefetchFlag"
    ></router-view>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import RulesSidebar from "../components/RulesSidebar.vue";
import * as api from "../api";

export default {
  name: "ProductRules",
  components: { RulesSidebar },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    const { ruleId } = this.$router.currentRoute.params;
    return {
      ruleId: ruleId || this.product.rules[0].id,
      unusedRules: [],
      cycleRules: [],
      shouldRefetchProduct: false,
    };
  },
  computed: {
    ...mapState("auth", ["tenant"]),
  },
  async mounted() {
    await this.checkRules();
    const { params, query } = this.$router.currentRoute;
    if (this.product.rules.length && !params.ruleId) {
      this.$router.replace({
        name: "product-rules-edit",
        params: {
          productId: this.product.version.latest,
          ruleId: this.ruleId,
        },
        query,
      });
    }
  },
  beforeDestroy() {
    if (this.shouldRefetchProduct) {
      this.fetchProduct({
        productId: this.product.id,
        silent: true,
      });
    }
  },
  methods: {
    ...mapActions("product", ["fetchProduct"]),
    propagateEvent(...args) {
      this.$refs.router.propagateEvent(...args);
    },
    addRule(...args) {
      this.$refs.router.addRule(...args);
    },
    async checkRules(cancel = false) {
      const response = await api.checkRules(
        this.product.id,
        cancel,
        this.tenant
      );
      this.unusedRules =
        response && response.data.ok ? response.data.data.unusedRules : [];
      this.cycleRules =
        response && response.data.ok ? response.data.data.rulesWithCycle : [];
    },
    setRefetchFlag() {
      this.shouldRefetchProduct = true;
    },
  },
};
</script>
