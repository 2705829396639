import { render, staticRenderFns } from "./UiPagination.vue?vue&type=template&id=3caa6606&scoped=true"
import script from "./UiPagination.vue?vue&type=script&lang=js"
export * from "./UiPagination.vue?vue&type=script&lang=js"
import style0 from "./UiPagination.vue?vue&type=style&index=0&id=3caa6606&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3caa6606",
  null
  
)

export default component.exports