import axios from 'axios';
import stringify from 'qs-stringify';
import { configureClient } from './helpers';

export const settings = { baseURL: `${window.__env__.VUE_APP_API_URL}/` };

const client = axios.create(settings);
configureClient(client, settings);

const queryClientUrl = (tenant, viewName, queryName, queryString) => {
  const tenantPath = tenant ? `/tenants/${tenant}` : '';
  const isCopilot = viewName.split('/')[0] === 'copilot';
  if (!isCopilot) {
    return `${
      window.__env__.VUE_APP_API_URL
    }${tenantPath}/api/editor/views/2.0/${viewName}/${queryName}${
      queryString.length > 0 ? `?${queryString}` : ''
    }`;
  }
  return `${
    window.__env__.VUE_APP_COPILOT_URL || window.__env__.VUE_APP_API_URL
  }/${viewName}/${queryName}`;
};

export const queryClient = async (tenant, viewName, queryName, params = {}) => {
  const { query, ...filteredParams } = params;
  const { status, data } = await client.get(
    queryClientUrl(
      tenant,
      viewName,
      queryName,
      stringify(
        query && query === `${viewName}.${queryName}` ? filteredParams : params
      )
    )
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export const queryClientBlob = async (
  tenant,
  viewName,
  queryName,
  params = {}
) => {
  const { query, ...filteredParams } = params;
  const { status, data } = await client.get(
    queryClientUrl(
      tenant,
      viewName,
      queryName,
      stringify(
        query && query === `${viewName}.${queryName}` ? filteredParams : params
      )
    ),
    { responseType: 'blob' }
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export default queryClient;
