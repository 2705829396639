<template>
  <div class="RowOperation" @click.stop.prevent>
    <div v-if="isEditing" v-selector class="d-flex justify-content-between">
      <UiCircularButton
        :disabled="isReadOnly || isInvalid"
        variant="success"
        data-cy="button-save"
        icon="el-icon-check"
        @click="$emit('command', 'save')"
      />
      <UiCircularButton
        :disabled="isReadOnly"
        variant="danger"
        data-cy="button-close"
        icon="el-icon-close"
        @click="$emit('command', 'cancel')"
      />
    </div>
    <div v-else>
      <UiCircularButton
        v-if="isEditable"
        :disabled="isReadOnly || restrictEdit"
        data-cy="button-row-edit"
        @click="$emit('command', 'edit')"
      >
        <span class="icon-pencil-1"></span>
      </UiCircularButton>
      <UiCircularButton
        v-if="!isInherited"
        :disabled="isReadOnly"
        data-cy="button-row-delete"
        @click="$emit('command', isOverridden ? 'revert' : 'delete')"
      >
        <span :class="isOverridden ? 'icon-undo' : 'icon-bin-1'"></span>
      </UiCircularButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "RowOperations",
  props: {
    isEditing: Boolean,
    isEditable: { type: Boolean, default: true },
    isInvalid: { type: Boolean, default: false },
    restrictEdit: { type: Boolean, default: false },
    isReadOnly: Boolean,
    isOverridden: Boolean,
    isInherited: Boolean,
  },
};
</script>

<style scoped>
.RowOperation {
  margin-left: auto;
}
</style>
