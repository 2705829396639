<template>
  <el-date-picker
    v-if="current || mode === 'form'"
    ref="input"
    v-model="value"
    :append-to-body="false"
    align="center"
    :clearable="mode === 'form'"
    :data-cy="`input-${question.id}`"
    type="datetime"
    :picker-options="{ firstDayOfWeek: 1 }"
    class="question date-picker-widget"
    :class="{ isOnFocus: isOnFocus }"
    value-format="timestamp"
    format="dd/MM/yyyy HH:mm"
    popper-class="center-date-picker"
    :placeholder="$t('enter-date')"
    :prefix-icon="question.loading ? 'el-icon-loading' : 'el-icon-date'"
    :disabled="question.loading"
    @input="$emit('show')"
    @change="submitWithDelay"
    @keydown.enter.exact.native.prevent.stop="
      setValue(value);
      submit(true);
    "
    @keydown.tab.native.capture="captureTab($event)"
    @focus="dateInputFocus()"
    @blur="dateInputBlur()"
  >
  </el-date-picker>
</template>
<script>
import captureTabMixin from "./mixins/captureTabMixin";
import inputMixin from "./mixins/inputMixin";

export default {
  name: "InputDateTimeComponent",
  mixins: [captureTabMixin, inputMixin],
  data() {
    return {
      isOnFocus: true,
    };
  },
  methods: {
    async submitWithDelay() {
      // need to setValue before submitting to allow form validation
      this.setValue(this.value);
      if (this.mode === "form") {
        this.submit();
      }
    },
    async focusInput() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    dateInputFocus() {
      this.isOnFocus = true;
      this.$emit("focus");
    },
    dateInputBlur() {
      this.isOnFocus = false;
    },
  },
};
</script>

<style lang="scss">
// @import "../theme/date-picker.css";
</style>

<style lang="scss">
.widget-section {
  .date-picker-widget {
    width: 300px !important;

    &.isOnFocus {
      height: 390px;
    }

    .center-date-picker {
      left: 50% !important;
    }
  }
}

.date-picker-submit {
  margin-left: -32px;
}
.date-picker-widget {
  width: 175px !important;

  .el-input__prefix {
    height: 40px !important;
  }
}
.center-date-picker {
  position: absolute;
  top: 35px !important;
  left: 70% !important;
  z-index: 2005;
  transform: translateX(-50%) !important;
  width: 280px;

  .el-picker-panel__content {
    margin: 4px;
    width: calc(100% - 8px);
    .el-date-table {
      max-width: 100%;
    }
    .el-date-table__row td div span {
      line-height: 24px !important;
    }
  }

  .el-picker-panel__footer {
    display: none;
  }

  .popper__arrow {
    left: 25% !important;
  }
}

.question {
  max-width: 100%;
}
</style>
