<template>
  <div class="home full-height d-flex">
    <div
      class="d-flex flex-row justify-content-between bg-white px-4 align-items-center"
    >
      <nav class="nav-bar">
        <router-link
          :to="{ name: 'products-index' }"
          data-cy="products-tab"
          class="px-3"
          ><span>{{ $t("common.insurance-models") }}</span></router-link
        >
        <router-link
          :to="{ name: 'templates-index' }"
          data-cy="templates-tab"
          class="px-3"
          ><span>{{ $t("common.model-templates") }}</span></router-link
        >
        <router-link
          :to="{ name: 'questionnaires-index' }"
          data-cy="questionnaires-tab"
          class="px-3"
          ><span>{{ $t("common.questionnaires") }}</span></router-link
        >
        <router-link
          :to="{ name: 'definition-lists-index' }"
          data-cy="definition-lists-tab"
          class="px-3"
          ><span>{{ $t("common.definitions") }}</span></router-link
        >
        <router-link
          :to="{ name: 'properties-index' }"
          data-cy="csp-tab"
          class="px-3"
          ><span>{{ $t("common.properties") }}</span></router-link
        >
      </nav>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

.back {
  background-color: white;
}

nav a {
  text-transform: uppercase;
  letter-spacing: 1px;
  user-select: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
}

a.router-link-active {
  ::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #d24723;
  }
  color: $color-axa;
  opacity: 1;
  font-weight: 600;
}

.homeNav {
  background-color: #fff;
}

.nav-bar {
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-end;
}
</style>
