<template>
  <div class="container">
    <h3 class="my-4">Rules</h3>

    <p v-if="!product.rules.length">This product does not have any rules.</p>
    <Loading v-else />
  </div>
</template>

<script>
import Loading from "../components/Loading";

export default {
  name: "ProductRulesIndex",
  components: {
    Loading,
  },
  props: {
    product: { type: Object, required: true },
  },
};
</script>
