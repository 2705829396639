<template>
  <table v-if="rule && rule.meta_data" class="m-4">
    <tr>
      <th v-for="column in meta_data.columns" :key="column" class="px-4 py-2">
        {{ column }}
      </th>
    </tr>
    <tr v-for="(row, index) in meta_data.rows" :key="index">
      <td v-for="column in meta_data.columns" :key="column" class="px-4 py-2">
        {{ row[column] }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "TableRule",
  props: { rule: { type: Object, default: undefined } },
  computed: {
    meta_data() {
      return this.rule && this.rule.meta_data ? this.rule.meta_data : {};
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

table {
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 143, 0.1);
  border-radius: 10px;
}

th {
  color: $color-axa-soft;
  font-weight: normal;
  border-bottom: 1px solid rgba(0, 0, 143, 0.1);
}

td {
  border-right: 1px solid rgba(0, 0, 143, 0.1);
  border-bottom: 1px solid rgba(0, 0, 143, 0.1);
  white-space: nowrap;
}

td:last-child {
  border-right: none;
}

tr:last-child {
  td {
    border-bottom: none;
  }

  td:last-child {
    border-bottom-right-radius: 0.5em;
  }
  td:first-child {
    border-bottom-left-radius: 0.5em;
  }
}

td:empty {
  background-color: #ededf4;
  opacity: 0.8;
}
</style>
