import { render, staticRenderFns } from "./DefinitionListsOverview.vue?vue&type=template&id=5c9ec124&scoped=true"
import script from "./DefinitionListsOverview.vue?vue&type=script&lang=js"
export * from "./DefinitionListsOverview.vue?vue&type=script&lang=js"
import style0 from "./DefinitionListsOverview.vue?vue&type=style&index=0&id=5c9ec124&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c9ec124",
  null
  
)

export default component.exports