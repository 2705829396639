/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { rootCommit, rootDispatch } from '../helper';
import * as api from '../../api';
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import types from './productProperty.types';
import feathersClient from '../../feathers';
import { commandClient } from '../../api/commandClient';

const clearProductValidation = (dispatch, productId) => {
  dispatch(
    'sharedProperty/clearProductValidation',
    { productId },
    { root: true }
  );
};

export default {
  fetchProductProperties: async ({ commit, rootState }, productId) => {
    commit(types.FETCH_PRODUCT_PROPERTIES_REQUEST);
    try {
      const properties = await feathersClient
        .service(`2.0/products/${productId}/properties`)
        .find({
          query: {
            tenant: rootState.auth.tenant
          }
        });
      commit(types.FETCH_PRODUCT_PROPERTIES_SUCCESS, {
        properties
      });
    } catch (e) {
      commit(types.FETCH_PRODUCT_PROPERTIES_ERROR);
    }
  },
  reloadProductProperties: async ({ commit, rootState }, productId) => {
    try {
      const properties = await feathersClient
        .service(`2.0/products/${productId}/properties`)
        .find({
          query: {
            tenant: rootState.auth.tenant
          }
        });
      commit(types.FETCH_PRODUCT_PROPERTIES_SUCCESS, {
        properties
      });
    } catch (e) {
      commit(types.FETCH_PRODUCT_PROPERTIES_ERROR);
    }
  },
  createProductProperty: async (
    { commit, dispatch, rootState },
    { productId, propertyData }
  ) => {
    commit(types.CREATE_PRODUCT_PROPERTY_REQUEST);
    try {
      const property = await feathersClient
        .service(`2.0/products/${productId}/properties`)
        .create(propertyData, {
          query: { tenant: rootState.auth.tenant }
        });
      commit(types.CREATE_PRODUCT_PROPERTY_SUCCESS, { property });

      rootDispatch(dispatch, 'product/fetchProduct', {
        productId,
        silent: true
      });

      clearProductValidation(dispatch, productId);
      return property;
    } catch (e) {
      commit(types.CREATE_PRODUCT_PROPERTY_ERROR);
    }
  },
  deleteProductProperty: async (
    { commit, dispatch, rootState },
    { productId, name }
  ) => {
    commit(types.DELETE_PRODUCT_PROPERTY_REQUEST);
    try {
      await feathersClient
        .service(`2.0/products/${productId}/properties`)
        .remove(name, {
          query: { tenant: rootState.auth.tenant }
        });
      commit(types.DELETE_PRODUCT_PROPERTY_SUCCESS, name);

      try {
        rootDispatch(dispatch, 'product/fetchProduct', {
          productId,
          silent: true
        });
        const { data: ide } = await api.ide(
          productId,
          rootState.auth.tenant,
          rootState.product.requestWarnings
        );
        rootCommit(commit, 'product/FETCH_PRODUCT_WARNINGS_SUCCESS', ide);
      } catch (error) {
        rootCommit(commit, 'product/FETCH_PRODUCT_SUCCESS', error.response);
      }
      clearProductValidation(dispatch, productId);
    } catch (e) {
      commit(types.DELETE_PRODUCT_PROPERTY_ERROR);
    }
  },
  updateProductProperty: async (
    { commit, dispatch, rootState },
    { productId, propertyName, propertyData }
  ) => {
    commit(types.UPDATE_PRODUCT_PROPERTY_REQUEST);
    try {
      const property = await feathersClient
        .service(`2.0/products/${productId}/properties`)
        .update(propertyName, propertyData, {
          query: { tenant: rootState.auth.tenant }
        });
      commit(types.UPDATE_PRODUCT_PROPERTY_SUCCESS, { propertyName, property });

      rootDispatch(dispatch, 'product/fetchProduct', {
        productId,
        silent: true
      });

      clearProductValidation(dispatch, productId);
    } catch (e) {
      commit(types.UPDATE_PRODUCT_PROPERTY_ERROR);
    }
  },
  fetchDimensions: async ({ state, commit, rootState }, productId) => {
    if (state.isDimensionsLoading) return;
    commit(types.FETCH_DIMENSIONS_REQUEST);
    try {
      const { data } = await api.getDimensionsAggregations(
        productId,
        rootState.auth.tenant
      );
      commit(types.FETCH_DIMENSIONS_SUCCESS, {
        dimensions: data
      });
    } catch (e) {
      commit(types.FETCH_DIMENSIONS_ERROR);
    }
  },
  createTermsForDimensionFromCopilot: async (
    { rootState },
    { productId, dimension }
  ) => {
    const values = rootState.product.extractedDimensions[dimension];
    if (values && Array.isArray(values) && values.length) {
      values.forEach((value) => {
        commandClient(rootState.auth.tenant, `products/${productId}`, 'terms', {
          dimension,
          name: value,
          definitions: []
        });
      });
    }
  }
};
