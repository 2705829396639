<template>
  <el-dialog
    :title="$t('product.create_new_prop')"
    :visible="isVisible"
    custom-class="add-variable-dialog"
    @close="close()"
  >
    <el-form :model="form" @submit.native.prevent="create">
      <el-form-item>
        <el-autocomplete
          v-if="autocompleteValues"
          ref="input"
          v-model="form.name"
          :placeholder="$t('product.name')"
          class="inline-input"
          :style="{ width: '100%' }"
          :fetch-suggestions="autocompleteValues"
          @input.native="enforceValidName()"
        >
          <template slot-scope="{ item }">
            <div class="d-flex align-stretch">
              <span class="flex-grow-1 flex-shrink-0">{{ item.value }}</span>
              <i :style="{ width: '30%' }" class="flex-grow-0 flex-shrink-0">
                {{ item.displayName }}
              </i>
            </div>
          </template>
        </el-autocomplete>
        <el-input
          v-else
          ref="input"
          v-model="form.name"
          :placeholder="$t('product.name')"
          class="inline-input"
          :style="{ width: '100%' }"
          @input.native="enforceValidName()"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isVisible = false">{{
        $t("action.cancel")
      }}</el-button>
      <el-button type="primary" :disabled="form.name === ''" @click="create"
        >{{ $t("action.ok") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Vue from "vue";

export default {
  name: "CreationDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    toValidName: {
      type: Function,
      default: () => (v) => v,
    },
    autocompleteValues: {
      type: Function,
      default: undefined,
    },
  },
  data: () => ({
    isVisible: false,
    form: {
      name: "",
    },
  }),
  watch: {
    visible(value) {
      this.isVisible = value;
      if (!value) return;
      Vue.nextTick(() => this.$refs.input.focus());
    },
  },
  methods: {
    enforceValidName() {
      this.form.name = this.toValidName(this.form.name);
    },
    reset() {
      this.form = {
        name: "",
      };
    },
    disabled() {
      return this.form.name.trim() === "";
    },
    create() {
      this.isVisible = false;
      this.$emit("create", this.form);
      this.reset();
    },
    cancel() {
      this.isVisible = false;
      this.$emit("cancel");
      this.reset();
    },
    close() {
      this.isVisible = false;
      this.$emit("close");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
