/* eslint-disable import/prefer-default-export */

export const queryApi = async (
  commit,
  prefix,
  onSuccess,
  onRequest,
  onError
) => {
  commit(`${prefix}_REQUEST`, onRequest && (await onRequest()));
  try {
    commit(`${prefix}_SUCCESS`, await onSuccess());
  } catch (error) {
    const { code, message } = error;
    commit(
      `${prefix}_ERROR`,
      (onError && (await onError(error))) || [{ code, message }]
    );
  }
};

export const apiMutations = (prefix, onRequest, onSuccess, onError) => ({
  [`${prefix}_REQUEST`]: onRequest,
  [`${prefix}_SUCCESS`]: onSuccess,
  [`${prefix}_ERROR`]: onError
});

export const apiTypes = (prefix) => ({
  [`${prefix}_REQUEST`]: `${prefix}_REQUEST`,
  [`${prefix}_SUCCESS`]: `${prefix}_SUCCESS`,
  [`${prefix}_ERROR`]: `${prefix}_ERROR`
});

export const rootDispatch = (dispatch, path, value) =>
  dispatch(path, value, { root: true });

export const rootCommit = (commit, path, value) =>
  commit(path, value, { root: true });

export const getProduct = (rootState) => {
  if (!rootState.product) throw new Error('Missing product state');

  const { product } = rootState;
  if (!product.product || typeof product.product !== 'object') {
    throw new Error('Missing or invalid product');
  }
  return product.product;
};

export const getTenant = (rootState) => {
  if (!rootState.auth) throw new Error('Missing auth state');

  const { auth } = rootState;
  if (!auth.tenant || auth.tenant.trim() === '') {
    throw new Error('Missing or empty tenant');
  }
  if (typeof auth.tenant !== 'string')
    throw new Error('Tenant must be a string');
  return auth.tenant.trim();
};

// Testing Helpers

// @todo create a specific helper for testing

export const mockState = (reset, state = {}) => {
  const s = {};
  reset(s);
  return { ...s, ...state };
};

export const testAction = async (
  action,
  payload,
  state,
  expectedMutations,
  expectedActions
) =>
  new Promise((resolve, reject) => {
    let mutationCount = 0;
    let actionCount = 0;

    const executedMutations = [];
    const executedActions = [];

    // mock commit
    const commit = (t, p) => {
      const currentMutation = expectedMutations[mutationCount];
      if (!currentMutation) {
        reject(new Error(`Additional mutation (${t})`));
      }
      const { type, payload: mutationPayload } = currentMutation;
      expect(t).toEqual(type);
      expect(p).toStrictEqual(mutationPayload);
      executedMutations.push(currentMutation);
      // eslint-disable-next-line no-plusplus
      mutationCount++;
    };

    // mock dispatch
    const dispatch = (a, p) => {
      const currentAction = expectedActions[actionCount];
      if (!action) {
        reject(new Error(`Additional dispatch (${a})`));
      }
      const { name, payload: actionPayload } = currentAction;

      expect(a).toEqual(name);
      expect(p).toStrictEqual(actionPayload);
      executedActions.push(currentAction);
      // eslint-disable-next-line no-plusplus
      actionCount++;
    };

    // call the action with mocked store and arguments
    const { rootState, ...moduleState } = state || {};
    try {
      Promise.resolve(
        action({ commit, dispatch, state: moduleState, rootState }, payload)
      ).then(() => {
        // check if no mutations should have been dispatched
        expect(expectedMutations).toStrictEqual(executedMutations);
        expect(expectedActions).toStrictEqual(executedActions);
        resolve();
      });
    } catch (error) {
      reject(error);
    }
  });

export const expectQueryClientParams = (
  mock,
  tenant,
  viewName,
  queryName,
  params
) => {
  expect(mock.tenant).toBe(tenant);
  expect(mock.viewName).toBe(viewName);
  expect(mock.queryName).toBe(queryName);
  expect(mock.params).toStrictEqual(params);
};
