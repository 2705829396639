<template>
  <div>
    <UiLoadingArea :is-loading="isLoading">
      <UiTable
        ref="table"
        :default-sort="{ prop: sortField, order: sortOrder }"
        class="ui_table_product_revision"
        :data="revisions"
        row-key="id"
        variant="padded"
        @sort-change="onSort"
      >
        <!-- Created at -->
        <el-table-column
          prop="created_at"
          width="300"
          :label="$t('product.versions.revisionDate')"
          sortable="custom"
        >
          <template #default="{ row }">
            <UiFormattedDate format="ll" :date="row.createdAt" />
          </template>
        </el-table-column>

        <!-- Label -->
        <el-table-column
          prop="name"
          :label="$t('product.versions.revisionStatus')"
        >
          <template #default="{ row }">
            <div class="Status">
              <component
                :is="revisionIcon(row.type)"
                :variant="releaseIconByPublicationStatus(row.metadata.status)"
              />
              {{ revisionLabel(row) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column width="180">
          <ProductRevisionRowOperations
            slot-scope="{ row }"
            class="ui_buttons_operations"
            :is-downloading="downloadedRevisions[row.id] === true"
            :revision-type="row.type"
            @command="(command) => handleRowItemCommand(command, row)"
          />
        </el-table-column>
      </UiTable>
    </UiLoadingArea>
    <UiPagination
      :current-page.sync="page"
      :page-size="limit"
      :total="pagination.total"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Message } from "element-ui";
import revisionIconComponent from "../../domain/service/revision/revisionIconComponent";
import revisionLabel from "../../domain/service/revision/revisionLabel";
import ProductSnapshotMixin from "./ProductSnapshotMixin";
import ProductRevisionRowOperations from "./ProductRevisionRowOperations";
import downloadFile from "../../helpers/downloadFile";

export default {
  name: "ProductRevisionIndex",
  components: { ProductRevisionRowOperations },
  mixins: [ProductSnapshotMixin],
  props: {
    product: { type: Object, required: true },
    revisions: { type: Array, required: true },
    pagination: { type: Object, required: true },
    downloadedRevisions: { type: Object, required: true },
    handleRevisionDownload: { type: Function, required: true },
  },
  data() {
    return {
      page: 1,
      limit: 25,
      sortField: "created_at",
      sortOrder: "DESC",
      pendingDownloads: [],
    };
  },
  computed: {
    ...mapGetters("product", ["isReadOnly"]),
    ...mapGetters("auth", ["isGuest"]),
    ...mapState("productSnapshot", ["isLoading"]),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
  },
  watch: {
    downloadedRevisions: {
      handler(value) {
        const { pendingDownloads } = this;
        pendingDownloads.forEach((id) => {
          if (!value[id]) return;
          const { dump, filename } = value[id];
          downloadFile(dump, filename, "application/json");
          this.pendingDownloads = this.pendingDownloads.filter((v) => v !== id);
        });
      },
    },
    page: {
      handler() {
        if (this.firstFetch) return;
        this.$emit("onFetchSnapshots", {
          page: this.page,
          count: this.limit,
          sort: {
            field: this.sortField,
            direction: this.sortOrder,
          },
        });
      },
    },
  },
  methods: {
    ...mapActions("product", ["questionnaireSubstitution"]),
    ...mapActions("uiProductReleases", ["changeTab"]),
    revisionLabel({ type, metadata }) {
      return revisionLabel(type, metadata);
    },
    revisionIcon(type) {
      return revisionIconComponent(type);
    },
    handleRowItemCommand(command, row) {
      // eslint-disable-next-line no-unused-expressions
      typeof this[command] === "function"
        ? this[command](row)
        : throw new Error(`Handler not found for ${command}`);
    },
    download(row) {
      if (!this.downloadedRevisions[row.id]) {
        if (this.pendingDownloads.includes(row.id)) return;
        this.pendingDownloads.push(row.id);
        this.handleRevisionDownload(row.id);
      } else {
        const { dump, filename } = this.downloadedRevisions[row.id];
        downloadFile(dump, filename, "application/json");
      }
    },
    async revertSubstitution(row) {
      const result = await this.questionnaireSubstitution({
        data: { snapshotId: row.id },
        rollback: true,
      });
      if (result && result.error) {
        Message.error(result.error.message);
      } else {
        this.changeTab("RELEASES");
        this.$router.push({ name: "product-home" });
        Message.success(this.$t("product.questionnaire_substitution_reverted"));
      }
    },
    async onSort(field, order) {
      try {
        if (order && field) {
          if (order === this.sortOrder && field === this.sortField) {
            return;
          }
          this.sortField = field;
          this.sortOrder = order;
          this.$emit("onFetchSnapshots", {
            page: this.page,
            count: this.pagination.limit,
            sort: {
              field: this.sortField,
              direction: this.sortOrder,
            },
          });
        }
      } catch (e) {
        Message.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Status {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;

  .icon {
    margin-right: 0.5rem;
  }
}
</style>
