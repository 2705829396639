<template>
  <router-link :to="homeUrl" class="HeaderLogo" active-class="no-border mr-2">
    <div class="HeaderLogo__Visual mr-2">
      <DefaultLogo v-if="!hasCustomIcon" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-else class="customLogo" v-html="logo"></div>
    </div>
    <template v-if="!isProductDetail">
      <div v-if="projectName" class="HeaderLogo__Content">
        <h1 class="HeaderLogo__Name mb-0">{{ appName }}</h1>
        <h2 v-if="appName !== projectName" class="HeaderLogo__Project">
          {{ projectName }}
        </h2>
      </div>
      <div v-else class="HeaderLogo__Content">
        <h1 class="HeaderLogo__Name mb-0">{{ appName }}</h1>
      </div>
    </template>
  </router-link>
</template>
<script>
import { name as appName } from "../config";
import DefaultLogo from "./DefaultLogo.vue";

const validCustomIcon = () => {
  return !!(
    window.__env__.VUE_APP_PROJECT_LOGO &&
    window.__env__.VUE_APP_PROJECT_LOGO !== ""
  );
};

export default {
  name: "HeaderLogo",
  components: {
    DefaultLogo,
  },
  props: {
    appName: {
      type: String,
      default: () => window.__env__.VUE_APP_NAME || appName,
    },
    projectName: {
      type: String,
      default: () => window.__env__.VUE_APP_PROJECT_NAME || null,
    },
    homeUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasCustomIcon() {
      return validCustomIcon();
    },
    appIcon() {
      return Buffer.from(
        window.__env__.VUE_APP_PROJECT_LOGO,
        "base64"
      ).toString("utf-8");
    },
    logo() {
      return this.appIcon;
    },
    isProductDetail() {
      return !!this.$route.params.productId;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";
.customLogo {
  height: 32px;
}
.no-border {
  border: 0;
}
.HeaderLogo {
  display: flex;
  outline: 0;
  margin-right: 2rem;

  &__Visual {
    width: 34px;
    height: 34px;
    display: inline-block;
    position: relative;
    margin-right: 1.5rem;

    > svg {
      width: 100%;
      height: auto;
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__Name,
  &__Project {
    font-family: $headings-font-family;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Name {
    font-size: 1.6rem;
    color: $color-axa-blue;
  }

  &__Project {
    font-size: 1.15rem;
    color: $color-gray--dark;
    margin-top: -0.4rem;
    text-transform: uppercase;
  }
}
</style>
