<template>
  <div class="RowOperation" @click.stop.prevent>
    <UiThreeDotsDropDown
      :items="dropdownItems"
      @command="(command) => $emit('command', command)"
    />
  </div>
</template>

<script>
import { revisionTypes } from "../../domain/model/revision";

export default {
  name: "ProductRevisionRowOperations",
  props: {
    isDownloading: { type: Boolean, default: false },
    revisionType: { type: String, default: "" },
  },
  computed: {
    dropdownItems() {
      const items = [
        {
          command: "download",
          label: this.$t("product.revision.downloadExport"),
          class: "ui_menu_download",
          icon: "fas fa-download",
        },
      ];

      if (this.revisionType === revisionTypes.QUESTIONAIRE_SUBSTITUTION) {
        items.push({
          command: "revertSubstitution",
          label: this.$t("product.revision.revertSubstitution"),
          class: "ui_menu_download",
          icon: "fas fa-history",
        });
      }

      return items;
    },
  },
};
</script>

<style scoped>
.RowOperation {
  margin-left: auto;
}
</style>
