<template>
  <div>
    <div v-if="!isLoading" class="container_chips">
      <UiChip
        v-for="(item, index) of itemsSorted"
        :key="index"
        :item="{
          id: item.id || index,
          text: `${item.name}`,
          selected: item.selected,
          selectable: item.editable,
        }"
        :selectable="item.editable"
        :selected.sync="item.selected"
        :hide="false"
        @click="$emit('click', item)"
      />
    </div>
    <div>
      <UiPagination
        v-if="true"
        ref="paginator"
        :current-page.sync="page"
        :page-size="count"
        :total="items.length"
      />
    </div>
  </div>
</template>

<script>
import { sortByFieldname } from "../helpers";

export default {
  name: "ChipCollector",
  props: {
    items: {
      type: Array,
      required: true,
    },
    preserveInitialOrder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      isLoading: true,
      page: 1,
      count: 15,
      sortField: "name",
      sortOrder: "descending",
      itemsSorted: [],
    };
  },
  watch: {
    page() {
      this.updatePaginatedItems();
    },
    items() {
      this.itemsSorted = this.items;
    },
  },
  mounted() {
    this.page = 1;
    this.updatePaginatedItems();
  },
  methods: {
    updatePaginatedItems() {
      this.itemsSorted = (
        !this.preserveInitialOrder
          ? sortByFieldname(this.items, this.sortField, this.sortOrder)
          : this.items
      ).slice(
        (this.page - 1) * this.count,
        (this.page - 1) * this.count + this.count
      );
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container_chips {
  margin-left: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
