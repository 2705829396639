<template>
  <div>
    <div class="icon">
      <DefaultIcon />
    </div>
  </div>
</template>

<script>
import DefaultIcon from "../assets/images/logo.svg";

export default {
  name: "DefaultLogo",
  components: { DefaultIcon },
};
</script>
<style scoped>
.icon {
  width: 36px;
}
</style>
