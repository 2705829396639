<template>
  <div class="TranslationLanguageSelector">
    <div v-if="label" class="TranslationLanguageSelector__Label">
      {{ label }}
      <el-tooltip v-if="tooltipContent">
        <i :class="tooltipIcon"></i>
        <template slot="content">
          {{ tooltipContent }}
        </template>
      </el-tooltip>
    </div>
    <UiInlineDropDown
      ref="dropdown"
      class="TranslationLanguageSelector__Dropdown"
      variant="no-border"
      prefix-icon="language"
      placeholder="Select a language..."
      :value="currentLanguage"
      :toggle-editing="true"
      :items="availableLanguages"
      :handle-change="(key) => $emit('language-change', key)"
    />
  </div>
</template>

<script>
import { isValidLanguageKeyOrNull } from "../../helpers/translations";

export default {
  name: "TranslationLanguageSelector",
  props: {
    label: {
      type: String,
      default: undefined,
    },
    tooltipIcon: {
      type: String,
      default: "el-icon-warning-outline",
    },
    tooltipContent: {
      type: String,
      default: undefined,
    },
    currentLanguage: {
      type: String,
      validator: isValidLanguageKeyOrNull,
      default: null,
    },
    availableLanguages: {
      type: Array,
      required: true,
    },
  },
  watch: {
    currentLanguage(v) {
      if (v != null) return;
      this.$refs.dropdown.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.TranslationLanguageSelector {
  position: relative;
  z-index: 0;
  text-transform: capitalize;

  &__Label {
    z-index: 1;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
