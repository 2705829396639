var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{class:{
    full: _vm.hasDefinitionLinked,
    stretched: !_vm.hasDefinitionLinked,
  },attrs:{"id":"editTermModal","visible":"","before-close":_vm.close}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between pr-5",attrs:{"slot":"title"},slot:"title"},[_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.term)?_c('div',[(!_vm.isEditing)?_c('el-button',{staticClass:"ui_button_edit",attrs:{"disabled":_vm.readonly,"size":"mini","icon":"el-icon-edit"},on:{"click":_vm.edit}},[_vm._v("Edit")]):_vm._e(),_vm._v(" "),_c('el-tooltip',{staticClass:"item",attrs:{"disabled":_vm.canDelete,"effect":"dark","content":_vm.$t(`product.terms.used_monoterm`),"placement":"top-start"}},[_c('div',{staticClass:"float-right pl-2"},[_c('el-button',{staticClass:"ui_button_delete",attrs:{"disabled":_vm.readonly || !_vm.canDelete,"size":"mini","type":"danger","plain":"","icon":"el-icon-delete"},on:{"click":_vm.deleteTerm}},[_vm._v("Delete")])],1)])],1):_vm._e()]),_vm._v(" "),(_vm.isEditing || !_vm.term)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"pr-3"},[_vm._v("Term")]),_vm._v(" "),_c('el-input',{staticClass:"ui_input_term_name",attrs:{"placeholder":_vm.$t('product.terms.edit_name'),"maxlength":"255","show-word-limit":""},model:{value:(_vm.termName),callback:function ($$v) {_vm.termName=$$v},expression:"termName"}})],1)]),_vm._v(" "),_c('div',{staticClass:"source-definition-list col-6"},[(_vm.hasDefinitionLinked)?_c('span',[_vm._v("\n        "+_vm._s(_vm.$t("product.terms.source_def_list"))+":\n        "),_c('router-link',{staticClass:"ui_link_to_list",attrs:{"to":{
            name: 'definition-lists-edit',
            params: {
              definitionListId: _vm.selectedDefinitionListId,
            },
          },"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.getDefinitionListName(_vm.selectedDefinitionListId))+"\n          "),_c('i',{staticClass:"fas fa-external-link-alt"})])],1):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.hasDefinitionLinked)?_c('div',[_c('UiSearchBox',{attrs:{"placeholder":_vm.$t('product.terms.edit_search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('el-tabs',{ref:"tabs"},[_c('el-tab-pane',{staticClass:"ui_tab_table_view",attrs:{"label":"Table View"}},[_c('div',{staticClass:"definitions"},_vm._l((_vm.definitionsForSelectedList),function(definition,definitionIndex){return _c('span',{key:'definition -' + definitionIndex,staticClass:"definition py-2 px-3 mr-1 mb-2 ui_definition_name",class:{
              selectable: !_vm.term || _vm.isEditing,
              selected: _vm.isSelected(definition),
              hide: !_vm.isSelected(definition),
            }},[_vm._v("\n            "+_vm._s(definition.displayName || definition.primaryKey)+"\n          ")])}),0),_vm._v(" "),(
            (!_vm.term || _vm.isEditing) &&
            _vm.selectedAndAvailableFilteredDefinitions.length &&
            _vm.hasDefinitionLinked
          )?_c('div',{staticClass:"py-3"},[_c('el-button',{staticClass:"ui_button_invert_sel",attrs:{"size":"mini","type":"text"},on:{"click":_vm.selectAll}},[_vm._v("Select all")])],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('el-table',{ref:"table",class:{ 'is-editing': _vm.isEditing || !_vm.term },attrs:{"row-key":"_id","border":"","resizable":"","height":"500","data":_vm.paginatedDefinitions,"stripe":"","default-sort":{
            prop: 'columns.' + _vm.columnsForSelectedList[0],
            order: 'ascending',
          }},on:{"sort-change":_vm.onSort,"row-click":_vm.handleRowClick,"selection-change":_vm.handleSelectedDefinitions}},[(!_vm.term || _vm.isEditing)?_c('el-table-column',{attrs:{"type":"selection","width":"55","reserve-selection":"","selectable":_vm.definitionSelectable}}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.getValidationErrors(row).length)?_c('el-tooltip',[_c('i',{staticClass:"el-icon-warning-outline"}),_vm._v(" "),_c('template',{slot:"content"},_vm._l((_vm.getValidationErrors(row)),function(error,key){return _c('div',{key:key},[_vm._v("\n                    "+_vm._s(_vm.$t(`product.validation.${error.code}`, {
                        definition: row.primaryKey,
                      }))+"\n                  ")])}),0)],2):_vm._e()]}}],null,false,1871237824)}),_vm._v(" "),_vm._l((_vm.columnsForSelectedList),function(column){return _c('el-table-column',{key:column,attrs:{"prop":'columns.' + column,"label":column,"sortable":true}})}),_vm._v(" "),(!_vm.columnsForSelectedList.length)?_c('el-table-column',{attrs:{"label":"Name"}}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Source"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('router-link',{staticClass:"ui_link_to_list",attrs:{"to":{
                  name: 'definition-lists-edit',
                  params: {
                    definitionListId: row.definitionList
                      ? row.definitionList.id
                      : _vm.definitionListId,
                  },
                },"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("\n                "+_vm._s(row.definitionList ? row.definitionList.name : "")+"\n                "),_c('i',{staticClass:"fas fa-external-link-alt"})])]}}],null,false,496846985)})],2):_vm._e(),_vm._v(" "),(!_vm.loaded)?_c('Loading'):_vm._e(),_vm._v(" "),(_vm.selectedAndAvailableFilteredDefinitions.length)?_c('UiPagination',{attrs:{"current-page":_vm.page,"page-size":_vm.count,"total":_vm.selectedAndAvailableFilteredDefinitions.length},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],1),_vm._v(" "),_c('el-tab-pane',{staticClass:"ui_tab_name",attrs:{"label":(_vm.term && _vm.term.defaultColumnName) || 'Name'}},[(!_vm.definitionListId && !_vm.term)?_c('p',{staticClass:"text-muted p-5 text-center"},[_vm._v("\n          Select list to view available definitions.\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"definitions"},_vm._l((_vm.selectedAndAvailableFilteredDefinitions),function(definition,definitionIndex){return _c('span',{key:'definition -' + definitionIndex,staticClass:"definition py-2 px-3 mr-1 mb-2 ui_definition_name",class:{
              selectable: !_vm.term || _vm.isEditing,
              selected: _vm.isSelected(definition),
            },on:{"click":function($event){return _vm.toggleDefinition(definition)}}},[_vm._v("\n            "+_vm._s(definition.displayName)+"\n            "),(!_vm.term || _vm.isEditing)?_c('el-button',{class:_vm.isSelected(definition) ? 'ui_button_close' : 'ui_button_add',attrs:{"icon":_vm.isSelected(definition) ? 'el-icon-close' : 'el-icon-plus',"type":"text","size":"mini"}}):_vm._e()],1)}),0)]),_vm._v(" "),(_vm.isEditing)?_c('el-tab-pane',{staticClass:"ui_tab_text_import",attrs:{"label":"Text import [BETA]"}},[_c('div',{staticClass:"definitions_from_text"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.definitionListText),expression:"definitionListText"}],staticClass:"ui_textarea_text_import_entity",domProps:{"value":(_vm.definitionListText)},on:{"input":function($event){if($event.target.composing)return;_vm.definitionListText=$event.target.value}}}),_vm._v(" "),_c('el-button',{staticClass:"ui_button_import_from_text",attrs:{"size":"small","type":"primary"},on:{"click":_vm.importEntitiesFromText}},[_vm._v("\n            Import element from text\n          ")]),_vm._v(" "),_c('pre',{staticClass:"debug_text"},[_vm._v("          "),_vm._l((_vm.definitionListTextDebug),function(letter,idx){return _c('span',{key:idx,staticClass:"debug-letter",class:{
                        'selected-last': letter.lastSelected,
                        selected: letter.selectedLetter,
                        'not-selected': !letter.selectedLetter
                      }},[_vm._v("\n          "+_vm._s(letter.letter)+"\n          ")])}),_vm._v("\n        ")],2)],1),_vm._v(" "),(!_vm.definitionListId && !_vm.term)?_c('p',{staticClass:"text-muted p-5 text-center"},[_vm._v("\n          Select list to view available definitions.\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"definitions"},_vm._l((_vm.selectedAndAvailableFilteredDefinitions),function(definition,idx){return _c('span',{key:idx,staticClass:"definition py-2 px-3 mr-1 mb-2 ui_definition_name",class:{
              selectable: !_vm.term || _vm.isEditing,
              selected: _vm.isSelected(definition),
            },on:{"click":function($event){return _vm.toggleDefinition(definition)}}},[_vm._v("\n            "+_vm._s(definition.displayName)+"\n            "),(!_vm.term || _vm.isEditing)?_c('el-button',{class:_vm.isSelected(definition) ? 'ui_button_close' : 'ui_button_add',attrs:{"icon":_vm.isSelected(definition) ? 'el-icon-close' : 'el-icon-plus',"type":"text","size":"mini"}}):_vm._e()],1)}),0)]):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(
      (!_vm.term || _vm.isEditing) &&
      _vm.selectedAndAvailableFilteredDefinitions.length &&
      _vm.hasDefinitionLinked
    )?_c('div',{staticClass:"py-3"},[_c('el-button',{staticClass:"ui_button_invert_sel",attrs:{"size":"mini","type":"text"},on:{"click":_vm.toggleAllSelection}},[_vm._v("Invert selection")])],1):_vm._e(),_vm._v(" "),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[(!_vm.term || _vm.isEditing)?_c('el-button',{staticClass:"ui_button_save",attrs:{"type":"primary","size":"small","disabled":!_vm.canSaveTerm},on:{"click":_vm.saveTerm}},[_vm._v(_vm._s(_vm.term ? "Update" : "Create"))]):_vm._e(),_vm._v(" "),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }