/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import feathersClient from '@/feathers';
import authClient from '@/authClient';
import Bugsnag from '@bugsnag/js';

const Cookies = require('js-cookie');

export const types = {
  SET_CONNECTION_AT: 'SET_CONNECTION_AT',
  SET_EXPIRED_AT: 'SET_EXPIRED_AT',
  SET_USER: 'SET_USER',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_ERROR: 'SET_ERROR',
  SET_LOADING: 'SET_LOADING',
  SET_TENANT: 'SET_TENANT'
};

function getUrlParameterByName(name) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results == null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const setTenant = async (
  { commit, state },
  { tenant, forceReload = false }
) => {
  if (state.tenant === tenant) return;
  if (forceReload) {
    setTimeout(() => {
      window.location.href = `${window.origin}/t/${tenant}/products`;
    }, 300);
  } else {
    commit(types.SET_TENANT, tenant);
  }
};

const setError = async ({ commit }, value) => {
  commit(types.SET_ERROR, value);
};

const attemptLocalLogin = async ({ commit }, { username, password }) => {
  setError({ commit }, null);
  commit(types.SET_LOADING, true);
  try {
    const token = getUrlParameterByName('token');
    const data = {
      strategy: 'local',
      email: username,
      password
    };
    if (token) {
      data.token = token;
    }
    const response = await authClient.authenticate(data);
    const validatedUser = await authClient.validate(response.accessToken);
    const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
    const cookieOpts = window.__env__.VUE_APP_AUTH_COOKIE_DOMAIN
      ? { domain: window.__env__.VUE_APP_AUTH_COOKIE_DOMAIN, path: '/' }
      : {};
    Cookies.set(AUTH_COOKIE, response.accessToken, cookieOpts);
    const user = await feathersClient.service('users').get(validatedUser.id);
    commit(types.SET_USER, user);
    if (window.__env__.VUE_APP_BUGSNAG_KEY) {
      Bugsnag.setUser(user.id, user.email);
    }
  } catch (error) {
    setError({ commit }, 'connection refused');
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const isLoggedIn = ({ state }) => {
  return !!state.user;
};

const logOut = async ({ commit }) => {
  const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
  localStorage.removeItem(AUTH_COOKIE);
  const cookieOpts = window.__env__.VUE_APP_AUTH_COOKIE_DOMAIN
    ? { domain: window.__env__.VUE_APP_AUTH_COOKIE_DOMAIN, path: '/' }
    : {};
  Cookies.remove(AUTH_COOKIE, cookieOpts);
  commit(types.SET_USER, undefined);
  setError({ commit }, undefined);
  commit('users/SET_ERROR', undefined, { root: true });
  // Feather hook on after will remove the cookie auth for the docs
  await authClient.logout();
};

const recallLogin = async ({ commit }, { accessToken }) => {
  commit(types.SET_LOADING, true);
  try {
    const validatedUser = await authClient.validate(accessToken);
    const user = await feathersClient.service('users').get(validatedUser.id);
    if (user) {
      commit(types.SET_USER, user);
      if (window.__env__.VUE_APP_BUGSNAG_KEY) {
        Bugsnag.setUser(user.id, user.email);
      }
    }
  } catch (error) {
    logOut({ commit });
  } finally {
    commit(types.SET_LOADING, false);
  }
};

const actions = {
  attemptLocalLogin,
  isLoggedIn,
  recallLogin,
  logOut,
  setError,
  setTenant
};

export default actions;
