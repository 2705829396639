<template>
  <UiPaddedArea>
    <UiToolbar>
      <UiButton
        v-if="showImportButton"
        size="mini"
        icon="el-icon-download"
        :disabled="readonly"
        :class="'ui_button_add_' + property"
        :data-cy="'property-import-csp'"
        @click="$emit('import')"
      >
        {{ $t("properties.import-from-csp") }}
      </UiButton>

      <UiButton
        size="mini"
        icon="el-icon-plus"
        :disabled="readonly"
        :class="'ui_button_add_' + property"
        :data-cy="'property-add-button' + property"
        @click="$emit('create')"
      >
        {{ $t("product.add", { property: label }) }}
      </UiButton>
      <template #side>
        <slot name="side"></slot>
      </template>
    </UiToolbar>
  </UiPaddedArea>
</template>

<script>
export default {
  name: "ProductConfigurationToolbar",
  props: {
    label: {
      type: String,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      onCSP: ["dimensions", "in", "out", "units", "computes"],
    };
  },
  computed: {
    showImportButton() {
      return this.onCSP.includes(this.property);
    },
  },
};
</script>

<style lang="scss" scoped>
.UiToolbar__Main > .UiButton {
  margin-right: 1rem;
}
</style>
