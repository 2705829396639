<template>
  <div @click="$refs.cmd.focus()">
    <div ref="terminal" class="Container">
      <div :class="progressClassNames">
        <div class="Progress__Bar"></div>
      </div>
      <div ref="screen" class="Screen">
        <div v-if="banner" class="Banner">
          <h2 v-if="banner.header" style="letter-spacing: 4px">
            {{ banner.header }}
          </h2>
          <p v-if="banner.subHeader">{{ banner.subHeader }}</p>
          <p v-if="banner.helpHeader">{{ banner.helpHeader }}</p>
          <p></p>
        </div>
        <output ref="output"></output>
        <div ref="prompt" class="InputLine">
          <div v-if="!processing" class="InputLine__Content">
            <div class="Prompt">
              <div>{{ banner.sign ? banner.sign : ">>" }}</div>
            </div>

            <input
              ref="cmd"
              v-model="value"
              class="CommandLine"
              autofocus
              @keydown.enter="enter($event)"
              @keydown.up="historyUp()"
              @keydown.down="historyDown()"
              @keydown.tab="completion($event)"
              @keydown.esc="close()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terminal",
  props: {
    shellInput: {
      type: String,
      required: false,
      default: null,
    },
    banner: {
      type: Object,
      required: false,
      default: () => {
        return {
          header: "Vue Shell",
          subHeader: "Shell is power just enjoy 🔥",
          helpHeader: 'Enter "help" for more information.',
          sign: "VueShell $",
        };
      },
    },
    commands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: "",
      processing: false,
      history: [],
      historyPosition: 0,
      historyTemporary: 0,
    };
  },
  computed: {
    allCommands() {
      const tab = [
        { name: "help", desc: "Show all the commands that are available" },
        { name: "clear", desc: "Clear the terminal of all output" },
        { name: "quit", desc: "Close the terminal" },
      ];
      if (this.commands) {
        this.commands.forEach(({ name, desc }) => {
          tab.push({ name, desc });
        });
      }
      return tab;
    },
    progressClassNames() {
      return {
        Progress: true,
        "Progress--Visible": this.processing,
      };
    },
  },
  watch: {
    shellInput(val) {
      this.output(val);
      this.$parent.send_to_terminal = "";
    },
  },
  methods: {
    historyUp() {
      if (this.history.length) {
        if (this.history[this.historyPosition]) {
          this.history[this.historyPosition] = this.value;
        } else {
          this.historyTemporary = this.value;
        }
      }
      this.historyPosition -= 1;
      if (this.historyPosition < 0) {
        this.historyPosition = 0;
      }
      this.value = this.history[this.historyPosition]
        ? this.history[this.historyPosition]
        : this.historyTemporary;
      this.moveCaretToEnd();
    },
    historyDown() {
      if (this.history.length) {
        if (this.history[this.historyPosition]) {
          this.history[this.historyPosition] = this.value;
        } else {
          this.historyTemporary = this.value;
        }
      }
      this.historyPosition += 1;
      if (this.historyPosition > this.history.length) {
        this.historyPosition = this.history.length;
      }
      this.value = this.history[this.historyPosition]
        ? this.history[this.historyPosition]
        : this.historyTemporary;
      this.moveCaretToEnd();
    },
    moveCaretToEnd() {
      requestAnimationFrame(() =>
        this.$refs.cmd.setSelectionRange(this.value.length, this.value.length)
      );
    },
    completion(e) {
      e.preventDefault();
    },
    appendToOutput() {
      // Duplicate current input and append to output section.
      const line = this.$refs.cmd.parentNode.cloneNode(true);
      line.removeAttribute("id");
      line.classList.add("line");
      const input = line.querySelector("input.CommandLine");
      input.autofocus = false;
      input.readOnly = true;
      this.$refs.output.appendChild(line);
    },
    async enter() {
      let args = [];
      let cmd;
      if (this.value) {
        this.history[this.history.length] = this.value;
        this.historyPosition = this.history.length;
      }
      this.appendToOutput();
      if (this.value && this.value.trim()) {
        args = this.value.split(" ").filter((val) => val);
        cmd = args[0].toLowerCase();
        args = args.splice(1); // Remove cmd from arg list.
      }
      if (cmd === "clear") {
        this.clear();
      } else if (cmd === "quit") {
        this.close();
        this.clear();
      } else if (cmd === "help") {
        this.help();
      } else if (this.commands) {
        const command = this.commands.find((a) => cmd === a.name);
        if (command) {
          this.processing = true;
          const output = await command.get(args || []);
          if (Array.isArray(output)) {
            await this.handlePartialOutput(output);
          } else {
            this.output(output);
          }
          this.processing = false;
        } else {
          this.output(
            `<div><error>Command not found</error>, type "help" to list available commands.</div>`
          );
        }
      }
      requestAnimationFrame(() => {
        this.$refs.prompt.scrollIntoView({ behavior: "smooth" });
        this.$refs.cmd.focus();
      });
    },
    async handlePartialOutput([value, next]) {
      this.partialOutput(value);
      const nextOutput = await next();
      if (Array.isArray(nextOutput)) {
        await this.handlePartialOutput(nextOutput);
      } else {
        this.output(nextOutput);
      }
    },
    help() {
      const commandsList = this.allCommands.map(({ name, desc }) =>
        desc ? `<dt>${name}</dt><dd>${desc}</dd>` : `<dt>${name}</dt>`
      );
      this.output(`<dl class="CommandList">${commandsList.join("")}</dl>`);
    },
    clear() {
      this.$refs.output.innerHTML = "";
      this.value = "";
      this.$emit("clear");
    },
    close() {
      console.log("close");
      this.$emit("close");
    },
    partialOutput(html) {
      this.$refs.output.insertAdjacentHTML("beforeEnd", `<pre>${html}</pre>`);
      requestAnimationFrame(() => {
        this.$refs.screen.scrollTop = this.$refs.screen.scrollHeight;
      });
    },
    output(html) {
      this.$refs.output.insertAdjacentHTML("beforeEnd", `<pre>${html}</pre>`);
      this.value = "";
    },
  },
};
</script>

<style lang="css" scoped>
.Progress {
  height: 4px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.Progress--Visible {
  opacity: 1;
}
.Progress__Bar {
  animation: progressBar 3s ease-in-out infinite;
  animation-fill-mode: both;
  height: 100%;
  background-color: #3a8b17;
}
@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.Container {
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 1em;
  font-family: Inconsolata, monospace;
  height: 100vh;
  user-select: none;
}

.Container output {
  width: 100%;
}

.Screen {
  overflow: auto;
  height: 100%;
  padding: 0.5em 1.5em 1em 1em;
}

.Screen::-webkit-scrollbar {
  width: 12px;
}

.Screen::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #000;
}

.Screen::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.Banner {
}

.Screen >>> pre {
  font-size: 1em;
  font-family: Inconsolata, monospace;
  white-space: normal;
  margin-bottom: 0;
}

.Screen >>> dt {
  margin-top: 1rem;
}

.Screen >>> dt:first-child {
  margin-top: 0;
}

.Screen >>> dd {
  margin-bottom: 0;
}

.Screen >>> dd {
  margin-left: 1rem;
}

.InputLine__Content {
  display: flex;
  flex-direction: row;
}

.InputLine > div:nth-child(2) {
  flex: 1;
}

.Prompt {
  white-space: nowrap;
  color: #3a8b17;
  margin-right: 7px;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.CommandLine {
  outline: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  font: inherit;
  border: none;
  color: inherit;
}

.CommandList {
  height: 45px;
  column-width: 100px;
}
</style>
