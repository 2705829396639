<template>
  <div class="ProductInfo product-summary ui_product_info">
    <div>
      <div v-if="showName" class="product-summary__title">
        <h4 class="prod_title rel_pos ui_label_name">
          <UiLockIcon v-if="product.locked" />
          {{ product.name }}
          <button
          v-if="!isReadOnlyMode"
          class="editButton ml-2 ui_button_edit_name"
          @click="editProductName"
          >
          <PencilIcon class="icon" />
        </button>
      </h4>
        </div>
        <div class="d-flex flex-row-reverse justify-content-between mt-4 mb-4">
        <router-link
        v-if="hasExternalLink"
          class="config"
          :to="{ path: '/' }"
        >
        External link
          <i class="fas fa-external-link-alt ml-2"></i>
        </router-link>
      </div>
      </div>

      <div
        v-if="productTypes.QUESTIONNAIRE === product.type"
        class="product-summary__title"
      >
        <h5 class="rel_pos ui_label_name">{{ product.subtype }}</h5>
        <button
          v-if="!isReadOnlyMode"
          class="editButton ml-2 ui_button_edit_name"
          @click="editProductSubtype"
        >
          <PencilIcon class="icon" />
        </button>
      </div>
      <div
        v-if="complexity && complexity.complexity >= 0"
        class="complexity block"
      >
        {{ $t("product.complexity.name") }} :
        <div
          class="complexity-score"
          :class="{
            green: complexity.complexity < 100,
            yellow: complexity.complexity >= 100 && complexity.complexity < 200,
            orange: complexity.complexity >= 200 && complexity.complexity < 300,
            red: complexity.complexity >= 300,
          }"
        >
          {{ complexity.complexity }}
          <el-tooltip content>
            <div id="complexity-tooltip" slot="content">
              <div class="complexity-title">
                {{ $t("product.complexity.details") }}
              </div>
              <div
                v-for="(value, source) in complexity.complexityDetails"
                :key="`${source}`"
                class="complexity-breakdown"
              >
                {{ source }} :
                <div v-if="value < 100" class="complexity-score green">
                  {{ value }}
                </div>
                <div v-else-if="value < 200" class="complexity-score yellow">
                  {{ value }}
                </div>
                <div v-else-if="value < 300" class="complexity-score orange">
                  {{ value }}
                </div>
                <div v-else class="complexity-score red">{{ value }}</div>
              </div>
              <div class="complexity-title">
                {{ $t("product.complexity.stats") }}
              </div>
              <div>
                {{ $t("product.complexity.nbProperties") }} :
                {{ complexity.nbProperties }}
              </div>
              <div>
                {{ $t("product.complexity.nbNodes") }} :
                {{ complexity.nbNodes }}
              </div>
              <div>
                {{ $t("product.complexity.nbLines") }} :
                {{ complexity.nbLines }}
              </div>
              <div>
                {{ $t("product.complexity.maxDepth") }} :
                {{ complexity.maxDepth }}
              </div>
            </div>
            <div class="complexity-tooltip-toggle">?</div>
          </el-tooltip>
        </div>
      </div>
      <div v-if="product.metadata.product_scheme" class="row mt-2 ml-1">
        <div class="scheme ui_scheme">
          {{ product.metadata.product_scheme }}
        </div>
      </div>
      <div v-if="validFrom" class="row mt-2 ml-1">
        <div class="validity ui_validity">
          {{ $t("product.validity") + ": " + validFrom + " - " + validTo }}
        </div>
      </div>
      <div v-if="travelBefore" class="row mt-2 ml-1">
        <div class="validity ui_validity">
          {{ $t("product.travel_before") + ": " + travelBefore }}
        </div>
      </div>
      <div v-if="description" class="row mt-2 ml-1 ui_description">
        <div class="description">{{ description }}</div>
      </div>
      <div
        v-if="editable && showDefaultLanguage"
        class="row mt-2 ml-1 ui_default_language"
      >
        <label for="product_default_language">Default Language</label>
        <UiInlineDropDown
          id="product_default_language"
          :value="product.defaultLanguage"
          :is-editable="!isReadOnlyMode"
          :toggle-editing="true"
          :items="availableLanguages"
          :handle-change="editProductDefaultLanguage"
        >
          <template #label>{{ defaultLanguageLabel }}</template>
        </UiInlineDropDown>
      </div>
      <div class="row mt-2 ml-1">
        <div class="description ui_date_created">
          {{ $t("product.created") + " " + created }}
        </div>
        <div class="description ml-4 ui_date_modified">
          {{ $t("product.modified") + " " + modified }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { MessageBox } from "element-ui";
import moment from "moment";
import { noop, get } from "lodash";
import PencilIcon from "../assets/images/pencil-1.svg";
import { productTypes } from "../const/product";
import { availableLanguages } from "../helpers/translations";
import { dateFormatter } from "../helpers";

export default {
  name: "ProductInfo",
  components: {
    PencilIcon,
  },
  props: {
    showName: {
      type: Boolean,
      default: true,
    },
    showDefaultLanguage: {
      type: Boolean,
      default: true,
    },
    product: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasExternalLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    productTypes,
    availableLanguages,
  }),
  computed: {
    ...mapGetters("product", ["isReadOnly", "complexity"]),
    ...mapGetters("auth", ["isGuest"]),
    isReadOnlyMode() {
      return this.isReadOnly || this.isGuest(this.product.team.slug);
    },
    description() {
      return this.product.metadata.description;
    },
    created() {
      return this.dateToString(this.product.createdAt);
    },
    modified() {
      return this.dateToString(this.product.updatedAt);
    },
    validFrom() {
      const value = get(this.product, "metadata.from_time");
      return value ? moment(value).format("lll") : null;
    },
    validTo() {
      const value = get(this.product, "metadata.to_time");
      return value ? moment(value).format("lll") : null;
    },
    travelBefore() {
      const value = get(this.product, "metadata.travel_before");
      return value ? moment(value).format("lll") : null;
    },
    hasValidityDates() {
      // TODO not used ??
      return this.product.metadata.from_time && this.product.metadata.to_time;
    },
    defaultLanguageLabel() {
      return (
        (
          this.availableLanguages.find(
            (lang) => lang.key === this.product.defaultLanguage
          ) || {}
        ).label || ""
      );
    },
  },

  methods: {
    ...mapActions("product", [
      "setName",
      "setDefaultLanguage",
      "setSubtype",
      "setProductConfigActiveTab",
    ]),
    editProductName() {
      MessageBox.prompt("Name", "Rename product", {
        inputValue: this.product.name,
        inputPattern: /^[\w)(\s-]+$/,
        inputErrorMessage: this.$t("product.invalid_name_characters"),
      })
        .then(async ({ value }) => {
          await this.setName({ name: value });
          this.sendToConfig();
        })
        .catch(noop);
    },
    editProductDefaultLanguage(value) {
      window.localStorage.setItem("productLocale", value);
      this.setDefaultLanguage({
        defaultLanguage: value,
      });
    },
    editProductSubtype() {
      MessageBox.prompt("Subtype", "Edit", {
        inputValue: this.product.subtype,
        inputPattern: /^[\w)(\s-]+$/,
        inputErrorMessage: this.$t("product.invalid_name_characters"),
      })
        .then(async ({ value }) => {
          await this.setSubtype({ productId: this.product.id, subtype: value });
          this.sendToConfig();
        })
        .catch(noop);
    },
    dateToString(date) {
      return dateFormatter(date, "lll");
    },
    sendToConfig() {
      if (!this.editable) {
        this.setProductConfigActiveTab({ activeTab: "metadata" });
        this.$router.push({
          name: "product-configuration",
          params: { productId: this.product.version.latest },
          query: this.$route.query,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

.rel_pos,
.row {
  position: relative;
  margin-right: 0;
}

.product-summary__title {
  display: flex;
  align-items: center;

  .icon::v-deep {
    margin-top: -4px;
  }

  h4,
  h5 {
    flex: 1;
  }
}

.prod_title {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  position: relative;
  color: $color-dark-text;
  font-weight: 100;
  font-style: italic;
}

.editButton,
h4,
h5 {
  position: relative;
  display: inline;
}

.editButton {
  cursor: pointer;
  color: gray;
  background: white;
  border: 0;
}

.complexity {
  display: flex;
  font-size: 18px;
}

#complexity-tooltip {
  .complexity-title {
    font-weight: bold;
    font-size: 16px;
  }
  .complexity-breakdown {
    display: flex;
    align-items: center;
  }
}

.complexity-score {
  height: 20px;
  line-height: 20px;
  margin-left: 5px;
  color: white;
  border-radius: 4px;
  padding: 0 5px;
  font-weight: initial;
  align-self: center;
  display: flex;

  .complexity-tooltip-toggle {
    height: 12px;
    width: 12px;
    line-height: 10px;
    background-color: white;
    border-radius: 6px;
    text-align: center;
    padding: 1px;
    font-size: 12px;
    align-self: center;
    margin-left: 5px;
    cursor: pointer;
  }
  &.red {
    background-color: #fc4946;
    .complexity-tooltip-toggle {
      color: #fc4946;
    }
  }
  &.orange {
    background-color: orange;
    color: initial;
    .complexity-tooltip-toggle {
      color: orange;
      background-color: black;
    }
  }
  &.yellow {
    background-color: yellow;
    color: initial;
    .complexity-tooltip-toggle {
      color: yellow;
      background-color: black;
    }
  }
  &.green {
    background-color: #39db85;
    .complexity-tooltip-toggle {
      color: #39db85;
    }
  }
}
.config {
  position: relative;
  margin-left: 1em;
  padding: 0.15em 0.7em;
  border: solid 1px $color-active;
  border-radius: 5px;
  box-shadow: 0 0 0 2px #f5f5f5;
}
</style>
