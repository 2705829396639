/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import DOMPurify from 'dompurify';

const getDisplayName = (answer) => {
  if (answer.metadata) {
    const answerText = answer.metadata.find(
      (meta) => meta.key === 'displayName'
    );
    if (answerText && answerText.value && answerText.value.toString().trim()) {
      return answerText.value;
    }
  }
  return answer.id;
};

export default class Answer {
  id;

  displayName;

  metadata;

  priority;

  constructor(answer) {
    this.displayName = getDisplayName(answer);
    this.id = answer.id;
    this.priority = answer.priority;
    if (answer.metadata) {
      this.metadata = answer.metadata;
    }
  }

  update(answer) {
    this.displayName = getDisplayName(answer);
    if (answer.priority) {
      this.priority = answer.priority;
    }
    if (answer.metadata) {
      this.metadata = answer.metadata;
    }
  }

  contains(input = null) {
    const lcInput = input.toLowerCase();
    return (
      this.displayName.toLowerCase().includes(lcInput) ||
      this.id.toLowerCase().includes(lcInput)
    );
  }

  // eslint-disable-next-line class-methods-use-this
  mark(terms, nameString) {
    const marked = Array(nameString.length).fill(false);
    terms.forEach((term) => {
      const inputIndex = nameString.toLowerCase().indexOf(term);
      if (inputIndex !== -1) {
        for (let i = 0; i < term.length; i += 1) {
          marked[i + inputIndex] = true;
        }
      }
    });
    let result = '';
    let marking = false;
    for (let i = 0; i < nameString.length; i += 1) {
      if (marked[i] && !marking) {
        result += '<mark>';
        marking = true;
      }
      if (!marked[i] && marking) {
        result += '</mark>';
        marking = false;
      }
      result += nameString[i];
    }
    return result;
  }

  html(input = '') {
    return DOMPurify.sanitize(
      this.mark(input.toLowerCase().split(' '), this.displayName)
    );
  }

  countryCode() {
    const res = this.metadata ? this.metadata['ISO 3166-1'] || '404' : '404';
    return res.toLowerCase();
  }
}
