<template>
  <div>
    <el-form-item
      :ref="`question-${question.id}`"
      class="question-container"
      :prop="question.formProp()"
    >
      <template slot="label">
        <div>
          <div :title="question.displayName" class="question-text">
            {{ question.displayName }}
          </div>
        </div>
      </template>

      <div>
        <template>
          <component
            :is="componentName"
            ref="input"
            mode="form"
            class="input"
            label=""
            :question="question"
            :answered="false"
            @focus="focusQuestion"
            @answer-question="answerQuestion"
            @clear-question="clearQuestion"
            @question-answered="questionAnswered"
            @show="inputShow"
          ></component>
        </template>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import InputBooleanComponent from "./InputBoolean.vue";
import InputComboComponent from "./InputCombo.vue";
import InputTextComponent from "./InputText.vue";
import InputNumberComponent from "./InputNumber.vue";
import InputDateComponent from "./InputDate.vue";
import InputDateTimeComponent from "./InputDateTime.vue";
import InputMonetaryComponent from "./InputMonetary.vue";

export default {
  name: "Question",
  components: {
    InputBooleanComponent,
    InputComboComponent,
    InputTextComponent,
    InputNumberComponent,
    InputDateComponent,
    InputDateTimeComponent,
    InputMonetaryComponent,
  },
  props: {
    question: { type: Object, default: null },
  },
  data() {
    return {
      captureTabDisabled: true,
    };
  },
  computed: {
    componentName() {
      const componentType = this.question.type.toUpperCase();
      const components = {
        ENUM: "input-combo-component",
        TEXT: "input-text-component",
        NUMBER: "input-number-component",
        DATE: "input-date-component",
        DATETIME: "input-date-time-component",
        MONETARYAMOUNT: "input-monetary-component",
        BOOLEAN: "input-boolean-component",
      };

      return components[componentType] || components.TEXT;
    },
    form() {
      return this.$parent.form;
    },
  },
  mounted() {
    this.clearValidation();
    const defaultValue = this.question.getMeta("defaultValue");
    if (defaultValue) {
      const value =
        this.question.type === "boolean"
          ? defaultValue.toLowerCase()
          : defaultValue;
      this.$refs.input.setValue(value);
    }
  },
  methods: {
    focusQuestion() {
      this.$emit("focusQuestion");
    },
    answerQuestion(data) {
      this.$emit("answerQuestion", data);
    },
    clearQuestion(question) {
      this.$emit("clearQuestion", question);
    },
    questionAnswered(data) {
      this.$emit("questionAnswered", data);
    },
    inputShow() {
      this.$emit("inputShow");
    },
    clearValidation() {
      if (this.$refs[`question-${this.question.id}`]) {
        this.$nextTick(() => {
          if (!this.submitted) {
            this.$refs[`question-${this.question.id}`].clearValidate();
          }
        });
      }
    },
  },
};
</script>
