<template>
  <div v-selector.view class="container">
    <div class="row justify-content-center">
      <div class="col-xs-6">
        <transition
          mode="out-in"
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <Loading v-if="isLoading" message="Logging you in…" class="py-4" />
          <form v-if="!isLoading" class="form pb-4 mt-4 px-5" :action="oidcUrl">
            <h2 class="form-title mt-5 mb-3 pb-3" data-cy="login-title">
              {{ $t("auth.login") }}
            </h2>
            <div class="form-group">
              <button
                class="btn btn-lg btn-axa btn-block"
                data-cy="login-button"
              >
                Login with OpenID Connect
              </button>
            </div>
          </form>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Loading from "../components/Loading.vue";

export default {
  name: "Login",
  components: { Loading },
  beforeRouteEnter(to, from, next) {
    window.location.replace(
      `${window.__env__.VUE_APP_AUTH_BASE_URL}/api/auth/oauth2/oidc`
    );
    next(false);
  },
  computed: {
    ...mapState("auth", ["isLoading", "error", "user"]),
    ...mapGetters("auth", ["isLoggedIn"]),
    passwordError() {
      return false;
    },
    intended() {
      const { query } = this.$route;
      return query && query.intended;
    },
    oidcUrl() {
      const intended = this.intended && this.intended.replace("/", "");
      return `${window.__env__.VUE_APP_AUTH_BASE_URL}/api/auth/oauth2/oidc${
        this.intended ? `?intended=${intended}` : ""
      }`;
    },
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler(isLoggedIn) {
        const islogin = isLoggedIn;
        if (islogin) {
          const redirectTo = this.intended || "/";
          this.$router.replace(redirectTo);
        }
      },
    },
  },
  mounted() {
    if (this.$refs.username) {
      this.$refs.username.focus();
    }
    if (this.$cookie.get("oidc-failure")) {
      this.setError("OpenID Connect failed");
    }
  },
  methods: {
    ...mapActions("auth", ["setError"]),
  },
};
</script>

<style lang="scss">
.local-login-link {
  font-size: 12px;
  text-align: right;
}
</style>
