<template>
  <div class="d-flex p-2 pt-2">
    <div v-if="leftCondition()" class="leftContainer">
      {{ left }}
    </div>
    <div
      class="rightContainer d-flex flex-nowrap"
      :class="{ withoutLeft: !leftCondition() }"
    >
      {{ right }}
      <i
        v-if="deleteEnabled"
        class="deleteIcon el-icon-close"
        @click="onClickDelete"
      >
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoleTag",
  inheritAttrs: false,
  props: {
    data: {
      type: Object,
    },
    left: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    leftCondition: {
      type: Function,
      default() {
        return true;
      },
    },
    deleteEnabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClickDelete() {
      // eslint-disable-next-line no-console
      this.$emit("delete", {
        data: this.data,
        left: this.left,
        right: this.right,
      });
    },
  },
};
</script>

<style lang="scss">
.leftContainer {
  background: #e8e8f0;
  padding: 5px;
  border-radius: 4px 0px 0px 4px;
  font-weight: 600;
  cursor: pointer;
}

.rightContainer {
  background: #f2f2f9;
  padding: 5px;
  border-radius: 0px 4px 4px 0px;
  align-items: center;
  cursor: default;
}

.withoutLeft {
  border-radius: 4px 4px 4px 4px;
}

.deleteIcon {
  color: #bebec0;
  cursor: pointer;
}
</style>
