<template>
  <div>
    <el-row class="p-2"
      ><span class="font-weight-bold">Team selection</span></el-row
    >
    <el-row class="modalSearch pl-2">
      <el-col :span="6">
        <el-select v-model="selectedTeam">
          <el-option key="all-teams" label="All" :value="null"> </el-option>
          <el-option
            v-for="team in teamsList"
            :key="team.teamId"
            :label="team.label"
            :value="team.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="18" class="d-flex justify-content-end">
        <UiButton
          v-if="tenant && (isSuperAdmin() || isOwner(tenant.slug))"
          class="addButton"
          :data-cy="'team-add-button'"
          @click="addNewTeam"
        >
          {{ $t("team.create-new-team") }}
        </UiButton>
        <UiSearchBox
          v-model="searchStr"
          :placeholder="$t('team.search-team')"
        />
      </el-col>
    </el-row>
    <UiTable class="membersTable" :data="searchedTeams" @click="setEditingRow">
      <el-table-column label="Team name" width="200">
        <template slot-scope="scope">
          <template v-if="scope.row.teamId === editingTeamId">
            <div class="team-select">
              <el-input
                v-model="editingLabel"
                :placeholder="$t('team.team-name-placeholder')"
                :disabled="isDefaultTeam(scope.row)"
                @input="checkTeamEditChanges(scope.row, true)"
              ></el-input>
            </div>
          </template>
          <template v-else>
            <div>{{ scope.row.label }}</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="Members">
        <template slot-scope="scope">
          <div
            v-if="scope.row.users && scope.row.users.length"
            class="d-flex flex-wrap p-0"
          >
            <RoleTag
              v-for="(user, index) of scope.row.users"
              :key="index"
              :data="{ teamId: scope.row.teamId, userId: user.id }"
              :left="user.email"
              :right="userRoleLabelById(scope.row, user.id)"
              :delete-enabled="canDeleteMember(scope.row, user)"
              @delete="deleteRole"
            />
          </div>
          <div v-else>No team members</div>
        </template>
      </el-table-column>
      <el-table-column width="300">
        <template slot-scope="scope">
          <el-row
            v-if="scope.row.teamId === editingTeamId"
            class="d-flex align-items-end"
          >
            <div class="new-member-team-role">
              Edit/Add:
              <el-select
                v-model="editingUser"
                filterable
                placeholder="Select"
                @change="checkTeamEditChanges"
              >
                <el-option
                  v-for="user in allowedMembers()"
                  :key="user.id"
                  :label="user.email"
                  :value="user.id"
                >
                </el-option>
              </el-select>
              as
              <el-select
                v-model="editingRole"
                filterable
                placeholder="Select"
                @change="checkTeamEditChanges"
              >
                <el-option
                  v-for="(role, index) in getPossibleRoles(scope.row)"
                  :key="index"
                  :label="role.label"
                  :value="role.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column :min-width="10">
        <template slot-scope="scope">
          <i
            v-if="canDeleteTeam(scope.row, tenant)"
            class="deleteIcon el-icon-close"
            @click="deleteTeam(scope.$index)"
          />
        </template>
      </el-table-column>
    </UiTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { MessageBox } from "element-ui";
import { noop } from "lodash";
import RoleTag from "./RoleTag";
import { slugify } from "../../filters/slugify";

export default {
  name: "TenantEditionTeams",
  components: {
    RoleTag,
  },
  props: { tenant: { type: Object, default: undefined } },
  data: () => ({
    tenantName: null,
    disabled: true,
    tenantSlug: "",
    selectedTeam: null,
    searchStr: "",
    selectedTeams: [],
    editingTeamId: null,
    editingLabel: "",
    editingUser: null,
    editingRole: null,
    teamsList: [],
  }),
  computed: {
    ...mapState("users", ["users", "roles"]),
    ...mapState("auth", ["user"]),
    ...mapState("teams", ["teams"]),
    ...mapGetters("auth", ["isOwner", "isSuperAdmin", "isAdmin"]),
    searchedTeams() {
      const str = this.searchStr.toLowerCase();
      return this.selectedTeams
        .filter((t) => t.label.includes(str))
        .filter((t) =>
          this.selectedTeam ? t.teamId === this.selectedTeam : true
        );
    },
  },
  watch: {
    tenant: {
      async handler() {
        this.updateData();
      },
    },
    tenantSlug(newTeamSlug) {
      this.tenantSlug = slugify(newTeamSlug.toLowerCase(), {
        lowercase: true,
      });
    },
  },
  async mounted() {
    this.updateData();
  },
  methods: {
    ...mapActions("users", ["getUsers", "getUsersByTenant"]),
    ...mapActions("teams", ["saveTenant", "createTeam", "loadTeams"]),
    async changeSlug() {
      try {
        await MessageBox.confirm(
          "Changing the Tenant Slug may break all API consuming the data of this team. Are you certain you want to continue?"
        );
        this.disabled = false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    addNewTeam() {
      this.$emit("create-team-clicked", this.selectedTeams);
      this.searchStr = "";
      this.editingTeamId = null;
    },
    userRoleById(team, userId) {
      if (!team.users) return null;
      const user = team.users.find((u) => u.id === userId);
      return user ? this.roleById(user.team_role_id) : null;
    },
    roleById(roleId) {
      return this.roles.find((role) => role.id === roleId);
    },
    transformUserTeams(team, users) {
      if (!users) return users;
      const transformedUsers = users.map((u) => {
        const user = { ...u };
        const currentTeam = u.teams && u.teams.find((t) => t.id === team.id);
        if (currentTeam) user.team_role_id = currentTeam.UserTeam.team_role_id;
        return user;
      });
      return transformedUsers;
    },
    async updateData() {
      if (this.teams.some((t) => t.users && t.users.some((u) => !u.teams)))
        await this.loadTeams({ includeUsers: true });

      this.teamsList =
        this.teams &&
        this.tenant &&
        this.teams.filter((t) => t.tenant === this.tenant.slug);

      this.selectedTeams = [];
      if (this.tenant) {
        this.tenantName = this.tenant.label;
        this.tenantSlug = this.tenant.slug;
        this.teamsList.forEach((team) => {
          this.selectedTeams.push({
            label: team.label,
            slug: team.slug,
            teamId: team.id,
            users: this.transformUserTeams(team, team.users),
          });
        });
      }
    },
    isEditableRow(row) {
      return row.email === "";
    },
    userRoleLabelById(team, userId) {
      const role = this.userRoleById(team, userId);
      return role ? role.label : "";
    },
    canDeleteMember(team, member) {
      const hasTeamPrivileges =
        this.isSuperAdmin() || this.isOwner(team.tenant);
      const isCurrentUser = member.email === this.user.email;
      return hasTeamPrivileges && !isCurrentUser && !this.isDefaultTeam(team);
    },
    canDeleteTeam(team, tenant) {
      const hasTeamPrivileges =
        this.isSuperAdmin() || this.isOwner(tenant.slug);
      return !this.isDefaultTeam(team) && hasTeamPrivileges;
    },
    async deleteTeam(index) {
      try {
        await MessageBox.confirm(
          "Are you certain that you want to delete the team?"
        );
        const toRemove = this.selectedTeams[index];
        this.$emit("team-delete", toRemove);
      } catch (err) {
        noop();
      }
    },
    deleteRole(value) {
      const { data } = value;
      const team = this.selectedTeams.find((t) => t.teamId === data.teamId);
      const userIndex = team.users.findIndex((u) => u.id === data.userId);
      if (userIndex > -1) team.users.splice(userIndex, 1);
      this.$emit("user-team-changed", {
        teamId: data.teamId,
        userId: data.userId,
        team_role_id: null,
      });
      this.cancelEditingRow();
    },
    checkTeamEditChanges(row, checklabel = false) {
      if (checklabel && this.editingLabel) {
        if (
          this.teams.some(
            (t) => t.slug === this.editingLabel || t.label === this.editingLabel
          )
        ) {
          this.$emit("can-save", {
            canSave: false,
            message: this.$t("team.name-duplicated"),
          });
          return;
        }
      }
      this.$emit("can-save", { canSave: true });
      this.newTeamSelected(row);
      this.$emit("teams-changed", this.selectedTeams);
    },
    newTeamSelected() {
      const editingTeam = this.selectedTeams.find(
        (t) => t.teamId === this.editingTeamId
      );
      const user = this.findUserById(this.editingUser);
      editingTeam.label = this.editingLabel;
      if (this.editingUser && this.editingRole) {
        const foundUserIndex =
          editingTeam.users &&
          editingTeam.users.findIndex((u) => {
            return u.id === this.editingUser;
          });
        if (foundUserIndex > -1) {
          editingTeam.users[foundUserIndex].team_role_id = this.editingRole;
        } else {
          const newUser = {
            email: user.email,
            id: user.id,
            team_role_id: this.editingRole,
          };
          if (!editingTeam.users) editingTeam.users = [];
          editingTeam.users.push(newUser);
        }
        this.$emit("user-team-changed", {
          teamId: this.editingTeamId,
          userId: user.id,
          team_role_id: this.editingRole,
        });
        this.editingRole = null;
        this.editingUser = null;
      }
    },
    getPossibleRoles(team) {
      if (team.slug !== this.tenant.slug) {
        // Teams only have members and guests
        return this.roles.filter((r) => r.id > 3 && r.id <= 5);
      }
      return this.roles.filter((r) => r.id > 1 && r.id <= 5);
    },
    allowedMembers() {
      return this.users
        .filter(
          (user) =>
            !this.isSuperAdmin(user) &&
            user.teams.some((t) => t.tenant === this.tenant.slug) &&
            !user.teams.some(
              (t) =>
                t.tenant === this.tenant.slug && t.UserTeam.tenant_role_id <= 3
            )
        )
        .sort(this.sortByEmail);
    },
    sortByEmail(a, b) {
      return a.email.localeCompare(b.email);
    },
    findUserById(id) {
      return this.users.find((u) => u.id === id);
    },
    setEditingRow(value) {
      this.editingTeamId = value.teamId;
      this.editingLabel = value.label;
      this.editingRole = null;
      this.editingUser = null;
    },
    findTeamById(id) {
      return this.teams.find((t) => t.id === id);
    },
    isDefaultTeam(team) {
      const foundTeam = this.findTeamById(team.teamId);
      return foundTeam && foundTeam.slug === this.tenant.slug;
    },
    cancelEditingRow() {
      this.editingTeamId = null;
      this.editingLabel = null;
      this.editingRole = null;
      this.editingUser = null;
    },
  },
};
</script>

<style lang="scss">
@import "@axatechlab/assets/scss/_variables";
.role-select .el-select .el-input__inner,
.user-select .el-select .el-input__inner {
  border: 0;
}
.modalSearch .search-box {
  font-size: medium;
  max-width: 15%;
  padding-left: 10px;
}
.modalSearch .search-box input.ui_input_search {
  overflow: hidden;
  font-size: medium;
}
.addButton {
  margin-left: 0.5rem;
}
.edit-tenant .el-dialog__body {
  padding: 0;
}

.edit-tenant .el-dialog__title {
  font-size: 24px;
}

.edit-tenant .el-dialog__header {
  padding: 22px 28px;
}
.edit-tenant .ui-large-tabs .el-tabs__header {
  padding: 0 28px;
  background: #ededf4;
}

.edit-tenant .el-tabs__item.is-active {
  font-weight: bold;
}

.edit-tenant .ui-large-tabs .el-tabs__item:not(.is-active) {
  color: #00008f;
}

.edit-tenant .el-tabs__content {
  padding: 35px 28px;
}

.edit-tenant h3 {
  font-size: 24px;
  font-weight: bold;
  color: #00008f;
}

.team-select {
  min-width: 150px;
}

.edit-tenant .leftContainer {
  background: #e8e8f0;
  font-size: 10px;
  font-weight: 600;
  border: solid 1px transparent;
}

.edit-tenant .rightContainer {
  background: #fff;
  border: solid 1px #ebebf2;
  font-size: 10px;
  font-weight: 600;
}

.edit-tenant .default-team .leftContainer {
  background-color: #00008f;
  color: #fff;
}

.edit-tenant .default-team .rightContainer {
  color: #00008f;
  border-color: #00008f;
}
</style>
