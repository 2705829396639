<template>
  <el-dialog
    :visible="$attrs.visible"
    :title="`${$t('team.edit-tenant')}`"
    :before-close="() => $emit('close')"
    width="90%"
    class="edit-tenant"
  >
    <UiLargeTabs v-model="activeTab">
      <el-tab-pane label="General" name="tenants-general-tab">
        <TenantEditionGeneral
          :tenant="tenant"
          @roles-changed="setUsers"
          @slug-changed="setSlug"
          @name-changed="setTenantName"
        ></TenantEditionGeneral>
      </el-tab-pane>
      <el-tab-pane label="Teams" name="tenants-teams-tab">
        <TenantEditionTeams
          :tenant="tenant"
          @can-save="canSaveTeams"
          @teams-changed="editTeams"
          @team-delete="removeTeam"
          @user-team-changed="setSelectedTeams"
          @create-team-clicked="startTeamCreation"
        ></TenantEditionTeams>
      </el-tab-pane>
      <el-tab-pane label="API Token" name="tenants-tokens-tab">
        <TenantEditionTokens :tenant="tenant"></TenantEditionTokens>
      </el-tab-pane>
    </UiLargeTabs>
    <div slot="footer">
      <span class="dialog-footer">
        <span
          v-if="!canSave && userInfo"
          class="p-2 float-left duplicate-name"
          >{{ userInfo }}</span
        >
        <el-button
          class="btn ui_button_back"
          :disabled="!canSave"
          @click="updateTenant"
          >{{ $t("action.save") }}</el-button
        >
        <el-button class="btn ui_button_back" @click="$emit('close')">{{
          $t("action.cancel")
        }}</el-button>
      </span>
    </div>
    <TeamCreationModal
      :visible="showTeamCreationDialog"
      :tenant="tenant"
      @close="finishTeamCreation"
    />
  </el-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { MessageBox, Message } from "element-ui";
import TeamCreationModal from "./TeamCreationModal.vue";
import TenantEditionGeneral from "./TenantEditionGeneral.vue";
import TenantEditionTeams from "./TenantEditionTeams.vue";
import TenantEditionTokens from "./TenantEditionTokens.vue";

export default {
  name: "TenantEditionModal",
  components: {
    TeamCreationModal,
    TenantEditionGeneral,
    TenantEditionTeams,
    TenantEditionTokens,
  },
  props: { tenant: { type: Object, default: undefined } },
  data: () => ({
    allUsers: [],
    users: [],
    userFilter: "",
    searchStr: "",
    showTeamCreationDialog: false,
    activeTab: "tenants-general-tab",
    selectedTeam: null,
    newTenantName: "",
    newTenantSlug: "",
    teamsInfo: [],
    canSave: true,
    userInfo: null,
    currentUserData: null,
  }),
  computed: {
    ...mapState("teams", ["teams", "previousActiveTab"]),
    ...mapState("auth", ["user"]),
  },
  mounted() {
    if (this.previousActiveTab) this.activeTab = this.previousActiveTab;
    this.currentUserData = {
      id: this.user.id,
      teams: this.user.teams.map((t) => {
        return {
          teamId: t.UserTeam.team_id,
          teamRoleId: t.UserTeam.team_role_id,
        };
      }),
    };
    const { tab } = this.$route.query;
    if (
      tab &&
      [
        "tenants-general-tab",
        "tenants-teams-tab",
        "tenants-tokens-tab",
      ].includes(tab)
    ) {
      this.activeTab = tab;
    }
  },
  methods: {
    ...mapActions("teams", [
      "loadTeams",
      "saveTenant",
      "saveTeams",
      "deleteTeam",
      "setPreviousActiveTab",
    ]),
    startTeamCreation() {
      this.showTeamCreationDialog = true;
    },
    async finishTeamCreation() {
      this.showTeamCreationDialog = false;
      this.setPreviousActiveTab(this.activeTab);
      await this.loadTeams({ includeUsers: true });
    },
    setSlug(newSlug) {
      this.newTenantSlug = newSlug;
    },
    setTenantName(newName) {
      this.newTenantName = newName;
    },
    setUsers(value) {
      this.users = value
        .filter((role) => role.email)
        .map((role) => {
          return {
            user_id: role.userId,
            tenant_role_id: role.roleId,
            teams: role.teams.map((t) => ({
              team_id: t.team_id,
              team_role_id: t.team_role_id,
            })),
          };
        });
    },
    removeUserFromTeam(value) {
      const userIndex = this.users.findIndex((r) => r.user_id === value.userId);
      if (userIndex > -1) {
        const teamIndex = this.users[userIndex].teams.findIndex(
          (t) => t.team_id === value.teamId
        );
        if (teamIndex > -1) {
          this.users[userIndex].teams.splice(teamIndex, 1);
        }
        if (!this.users[userIndex].teams.length) {
          this.users.splice(userIndex, 1);
        }
      }
    },
    setSelectedTeams(value) {
      if (!value.team_role_id) {
        return this.removeUserFromTeam(value);
      }
      const userIndex = this.users.findIndex((r) => r.user_id === value.userId);
      if (userIndex === -1) {
        this.users.push({
          user_id: value.userId,
          tenant_role_id: 5,
          teams: [
            {
              team_id: value.teamId,
              team_role_id: value.team_role_id,
            },
          ],
        });
      } else {
        const team = this.users[userIndex].teams.find(
          (t) => t.team_id === value.teamId
        );
        if (!team) {
          this.users[userIndex].teams.push({
            team_id: value.teamId,
            team_role_id: value.team_role_id,
          });
        } else {
          team.team_role_id = value.team_role_id;
          if (this.isDefaultTeam(team))
            this.users[userIndex].tenant_role_id = team.team_role_id;
        }
      }
      return undefined;
    },
    async updateTenant() {
      this.setPreviousActiveTab(this.activeTab);
      try {
        const currentUserNewData = this.users.find(
          (u) => u.user_id === this.currentUserData.id
        );
        let okToUpdate = true;
        if (currentUserNewData) {
          okToUpdate = currentUserNewData.teams.reduce(
            (prevResult, currentElm) => {
              const currentRole = this.currentUserData.teams.find(
                (t) => t.teamId === currentElm.team_id
              );
              if (!currentRole) return prevResult && true;
              if (currentRole.teamRoleId < currentElm.team_role_id)
                return prevResult && false;
              return prevResult && true;
            },
            true
          );
        }
        if (!okToUpdate) {
          Message.error(this.$t("team.self-demote"));
          return;
        }

        let slugToSave = this.tenant.slug;
        if (this.newTenantSlug !== this.tenant.slug) {
          slugToSave = this.newTenantSlug;
          await MessageBox.confirm(
            `Are you sure you want to change the tenant slug from "${this.tenant.slug}" to "${this.newTenantSlug}"?`,
            "Confirm new slug"
          );
        }
        await this.saveTenant({
          data: {
            label: this.newTenantName,
            slug: slugToSave,
            users: this.users.map((u) => {
              if (!u.tenant_role_id) {
                u.tenant_role_id = 5;
              }
              return u;
            }),
            teams:
              this.teams &&
              this.tenant &&
              this.teams.filter((t) => t.tenant === this.tenant.slug),
          },
        });
        const teams = this.teamsInfo.map((t) => ({
          id: t.teamId,
          label: t.label,
        }));
        await this.saveTeams({ data: teams });
        this.$emit("close");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    findTeamById(id) {
      return this.teams.find((t) => t.id === id);
    },
    isDefaultTeam(team) {
      const foundTeam = this.findTeamById(team.team_id);
      return foundTeam && foundTeam.slug === this.tenant.slug;
    },
    editTeams(value) {
      this.teamsInfo = value;
    },
    canSaveTeams({ canSave, message }) {
      this.canSave = canSave;
      this.userInfo = message || null;
    },
    async removeTeam(team) {
      this.setPreviousActiveTab(this.activeTab);
      await this.deleteTeam({ id: team.teamId });
      await this.loadTeams();
    },
  },
};
</script>
<style lang="scss">
@import "@axatechlab/assets/scss/_variables";
.membersTable {
  max-height: 400px;
  overflow: auto;
}
.avatar-circle {
  width: 44px;
  height: 44px;
  background-color: #5e77ff;
  text-align: center;
  border-radius: 50%;
  display: table;
}
.avatar-letters {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
  color: #ffffff;
}
.role-select .el-select .el-input__inner,
.user-select .el-select .el-input__inner {
  border: 0;
}
.modalSearch .search-box {
  font-size: medium;
  max-width: 15%;
  padding-left: 10px;
}
.modalSearch .search-box input.ui_input_search {
  overflow: hidden;
  font-size: medium;
}
.addButton {
  margin-left: 0.5rem;
}
.edit-tenant .el-dialog__body {
  padding: 0;
}

.edit-tenant .el-dialog__title {
  font-size: 24px;
}

.edit-tenant .el-dialog__header {
  padding: 22px 28px;
}
.edit-tenant .ui-large-tabs .el-tabs__header {
  padding: 0 28px;
  background: #ededf4;
}

.edit-tenant .el-tabs__item.is-active {
  font-weight: bold;
}

.edit-tenant .ui-large-tabs .el-tabs__item:not(.is-active) {
  color: #00008f;
}

.edit-tenant .el-tabs__content {
  padding: 35px 28px;
}

.edit-tenant h3 {
  font-size: 24px;
  font-weight: bold;
  color: #00008f;
}

.team-select {
  min-width: 150px;
}

.edit-tenant .groupContainer {
  background: #e8e8f0;
  font-size: 10px;
  font-weight: 600;
  border: solid 1px transparent;
}

.edit-tenant .roleContainer {
  background: #fff;
  border: solid 1px #ebebf2;
  font-size: 10px;
  font-weight: 600;
}

.edit-tenant .default-team .groupContainer {
  background-color: #00008f;
  color: #fff;
}

.edit-tenant .default-team .roleContainer {
  color: #00008f;
  border-color: #00008f;
}
.duplicate-name {
  color: red;
}
</style>
