<template>
  <UiTable
    :data="teams"
    :default-sort="{ prop: 'updated_at', order: 'descending' }"
  >
    <el-table-column
      :label="$t('user.tenant')"
      prop="label"
      sortable
      :sort-method="sortByName"
    >
      <template slot-scope="scope">{{ scope.row.label }}</template>
    </el-table-column>
    <el-table-column
      :label="$t('team.members')"
      prop="label"
      sortable
      :min-width="30"
    >
      <template slot-scope="scope">{{ scope.row.userCount }}</template>
    </el-table-column>
    <el-table-column
      :label="$t('common.last_modified')"
      prop="updated_at"
      sortable
      class-name="ui_team_modified"
      :min-width="30"
    >
      <template slot-scope="{ row }">
        <UiFormattedDate :date="row.updated_at" />
      </template>
    </el-table-column>
    <el-table-column :min-width="10">
      <template slot-scope="{ row }">
        <UiThreeDotsDropDown
          :items="dropdownItems(row.id)"
          @command="(command) => handleDropdownCommand(command, row)"
        />
      </template>
    </el-table-column>
  </UiTable>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MessageBox } from "element-ui";

export default {
  name: "TenantTable",
  props: {
    teams: {
      type: Array,
      required: true,
    },
    fetchTenants: {
      type: Function,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["isSuperAdmin", "isOwner"]),
    ...mapGetters("teams", ["isDefaultTeam"]),
  },
  methods: {
    ...mapActions("teams", ["deleteTenant"]),
    handleDropdownCommand(command, team) {
      switch (command) {
        case "delete":
          this.confirmDeleteTenant(team);
          break;
        case "edit":
          this.editTenant(team);
          break;
        default:
          throw new Error(`Unknown command ${command}`);
      }
    },
    dropdownItems(teamId) {
      const items = [
        {
          command: "edit",
          label: this.$t("action.edit"),
          class: "ui_menu_edit",
          icon: "icon-pencil-1",
        },
      ];

      if (
        (this.isOwner(teamId) || this.isSuperAdmin()) &&
        !this.isDefaultTeam(teamId)
      ) {
        items.push({
          command: "delete",
          label: this.$t("action.delete"),
          class: "ui_menu_delete",
          icon: "far fa-trash-alt",
          variant: "danger",
        });
      }
      return items;
    },
    sortByName(a, b) {
      return a.label.localeCompare(b.label);
    },
    async confirmDeleteTenant(tenant) {
      try {
        await MessageBox.confirm(
          this.$t("team.msg-confirm-delete-tenant", {
            label: tenant.label,
          }),
          this.$t("definition-lists.msg-confirm-deletion")
        );
        try {
          await this.deleteTenant({ id: tenant.id });
          await this.fetchTenants();
        } catch (err) {
          let message = this.$t("team.delete_error");
          if (err.response && err.response.data) {
            message = err.response.data.error;
          }
          MessageBox.alert(message, this.$t("team.delete_failed"));
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    editTenant(team) {
      this.onEdit(team);
    },
  },
};
</script>

<style scoped>
.el-table tr {
  background: red;
}
</style>
