<template>
  <div>
    <UiLoadingArea :is-loading="submitting" :message="$t('product.loading')">
      <UiTable
        ref="definitionListsTable"
        row-key="name"
        :data="definitionsLists"
        :default-sort="{ prop: 'fieldname', order: 'descending' }"
        @selectionChange="handleSelectionChange"
      >
        >
        <el-table-column
          v-if="allowImport"
          type="selection"
          reserve-selection
          width="55"
        >
        </el-table-column>
        <el-table-column label="List name">
          <template #default="{ row }">
            <span>{{ row.metadata.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Primary key" width="240">
          <template #default="{ row }">
            <span>{{ primaryKey(row) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Count" width="130">
          <template #default="{ row }">
            <span>{{ row.content.length }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Import status" width="130">
          <template #default="{ row }">
            <span>{{ getStatus(row) }}</span>
          </template>
        </el-table-column>
      </UiTable>
      <div v-if="force">
        <span class="label-warning">
          Warning: Force import action requested by user bypasses the validation
          process of primary key(s) and must be used with caution. This may
          affect existing definitions and product(s) state stability.
        </span>
      </div>
      <UiButton
        data-test="button-import-definition-list"
        :disabled="!canImport"
        variant="secondary"
        icon="el-icon-plus"
        @click="importList"
        >{{
          disableImportAction
            ? 'Only "Pending" definition lists can be imported, select at least one of them.'
            : force
            ? "Force import"
            : $t("action.import")
        }}</UiButton
      >

      <div v-if="errors.length > 0">
        <span v-for="(error, index) in errors" :key="index" class="error">
          {{ error }}
        </span>
      </div>
    </UiLoadingArea>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { Message } from "element-ui";

const { groupBy } = require("lodash");

export default {
  name: "DefinitionListsOverview",
  components: {},
  props: {
    definitionListSuggestions: {
      type: Array,
      required: true,
    },
    definitionsLists: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      confirmImport: false,
      showConfirm: false,
      submitting: false,
      allowImport: true,
      disableImportAction: true,
      initialized: false,
      statusImport: {
        PENDING: { id: "1", keyword: "pending" },
        IMPORTED: { id: "2", keyword: "imported" },
        FAILED: { id: "3", keyword: "failed" },
        ALREADY_IMPORTED: { id: "4", keyword: "already_imported" },
        ALREADY_EXISTS: { id: "5", keyword: "already_exists" },
      },
      errors: [],
      force: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isSuperAdmin", "isOwner", "isAdmin"]),
    ...mapState("auth", ["tenant"]),
    canImport() {
      return !this.disableImportAction && !this.submitting && this.allowImport;
    },
    canForce() {
      return this.isSuperAdmin() || this.isOwner || this.isAdmin;
    },
  },
  watch: {
    definitionsLists() {
      if (this.definitionsLists.length > 0) {
        if (!this.initialized) {
          this.initializeDefinitionsLists();
        }
      } else if (this.$refs && this.$refs.definitionListsTable) {
        this.$refs.definitionListsTable.$refs.table.clearSelection();
        this.initialized = false;
        this.allowImport = true;
      }
    },
  },
  mounted() {
    if (!this.initialized) {
      this.initializeDefinitionsLists();
    }
  },
  methods: {
    initializeDefinitionsLists() {
      this.definitionsLists.map((l) => {
        this.$set(l, "selected", false);
        this.$set(
          l,
          "importStatus",
          this.alreadyExists(l.metadata.name)
            ? this.statusImport.ALREADY_EXISTS.id
            : this.statusImport.PENDING.id
        );
        return l;
      });
      this.initialized = true;
    },
    ...mapActions("definitionListSuggestions", { fetchDefinitions: "fetch" }),
    ...mapActions("definitions", ["importDefinitonsList"]),
    primaryKey(row) {
      return Object.keys(groupBy(row.content || [], "primary_key_column")).join(
        " "
      );
    },
    handleSelectionChange(selection) {
      this.definitionsLists.forEach((dl) => {
        Object.assign(dl, { selected: false });
      });
      selection.forEach((s) => {
        const d = this.definitionsLists.find(
          (dl) =>
            dl.metadata.name === s.metadata.name &&
            !this.alreadyExists(dl.metadata.name)
        );
        if (d) d.selected = true;
      });

      this.disableImportAction =
        Object.keys(
          groupBy(
            this.definitionsLists.filter(
              (dl) => !!dl.selected && !this.alreadyExists(dl.metadata.name)
            ),
            "selected"
          )
        ).length === 0;
    },
    async importList() {
      this.errors = [];
      this.submitting = true;
      let nbImport = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const deflist of this.definitionsLists) {
        if (
          deflist.selected &&
          deflist.importStatus === this.statusImport.IMPORTED.id
        ) {
          Object.assign(deflist, {
            importStatus: this.statusImport.ALREADY_IMPORTED.id,
          });
        }

        if (
          deflist.selected &&
          (deflist.importStatus === this.statusImport.PENDING.id ||
            deflist.importStatus === this.statusImport.FAILED.id)
        ) {
          try {
            // send json product to backend
            const deflistToBackend = {
              force: this.force,
              ...deflist,
              ...{
                name:
                  (deflist.metadata && deflist.metadata.name) ||
                  deflist.filename,
                definitions: deflist.content,
              },
            };

            const blob = new Blob([JSON.stringify(deflistToBackend, null, 2)], {
              type: "application/json",
            });

            // eslint-disable-next-line no-await-in-loop
            const response = await this.importDefinitonsList({
              data: blob,
              sync: false,
            });

            Object.assign(deflist, {
              importStatus: this.statusImport.IMPORTED.id,
            });
            nbImport += 1;
            this.$emit("callback", response);
          } catch (err) {
            if (
              err.message &&
              err.message.indexOf("Duplicate primary keys") !== -1 &&
              this.canForce &&
              this.definitionsLists.length === 1 // TODO: Force import for multiple deflists
            ) {
              this.force = true;
            }
            Object.assign(deflist, {
              importStatus: this.statusImport.FAILED.id,
            });
            this.errors.push(err.message || "An error occurred during import.");
            Message.error(err.message || "An error occurred during import.");
            this.resetImportUi();
          }
        }
      }
      this.submitting = false;

      const statuses = Object.keys(
        groupBy(this.definitionsLists, "importStatus")
      );
      if (
        statuses.indexOf(this.statusImport.PENDING.id) !== -1 &&
        statuses.indexOf(this.statusImport.FAILED.id) !== -1
      ) {
        this.allowImport = false;
      }

      if (nbImport > 0) {
        Message.success(`${nbImport} Import process ended successfully`);
        this.errors = [];
        this.fetchDefinitions();
      }
    },
    alreadyExists(name) {
      return this.definitionListSuggestions.some((dl) => dl.label === name);
    },
    getStatus(row) {
      const codeStatus =
        (
          Object.values(this.statusImport).find(
            (v) => v.id === row.importStatus
          ) || {}
        ).keyword || "#error!!";

      return this.$t(
        `product.import-definitions-list.wizard.status.${codeStatus}`
      );
    },
    resetImportUi() {
      this.submitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.error,
.label-warning {
  display: block;
  color: red;
}
</style>
