<template>
  <el-dialog
    id="editTermModal"
    visible
    :before-close="close"
    :class="{
      full: hasDefinitionLinked,
      stretched: !hasDefinitionLinked,
    }"
  >
    <div
      slot="title"
      class="d-flex align-items-center justify-content-between pr-5"
    >
      <div class="modal-title">{{ title }}</div>
      <div v-if="term">
        <el-button
          v-if="!isEditing"
          :disabled="readonly"
          size="mini"
          icon="el-icon-edit"
          class="ui_button_edit"
          @click="edit"
          >Edit</el-button
        >
        <el-tooltip
          :disabled="canDelete"
          class="item"
          effect="dark"
          :content="$t(`product.terms.used_monoterm`)"
          placement="top-start"
        >
          <div class="float-right pl-2">
            <el-button
              :disabled="readonly || !canDelete"
              size="mini"
              type="danger"
              plain
              icon="el-icon-delete"
              class="ui_button_delete"
              @click="deleteTerm"
              >Delete</el-button
            >
          </div>
        </el-tooltip>
      </div>
    </div>

    <div v-if="isEditing || !term" class="row">
      <div class="col-6">
        <label class="d-flex align-items-center">
          <span class="pr-3">Term</span>
          <el-input
            v-model="termName"
            :placeholder="$t('product.terms.edit_name')"
            class="ui_input_term_name"
            maxlength="255"
            show-word-limit
          />
        </label>
      </div>

      <div class="source-definition-list col-6">
        <span v-if="hasDefinitionLinked">
          {{ $t("product.terms.source_def_list") }}:
          <router-link
            :to="{
              name: 'definition-lists-edit',
              params: {
                definitionListId: selectedDefinitionListId,
              },
            }"
            target="_blank"
            class="ui_link_to_list"
          >
            {{ getDefinitionListName(selectedDefinitionListId) }}
            <i class="fas fa-external-link-alt"></i>
          </router-link>
        </span>
      </div>
    </div>

    <div v-if="hasDefinitionLinked">
      <UiSearchBox
        v-model="search"
        :placeholder="$t('product.terms.edit_search')"
      />
      <el-tabs ref="tabs">
        <el-tab-pane label="Table View" class="ui_tab_table_view">
          <div class="definitions">
            <span
              v-for="(
                definition, definitionIndex
              ) in definitionsForSelectedList"
              :key="'definition -' + definitionIndex"
              class="definition py-2 px-3 mr-1 mb-2 ui_definition_name"
              :class="{
                selectable: !term || isEditing,
                selected: isSelected(definition),
                hide: !isSelected(definition),
              }"
            >
              {{ definition.displayName || definition.primaryKey }}
            </span>
          </div>
          <div
            v-if="
              (!term || isEditing) &&
              selectedAndAvailableFilteredDefinitions.length &&
              hasDefinitionLinked
            "
            class="py-3"
          >
            <el-button
              size="mini"
              type="text"
              class="ui_button_invert_sel"
              @click="selectAll"
              >Select all</el-button
            >
          </div>
          <el-table
            v-if="loaded"
            ref="table"
            row-key="_id"
            border
            resizable
            :class="{ 'is-editing': isEditing || !term }"
            height="500"
            :data="paginatedDefinitions"
            stripe
            :default-sort="{
              prop: 'columns.' + columnsForSelectedList[0],
              order: 'ascending',
            }"
            @sort-change="onSort"
            @row-click="handleRowClick"
            @selection-change="handleSelectedDefinitions"
          >
            <el-table-column
              v-if="!term || isEditing"
              type="selection"
              width="55"
              reserve-selection
              :selectable="definitionSelectable"
            />

            <el-table-column width="60">
              <template slot-scope="{ row }">
                <el-tooltip v-if="getValidationErrors(row).length">
                  <i class="el-icon-warning-outline"></i>
                  <template slot="content">
                    <div
                      v-for="(error, key) of getValidationErrors(row)"
                      :key="key"
                    >
                      {{
                        $t(`product.validation.${error.code}`, {
                          definition: row.primaryKey,
                        })
                      }}
                    </div>
                  </template>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              v-for="column in columnsForSelectedList"
              :key="column"
              :prop="'columns.' + column"
              :label="column"
              :sortable="true"
            />
            <el-table-column
              v-if="!columnsForSelectedList.length"
              label="Name"
            />
            <el-table-column label="Source">
              <template slot-scope="{ row }">
                <router-link
                  :to="{
                    name: 'definition-lists-edit',
                    params: {
                      definitionListId: row.definitionList
                        ? row.definitionList.id
                        : definitionListId,
                    },
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="ui_link_to_list"
                >
                  {{ row.definitionList ? row.definitionList.name : "" }}
                  <i class="fas fa-external-link-alt"></i>
                </router-link>
              </template>
            </el-table-column>
          </el-table>
          <Loading v-if="!loaded" />
          <UiPagination
            v-if="selectedAndAvailableFilteredDefinitions.length"
            :current-page.sync="page"
            :page-size="count"
            :total="selectedAndAvailableFilteredDefinitions.length"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="(term && term.defaultColumnName) || 'Name'"
          class="ui_tab_name"
        >
          <p
            v-if="!definitionListId && !term"
            class="text-muted p-5 text-center"
          >
            Select list to view available definitions.
          </p>
          <div class="definitions">
            <span
              v-for="(
                definition, definitionIndex
              ) in selectedAndAvailableFilteredDefinitions"
              :key="'definition -' + definitionIndex"
              class="definition py-2 px-3 mr-1 mb-2 ui_definition_name"
              :class="{
                selectable: !term || isEditing,
                selected: isSelected(definition),
              }"
              @click="toggleDefinition(definition)"
            >
              {{ definition.displayName }}
              <el-button
                v-if="!term || isEditing"
                :icon="
                  isSelected(definition) ? 'el-icon-close' : 'el-icon-plus'
                "
                type="text"
                size="mini"
                :class="
                  isSelected(definition) ? 'ui_button_close' : 'ui_button_add'
                "
              />
            </span>
          </div>
        </el-tab-pane>

        <el-tab-pane
          v-if="isEditing"
          label="Text import [BETA]"
          class="ui_tab_text_import"
        >
          <div class="definitions_from_text">
            <textarea
              v-model="definitionListText"
              class="ui_textarea_text_import_entity"
            ></textarea>
            <el-button
              size="small"
              type="primary"
              class="ui_button_import_from_text"
              @click="importEntitiesFromText"
            >
              Import element from text
            </el-button>
            <pre class="debug_text">
            <span
              v-for="(letter, idx) in definitionListTextDebug"
              :key="idx"
              class="debug-letter"
              :class=" {
                          'selected-last': letter.lastSelected,
                          selected: letter.selectedLetter,
                          'not-selected': !letter.selectedLetter
                        }"
            >
            {{letter.letter}}
            </span>
          </pre>
          </div>
          <p
            v-if="!definitionListId && !term"
            class="text-muted p-5 text-center"
          >
            Select list to view available definitions.
          </p>
          <div class="definitions">
            <span
              v-for="(
                definition, idx
              ) in selectedAndAvailableFilteredDefinitions"
              :key="idx"
              class="definition py-2 px-3 mr-1 mb-2 ui_definition_name"
              :class="{
                selectable: !term || isEditing,
                selected: isSelected(definition),
              }"
              @click="toggleDefinition(definition)"
            >
              {{ definition.displayName }}
              <el-button
                v-if="!term || isEditing"
                :icon="
                  isSelected(definition) ? 'el-icon-close' : 'el-icon-plus'
                "
                type="text"
                size="mini"
                :class="
                  isSelected(definition) ? 'ui_button_close' : 'ui_button_add'
                "
              />
            </span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div
      v-if="
        (!term || isEditing) &&
        selectedAndAvailableFilteredDefinitions.length &&
        hasDefinitionLinked
      "
      class="py-3"
    >
      <el-button
        size="mini"
        type="text"
        class="ui_button_invert_sel"
        @click="toggleAllSelection"
        >Invert selection</el-button
      >
    </div>

    <div slot="footer">
      <el-button
        v-if="!term || isEditing"
        type="primary"
        size="small"
        :disabled="!canSaveTerm"
        class="ui_button_save"
        @click="saveTerm"
        >{{ term ? "Update" : "Create" }}</el-button
      >
      <el-button size="small" @click="$emit('close')">Cancel</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { MessageBox, Message } from "element-ui";
import { uniqBy, uniq, find } from "lodash";
import { mapState, mapActions } from "vuex";
import * as api from "../api";
import TermMixin from "./TermMixin";
import Loading from "./Loading.vue";

export default {
  name: "EditTermModal",
  components: {
    Loading,
  },
  mixins: [TermMixin],
  props: {
    currentTerm: {
      type: Object,
      required: true,
    },
    property: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    termId: {
      type: Number,
      default: null,
    },
    duplicateDefinitions: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: undefined,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    selectedDefinitionListId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
      paginatedDefinitions: [],
      sortField: null,
      sortOrder: "ascending",
      count: 20,
      term: null,
      termName: this.name || "",
      previousTermName: this.name || "",
      definitionLists: [],
      definitionListId: null,
      definitionsForSelectedList: [],

      selectedDefinitions: [],
      isEditing: this.editMode && !this.readonly,

      definitionListText: "",
      definitionListTextDebug: [],

      search: "",
      searchFilter: (item) => {
        if (!item || !item.columns) {
          return undefined;
        }
        return Object.values(item.columns).some((value) => {
          return value
            .toString()
            .toLowerCase()
            .includes(this.search.toLowerCase());
        });
      },

      loaded: false,
    };
  },
  computed: {
    ...mapState("product", ["sharedProperties"]),
    ...mapState("auth", ["tenant"]),
    ...mapState("sharedProperty", ["productValidations"]),
    hasDefinitionLinked() {
      return this.selectedDefinitionListId > 0;
    },
    termDefinitions() {
      return (this.term && this.term.definitions) || [];
    },
    selectedAndAvailableDefinitions() {
      return uniqBy(
        this.termDefinitions.concat(this.definitionsForSelectedList),
        "_id"
      );
    },
    selectedAndAvailableFilteredDefinitions() {
      return this.selectedAndAvailableDefinitions.filter(this.searchFilter);
    },
    title() {
      if (this.term) {
        return this.isEditing
          ? `Edit ${this.term.name}`
          : `Term ${this.term.name}`;
      }

      const dimSpec =
        this.product.specification.dimensions[this.property] ||
        this.product.specification.out[this.property];
      const name = (dimSpec && dimSpec.displayName) || this.property;
      return `Create Term for ${name || this.property}`;
    },
    filteredDefinitions() {
      return this.termDefinitions.filter(this.searchFilter);
    },
    canSaveTerm() {
      return !this.readonly && this.termName.trim() !== "";
    },
    columnsForSelectedList() {
      if (
        this.definitionsForSelectedList &&
        this.definitionsForSelectedList.length
      ) {
        return uniq(
          this.definitionsForSelectedList.reduce((columns, item) => {
            return columns.concat(Object.keys(item.columns));
          }, [])
        );
      }

      return uniq(
        this.filteredDefinitions.reduce((columns, item) => {
          return columns.concat(Object.keys(item.columns));
        }, [])
      );
    },
    isAscending() {
      return this.sortOrder === "ascending";
    },
    canDelete() {
      return this.term.canDelete;
    },
  },
  watch: {
    async definitionListId() {
      this.loaded = false;
      this.definitionsForSelectedList =
        await this.fetchDefinitionsForSelectedList();
      this.definitionLists = await this.fetchDefinitionLists();
      this.search = "";
      this.updatePaginatedDefinitions();
      this.loaded = true;
    },
    definitionsForSelectedList() {
      this.selectTermDefinitions();
    },
    term() {
      this.selectedDefinitions = this.term.definitions;
    },
    search: {
      handler() {
        this.updatePaginatedDefinitions();
      },
    },
    page: {
      handler() {
        this.updatePaginatedDefinitions();
      },
    },
  },
  async mounted() {
    this.page = 1;
    this.definitionListId = this.selectedDefinitionListId;
    if (this.termId) {
      // this.term = await this.fetchTerm(this.termId);
      this.term = this.currentTerm;
      this.termName = this.term.name;
      this.previousTermName = this.term.name;
    }
  },
  methods: {
    ...mapActions("product", ["refreshProduct"]),
    onSort(props) {
      const { order } = props;
      const field = props.prop.split(".")[1];

      if (order && field) {
        if (order !== this.sortOrder || field !== this.sortField) {
          this.sortField = field;
          this.sortOrder = order;

          this.updatePaginatedDefinitions(field, order);
        }
      }
    },
    updatePaginatedDefinitions() {
      this.paginatedDefinitions = this.selectedAndAvailableDefinitions
        .filter(this.searchFilter)
        .sort((a, b) =>
          this.sortColumn(
            this.sortField || this.columnsForSelectedList[0],
            this.isAscending,
            a,
            b
          )
        )
        .splice((this.page - 1) * this.count, this.count);

      this.scrollTableToTop();
    },
    scrollTableToTop() {
      if (this.$refs.table) {
        this.$refs.table.bodyWrapper.scrollTop = 0;
      }
    },
    sortColumn(column, isAscending, a, b) {
      const valueA = a.columns[column];
      const valueB = b.columns[column];
      if (!valueB || valueB.length === 0) {
        return isAscending ? -1 : 1;
      }
      if (!valueA || valueA.length === 0) {
        return isAscending ? 1 : -1;
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return isAscending
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return valueA - valueB;
    },
    getDefinitionListName(definitionListId) {
      const definitionListFound = find(this.definitionLists, (o) => {
        return o._id === definitionListId;
      });
      return definitionListFound && definitionListFound.name
        ? definitionListFound.name
        : this.property;
    },
    getValidationErrors(definition) {
      if (!this.productValidations[this.product.id]) return [];
      return this.productValidations[this.product.id].errors.filter(
        (e) =>
          e.definition === definition.primaryKey && e.property === this.property
      );
    },
    setDefaultColumnName(defaultColumnName) {
      this.term.defaultColumnName = defaultColumnName;
    },
    async fetchTerm(termId) {
      const { data } = await api.getTerm(termId, this.tenant);
      return data;
    },
    async selectTermDefinitions() {
      if (!this.term) {
        return;
      }
      await this.$nextTick();
      const { definitions } = this.term;
      definitions.forEach((definition) => {
        const { table } = this.$refs;
        if (table) {
          table.toggleRowSelection(definition, true);
        }
      });
    },
    async fetchDefinitionLists() {
      const data = await api.getDefinitionLists({
        tenant: this.tenant,
      });
      return data.items || [];
    },
    async fetchDefinitionsForSelectedList() {
      if (this.definitionListId) {
        const { data } = await api.getDefinitionList(this.definitionListId, {
          tenant: this.tenant,
        });
        return data.items || [];
      }
      return [];
    },
    async saveTerm() {
      try {
        const data = {
          name: this.termName,
          previousTermName: this.previousTermName,
          dimension: this.property,
          definitions: this.selectedDefinitions || [],
        };

        let { termId } = this;
        if (this.product.terms[this.property]) {
          const existingTerms = this.product.terms[this.property].filter(
            (i) => i.name === this.termName
          );
          if (existingTerms.length > 0 && !this.termId) {
            termId = existingTerms[0]._id;
            data.definitions = data.definitions.concat(
              existingTerms[0].definitions
            );
          }
        }

        if (termId) {
          await api.updateTerm(this.product.id, termId, data, this.tenant);
          Message.success("Successfully updated term");
        } else {
          await api.createTerm(this.product.id, data, this.tenant);
          Message.success("Successfully created new term");
        }
        this.termName = null;
        this.definitions = [];
        this.refreshProduct({
          productId: this.product.id,
        });
        this.$emit("update");
        this.close();
      } catch (err) {
        Message.error(err);
      }
    },
    importEntitiesFromText() {
      const wordPosition = [];
      this.definitionListText = this.definitionListText.trim();
      this.selectedAndAvailableFilteredDefinitions.forEach((definition) => {
        const { displayName } = definition;
        if (displayName) {
          const index = this.definitionListText
            .toLowerCase()
            .search(displayName.toLowerCase());
          if (index !== -1) {
            wordPosition.push([index, displayName.length]);
            const { table } = this.$refs;
            if (table) {
              table.toggleRowSelection(definition, true);
            }
          }
        }
      });

      // creating debug text
      this.definitionListTextDebug = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.definitionListText.length; i += 1) {
        let isLeterSelected = false;
        let lastSelected = false;
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < wordPosition.length; ++j) {
          if (
            i >= wordPosition[j][0] &&
            i < wordPosition[j][0] + wordPosition[j][1]
          ) {
            isLeterSelected = true;
          }
          if (i === wordPosition[j][0] + wordPosition[j][1] - 1) {
            lastSelected = true;
          }
        }
        const letter = this.definitionListText[i];

        this.definitionListTextDebug.push({
          letter,
          selectedLetter: isLeterSelected,
          lastSelected,
        });
      }
    },
    toggleDefinition(definition) {
      if (this.termId && !this.isEditing) {
        return;
      }
      const { table } = this.$refs;
      if (table) {
        table.toggleRowSelection(definition, !this.isSelected(definition));
      }
    },
    toggleAllSelection() {
      this.selectedAndAvailableFilteredDefinitions.forEach((definition) => {
        const { table } = this.$refs;
        if (table) {
          table.toggleRowSelection(definition);
        }
      });
    },
    selectAll() {
      this.selectedAndAvailableFilteredDefinitions.forEach((definition) => {
        const { table } = this.$refs;
        if (table) {
          table.toggleRowSelection(definition, true);
        }
      });
    },
    handleRowClick(row) {
      if (!this.definitionSelectable(row)) {
        return;
      }
      if (this.isEditing && !row.termInUse) {
        this.toggleDefinition(row);
      }
    },
    handleSelectedDefinitions(definitions) {
      this.selectedDefinitions = definitions;
    },
    async deleteTerm() {
      try {
        await MessageBox.confirm(
          "Are you sure you want to delete this term?",
          "Confirm Deletion"
        );
        await api.unlinkProductTerm(this.product.id, this.term._id);
        Message.info("Successfully deleted term");
        this.$emit("update");
      } catch (err) {
        this.$message.error(err);
      }
      this.close();
    },
    edit() {
      this.isEditing = true;
      this.search = "";
      const firstDefinitionWithListId = this.filteredDefinitions.find(
        (d) => d.definitionList.id
      );
      if (firstDefinitionWithListId) {
        this.definitionListId = firstDefinitionWithListId.definitionList.id;
      }
      this.selectTermDefinitions();
    },
    close() {
      this.$emit("close");
      this.isEditing = false;
    },
    isSelected(definition) {
      return this.selectedDefinitions.some((d) => d._id === definition._id);
    },
    // eslint-disable-next-line no-unused-vars
    definitionSelectable(def) {
      if (!this.term) {
        return true;
      }
      const definition = (this.term.definitions || []).find(
        (df) => df.primaryKey === def.primaryKey
      );
      if (!definition) {
        return true;
      }
      return definition.canDelete;
    },
  },
};
</script>

<style lang="scss" scoped>
#editTermModal {
  .el-icon-warning-outline {
    font-weight: bold;
    font-size: 20px;
  }
  .definitions {
    max-height: 500px;
    overflow: auto;
  }
  .definition {
    background: #ededf4;
    display: inline-block;
    border-radius: 30px;
  }
  .definition.selectable {
    cursor: pointer;
  }
  .definition.selected {
    background: #8571f9;
    color: white;
  }
  .definition.selected button {
    color: white;
  }
  .definition.hide {
    display: none;
  }
  button:focus {
    outline: 0;
  }

  ::v-deep div[role="dialog"] {
    .el-table .caret-wrapper {
      width: auto;
    }

    .is-editing .el-table__row {
      cursor: pointer;
    }

    .ui_textarea_text_import_entity {
      width: 100%;
      padding: 8px;
      border-radius: 2px;
      border: 1px solid #aaa;
    }

    .definitions_from_text {
      padding-bottom: 16px;
    }

    .modal-title {
      max-width: 70%;
    }

    .debug-letter {
      display: inline-block;
      color: #000;

      margin-top: 2px;
      font-size: 14px;
    }
    .debug-letter.selected {
      background-color: #eef;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .debug-letter.selected + .debug-letter.selected {
      padding-left: 0px;
    }

    .debug-letter.not-selected + .debug-letter.selected {
      padding-left: 6px;
      border-radius: 4px 0px 0px 4px;
    }
    .selected-last {
      background-color: #eef;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 6px;
      border-radius: 0px 4px 4px 0px;
    }

    .debug_text {
      background-color: #fff;
      padding: 16px;
      border-left: 2px solid #444;
      margin: 8px 0px;
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    span {
      white-space: nowrap;
    }

    .source-definition-list {
      text-align: right;
    }

    .ui_input_term_name input[type="text"] {
      padding-right: 50px;
    }
  }

  &.full {
    ::v-deep div[role="dialog"] {
      width: 95%;
      margin-top: 5vh !important;
    }
  }

  &.streched {
    ::v-deep div[role="dialog"] {
      width: 45%;
    }
  }
}
</style>
