<template>
  <el-table-column v-bind="$attrs" prop="name" :sortable="sortable" :label="label">
    <template #default="{ row, $index }">
      <UiColorPicker
        v-if="hasColor"
        :toggle-editing="isEditing($index)"
        :value="row.color"
        :palette="colors"
        :handle-change="onColorChange"
        :data-cy="`color-picker-${row.name}`"
        data-test="property-color-picker"
      />
      <slot name="label" :row="row" :$index="$index">
        <UiInlineEditable
          v-if="!displayNameOnly(row)"
          :value="currentName"
          variant="bold"
          :data-cy="`property-technical-name-${row.name}`"
          cy-test="property-technical-name"
          :is-editable="isEditable(row, $index)"
          :toggle-editing="isEditing($index)"
          :handle-change="onChange"
          @save="saveItem($index)"
          @edit-row="editRow($index)"
        >
          <template #label>
            <UiLabel
              variant="bold"
              :label="row.name"
              :description="displayName ? displayName(row) : null"
            />
            <slot name="icons" :row="row" :$index="$index" />
          </template>
        </UiInlineEditable>
        <div v-else class="DisplayName">
          <UiLabel style="flex: 1" variant="bold" :label="displayName(row)" />
          <slot name="icons" :row="row" :$index="$index" />
        </div>
        <slot name="tooltip" v-bind="{ row, $index }"></slot>
      </slot>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: "TableColumnName",
  props: {
    product: {
      type: Object,
      required: true,
    },
    hasColor: {
      type: Boolean,
      required: true,
    },
    isEditable: {
      type: Function,
      required: true,
    },
    isEditing: {
      type: Function,
      required: true,
    },
    saveItem: {
      type: Function,
      required: true,
    },
    editRow: {
      type: Function,
      required: true,
    },
    displayName: {
      type: Function,
      required: false,
      default: undefined,
    },
    displayNameOnly: {
      type: Function,
      required: false,
      default: () => false,
    },
    label: {
      type: String,
      required: true,
    },
    sortable: {
      type: [String, undefined],
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: undefined,
    },
    colors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentName: undefined,
      currentColor: undefined,
    };
  },
  watch: {
    name(value) {
      this.currentName = value;
    },
    color(value) {
      this.currentColor = value;
    },
  },
  mounted() {
    this.currentName = this.name;
    this.currentColor = this.color;
  },
  methods: {
    onColorChange(value) {
      this.currentColor = value;
      this.$emit("color-change", this.currentColor);
    },
    onChange(value) {
      this.currentName = value;
      this.$emit("change", this.currentName);
    },
  },
};
</script>

<style lang="scss" scoped>
.DisplayName {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
