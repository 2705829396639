/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import axios from 'axios';
import { productTypes } from './const/product';
import feathersClient from './feathers';
import { configureClient } from './api/helpers';
import { computationV3Payload, formatCoverageCheck } from './util';

const Cookies = require('js-cookie');

// Needed for the unit tests where wondow.__env__ is not defined
const apiUrl = window && window.__env__ ? window.__env__.VUE_APP_API_URL : '';
const computationAPIUrl =
  window && window.__env__
    ? // only required for local development as the api runs on port 5000.
      window.__env__.VUE_APP_COMPUTATION_API_URL ||
      window.__env__.VUE_APP_API_URL
    : '';

export const settings = { baseURL: `${apiUrl}/api/editor` };
export const settingsApi = { baseURL: `${apiUrl}/api` };
export const settingsV2 = { baseURL: `${apiUrl}/api/2.0` };
export const computationAPI = {
  baseURL: `${computationAPIUrl}/v3/tenants`
};

const getUrlWithTenant = (tenant, baseUrl) => {
  return `${apiUrl}/tenants/${tenant}${baseUrl.replace(apiUrl, '')}`;
};

export const getProductExportUrl = (id, options) => {
  const definitionsLists = (options || {}).definitionsLists || false;
  const questionnaires = (options || {}).questionnaires || false;
  return `${settings.baseURL}/dump/${id}?df=${definitionsLists}&qst=${questionnaires}`;
};

export const getCSPExportUrl = (team) => {
  return `${settings.baseURL}/export-csp?team=${team}`;
};

export const getExportUrlByType = (id, type) =>
  `${settings.baseURL}/export/${id}?type=${type}`;

export const client = axios.create(settings);
export const clientApi = axios.create(settingsApi);
export const clientV2 = axios.create(settingsV2);
export const computationAPIClient = axios.create(computationAPI);

configureClient(client, settings);
configureClient(clientApi, settingsApi);
configureClient(clientV2, settingsV2);
configureClient(computationAPIClient, computationAPI);
// Used for backend call
const { get, post, put, patch } = client;

// Used for api call shared outside.
const apiPost = clientApi.post;
const apiGet = clientApi.get;
const apiPostV2 = clientV2.post;
const apiGetV2 = clientV2.get;
const computationAPIPost = computationAPIClient.post;

export const getCancelToken = () => axios.CancelToken.source();

/**
 * @returns {Promise}
 */
export const logOut = () => post('/auth/logout');

/**
 * @returns {Promise}
 */
export const listTemplates = ({
  bline,
  search,
  page,
  count,
  field,
  order,
  source,
  cancelToken,
  tenant
}) => {
  let url = `/templates?search=${search || ''}&page=${page || ''}&count=${
    count || ''
  }&field=${field || ''}&order=${order || ''}&bline=${bline || ''}`;
  if (source) url += `&source=${source}`;
  const getSettings = { cancelToken };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(url, getSettings);
};

/**
 * @returns {Promise}
 */
export const listTeams = () => get('/teams');

export const findProductsByName = (name) => get(`/product/search/${name}`);

/**
 * @param name
 * @returns {Promise}
 */
export const createProduct = (product, tenant, templateId = false) => {
  const postSettings = {};
  if (tenant) {
    postSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return post('/product', { templateId, ...product }, postSettings);
};

/**
 * Given an id get a product, clearing cache
 * on every request as performance is not
 * paramount for the edition UI.
 * @param id
 * @returns {Promise}
 */
export const getProduct = (id, tenant) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(`/product/${id}?clearCache=true`, getSettings);
};

const serveAttachment = ({ data, headers }, customFilename = null) => {
  const regex = /filename="(.*)"/g;
  const corresp = regex.exec(headers['content-disposition']);
  const filename = corresp && corresp[1] ? corresp[1] : customFilename;

  const url = window.URL.createObjectURL(
    new Blob(
      customFilename && customFilename.endsWith('.json')
        ? [JSON.stringify(data, null, 2)]
        : [data]
    )
  );
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportSharedProperties = async (team) => {
  const response = await get(getCSPExportUrl(team), { responseType: 'blob' });
  serveAttachment(response);
};
/**
 * @param id
 * @returns {Json}
 */
export const exportProduct = async (id, exportSelection) => {
  const response = await get(getProductExportUrl(id, exportSelection), {
    responseType: 'blob'
  });
  serveAttachment(response);
};

/**
 * @param id
 * @param type
 * @returns {Json}
 */
export const exportTests = async (id, type) => {
  const response = await get(getExportUrlByType(id, type), {
    responseType: 'blob'
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  const regex = /filename="(.*)"/g;
  const corresp = regex.exec(response.headers['content-disposition']);
  const filename = corresp[1] ? corresp[1] : 'exportTests.json';
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * @TODO give a full product object to this method and not individual values.
 * @TODO be consistant with naming conventions
 * @param id
 * @param name
 * @param specification
 * @returns {Promise}
 */
export const updateProduct = (
  id,
  {
    name,
    specification,
    type = productTypes.PRODUCT,
    subtype,
    template_type_id = null,
    technicalName = null,
    meta_data = null,
    questionnaires = null,
    main_rule_id = null,
    default_language = null,
    tenant
  }
) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(
    `/product/${id}`,
    {
      id,
      name,
      specification,
      type,
      subtype,
      template_type_id,
      technicalName,
      meta_data,
      questionnaires,
      main_rule_id,
      default_language
    },
    putSettings
  );
};

/**
 * @param id
 * @param property  the property ('dimension', 'in', 'out')
 * @param old_key   the current value of the property's key
 * @param new_key   the new value of the property's key
 * @returns {Promise}
 */
export const updateProductProperty = (id, property, oldKey, newKey, tenant) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(
    `/product/${id}/property`,
    {
      id,
      property,
      oldKey,
      newKey
    },
    putSettings
  );
};

export const updateProductTechnicalName = (id, name, technicalName, tenant) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(`/product/${id}`, { id, name, technicalName }, putSettings);
};

export const updateProductTenant = async (productId, tenant, newTenant) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(
    `/product/${productId}/team-relationship`,
    { tenant: newTenant },
    putSettings
  );
};

export const updateRule = (id, rule) => {
  post(`/rule/${id}`, {
    ...rule,
    ...{is_locked: rule.is_locked || false}
  })
};

export const ide = (id, tenant, withWarnings = true) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settingsV2.baseURL);
  }
  return apiGetV2(`ide/${id}?withWarnings=${withWarnings}`, getSettings);
};

export const complexity = (id, tenant) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settingsV2.baseURL);
  }
  return apiGetV2(`complexity/${id}`, getSettings);
};

export const renameRule = (rules, oldName, newName, dryRun) =>
  put('/rules', { rules, oldName, newName, dryRun });

export const updateRulesOrder = (productId, rules, tenant) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(`/product/${productId}/rules-order`, { rules }, putSettings);
};

/**
 * @param id
 * @returns {Promise}
 */
export const deleteProduct = (id, tenant) => {
  const deleteSettings = {};
  if (tenant) {
    deleteSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return client.delete(`/product/${id}`, deleteSettings);
};
/**
 * @param label
 * @returns {Promise}
 */
export const createTenant = (label) => post('/tenants', { label });

/**
 * @param id
 * @returns {Promise}
 */
export const deleteTenant = (id) => client.delete(`/tenants/${id}`);

/**
 * @param label
 * @returns {Promise}
 */
export const editTenant = (id, label) => put(`/tenants/${id}`, { label });

/**
 * @param file
 * @returns {Promise}
 */
export const importProduct = (file, type, tenant, productTeam) => {
  const data = new FormData();
  data.append('file', file);
  const postSettings = {};
  if (tenant) {
    postSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return post(
    `/dump?team=${productTeam}${type ? `&type=${type}` : ''}`,
    data,
    postSettings
  );
};

export const uploadProduct = (product, tenant, team = tenant) => {
  if (!product) return;
  const postSettings = {};
  if (tenant) {
    postSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return post('/upload', { product, team }, postSettings);
};

export const getPdfFile = (productId, fileName, tenant) => {
  const getSettings = {
    responseType: 'blob'
  };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(`/product/${productId}/documents/${fileName}`, getSettings);
};

export const getPdfUploadHeaders = () => {
  const AUTH_COOKIE = window.__env__.VUE_APP_AUTH_COOKIE;
  return {
    Authorization: Cookies.get(AUTH_COOKIE)
  };
};

export const deletePdfFile = (productId, fileName, tenant) => {
  const deleteSettings = {};
  if (tenant) {
    deleteSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return client.delete(
    `/product/${productId}/documents/${fileName}`,
    deleteSettings
  );
};

export const importByType = (id, type, file) => {
  const data = new FormData();
  data.append('file', file);
  return post(`/import/${id}?type=${type}`, data);
};

/**
 * @param productId
 * @param name
 * @returns {Promise}
 */
export const createRule = async (productId, name) => await post('/rule', { product_id: productId, name, is_locked: false });

/**
 * TODO: This should not be done with a GET
 * @param id
 * @returns {Promise}
 */
export const deleteRule = (id) => get(`/deleteRule/${id}`);

/**
 * @param apiName
 * @param productId
 * @param query
 * @param versionId
 * @returns {Promise}
 */
export const executeAPIQuery = (
  apiName,
  productId,
  query,
  versionId = null,
  expandWithTerms = null
) => {
  let payload = {};
  if (!productId && expandWithTerms === null) {
    payload = { ...query };
  } else {
    payload =
      expandWithTerms != null
        ? { productId, expandWithTerms, query }
        : { productId, query };
  }
  return versionId
    ? apiPost(`/${apiName}/versions/${versionId}`, payload)
    : apiPost(`/${apiName}`, payload);
};

/* eslint-disable prettier/prettier */
export const executeAPIQueryV2 = (
  apiName,
  productId,
  query,
  versionId = null
) => {
  return versionId
    ? apiPostV2(
        `products/${productId}/${apiName}/versions/${versionId}?token=${this.token}`,
        query
      )
    : apiPostV2(`products/${productId}/${apiName}?token=${this.token}`, query);
};
/* eslint-enable prettier/prettier */

// This could be used by other endpoints.
const { CancelToken } = axios;

/** Given a product ID get and runs tests.
 * @param productId
 * @param versionId
 * @param {Boolean} [counter] - returns only the counter
 * @param {Boolean} [cancel] - interrupts request
 * @returns {Promise}
 */
const testCancelers = [];
export const getTests = (
  productId = undefined,
  versionId = null,
  graph = false,
  cancel = false,
  tenant = undefined
) => {
  if (cancel) {
    testCancelers.forEach((f) => f('Test request cancelled'));
    // testCancelers = [];
  }

  const getSettings = {
    cancelToken: new CancelToken((cancellerCallback) => {
      testCancelers.push(cancellerCallback);
    })
  };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  /* eslint-disable prettier/prettier */
  return versionId
    ? get(
        `/tests/${productId}/versions/${versionId}${
          graph ? '?graph=true' : ''
        }`,
        getSettings
      )
    : get(`/tests/${productId}${graph ? '?graph=true' : ''}`, getSettings);
  /* eslint-enable prettier/prettier */
};

/**
 * @param productId
 * @param name a test name
 * @param definition a test definition
 * @returns {Promise}
 */
export const createTest = (productId, name, definition) =>
  post('/test', { productId, name, definition });

/**
 * @param testId
 * @param name a test name
 *  @param definition a test definition
 * @returns {Promise}
 */
export const updateTest = (testId, name, definition, productId) =>
  put(`/test/${testId}`, { name, definition, productId });

/**
 * @param id The test id to delete
 * @returns {Promise}
 */
export const deleteTest = (id) => get(`/deleteTest/${id}`);

/**
 * @param tests An array of test ids to delete
 * @returns {Promise}
 */
export const deleteTests = (tests) => {
  const postSettings = { tests };
  return post('/deleteTests', postSettings);
};
/* eslint-disable prettier/prettier */
export const getDefinitionLists = async ({
  search = '',
  page = '',
  count = '',
  field = '',
  order = '',
  cancelToken,
  tenant = null,
} = {}) => {
  const getSettings = { cancelToken };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }

  return feathersClient.service('2.0/definition-list').find({
    query: {
      tenant,
      search,
      page,
      count,
      field,
      order,
    },
  });
};

const batchRequest = async (definitionListId, batch) => {
  const getSettings = {};
  try {
    const response = await get(`/definition-lists/${definitionListId}/product-dependancies?ids=${batch.join(',')}`, getSettings);
    return response.data;
  } catch (error) {
    console.error(`Error fetching batch: ${batch}`, error);
    throw error;
  }
};

export const getProductDepenciesForList = async (definitionListId, { definitionIds }) => {
  const batchSize = 10; // Define your preferred batch size
  const allResults = [];

  for (let i = 0; i < definitionIds.length; i += batchSize) {
    const batch = definitionIds.slice(i, i + batchSize);
    // eslint-disable-next-line no-await-in-loop
    const batchResults = await batchRequest(definitionListId, batch);
    allResults.push(...batchResults); // Combine batch results into the final result array
  }

  return allResults;
};

export const getDefinitionList = (
  definitionListId,
  {
    search = '',
    page = '',
    count = '',
    field = '',
    order = '',
    cancelToken,
    tenant = null,
  }
) => {
  const getSettings = { cancelToken };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(
    `/definition-lists/${definitionListId}?search=${search || ''}&page=${page ||
      ''}&count=${count || ''}&field=${field || ''}&order=${order || ''}`,
    getSettings
  );
};

/* eslint-enable prettier/prettier */
export const updateDefinitionList = (
  definitionListId,
  data,
  tenant,
  force = false
) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(
    `/definition-lists/${definitionListId}?force=${force}`,
    data,
    putSettings
  );
};

export const updateDefinitionListTeam = (
  definitionListId,
  currentTenant,
  newTeam
) => {
  const putSettings = {};
  if (currentTenant) {
    putSettings.baseURL = `/tenants/${currentTenant}${settings.baseURL}`;
  }
  return put(
    `/definition-lists/${definitionListId}/team-relationship`,
    {
      team: newTeam
    },
    putSettings
  );
};

export const updateDefinitionListItemColumn = (
  definitionListId,
  data,
  tenant
) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(`/definition-lists/${definitionListId}/item`, data, putSettings);
};

export const renameDefinitionList = (definitionListId, data, tenant) => {
  const patchSettings = {};
  if (tenant) {
    patchSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return patch(`/definition-lists/${definitionListId}`, data, patchSettings);
};

export const retrieveDefinitionPrimaryKeys = async (
  primaryKeys,
  tenant,
  definitionListId = null,
  force = 0
) => {
  const CHUNK_LENGTH = 15;
  let result = [];

  const batchHandler = async (start) => {
    const chunkedKeys = primaryKeys.slice(start, start + CHUNK_LENGTH);
    const response = await feathersClient.service('2.0/definitions').find({
      query: {
        tenant,
        primaryKeys: encodeURI(JSON.stringify(chunkedKeys)),
        definitionListId,
        force
      }
    });
    result = [...result, response].flat();

    if (start + CHUNK_LENGTH > primaryKeys.length) {
      return;
    }
    const nextStart = start + CHUNK_LENGTH;
    batchHandler(nextStart);
  };

  await batchHandler(0);

  return result;
};

export const createDefinitionList = (name, tenant) => {
  const postSettings = {};
  if (tenant) {
    postSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return post('/definition-lists', { name }, postSettings);
};

export const deleteDefinitionList = (definitionListId, tenant) => {
  const deleteSettings = {};
  if (tenant) {
    deleteSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return client.delete(`/definition-lists/${definitionListId}`, deleteSettings);
};

export const importDefinitionList = async (list, tenant) => {
  const response = await feathersClient
    .service('2.0/definition-list')
    .create(list, { query: { tenant } });

  return response;
};

export const deleteDefinition = (definitionId, tenant) => {
  const deleteSettings = {};
  if (tenant) {
    deleteSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return client.delete(`/definitions/${definitionId}`, deleteSettings);
};
export const approveDefinition = (definitionId, tenant) => {
  const patchSettings = {};
  if (tenant) {
    patchSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return patch(
    `/definitions/${definitionId}`,
    { status: 'VALIDATED' },
    patchSettings
  );
};

export const checkURL = async (url) => {
  try {
    return axios.create().get(url);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    // do nothing
  }
};

export const getTerms = (productId, tenant) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(`/products/${productId}/terms`, getSettings);
};
export const getTerm = (termId, tenant) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(`/terms/${termId}`, getSettings);
};
export const createTerm = (productId, data, tenant) => {
  const postSettings = {};
  if (tenant) {
    postSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return post(`/products/${productId}/terms`, data, postSettings);
};
export const updateTermDefinitionsOrder = (
  definition,
  dimension,
  productId,
  tenant
) => {
  const putSettings = {};
  putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  return put(
    `/product/${productId}/definitions-order`,
    { definition, dimension, productId },
    putSettings
  );
};

export const updateTerm = (productId, termId, data, tenant) => {
  const putSettings = {};
  if (tenant) {
    putSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return put(`/terms/${termId}`, { ...data, productId }, putSettings);
};

export const getDimensionsAggregations = (productId, tenant) => {
  const getSettings = {};
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settingsV2.baseURL);
  }
  return apiGetV2(`/dimensions/${productId}`, getSettings);
};

export const unlinkProductTerm = (productId, termId, tenant) => {
  const deleteSettings = {};
  if (tenant) {
    deleteSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return client.delete(
    `/products/${productId}/terms/${termId}`,
    deleteSettings
  );
};

const checkRulesCanceler = [];

/**
 * Given a product id check if all rules are used
 * @param {Number} id - id of the product
 * @returns {Promise}
 */
const checkRulesCancelers = [];
export const checkRules = (
  id = undefined,
  cancel = false,
  tenant = undefined
) => {
  if (cancel) {
    checkRulesCanceler.forEach((f) => f('Check rules request cancelled'));
    // checkRulesCanceler = [];
  }
  const getSettings = {
    cancelToken: new CancelToken((cancellerCallback) => {
      checkRulesCancelers.push(cancellerCallback);
    })
  };
  if (tenant) {
    getSettings.baseURL = getUrlWithTenant(tenant, settings.baseURL);
  }
  return get(`/product/${id}/check-rules`, getSettings);
};

export const exportDefinitionsList = async (
  tenant,
  definitionListId,
  fullname
) => {
  const response = await getDefinitionList(definitionListId, {
    tenant,
    responseType: 'blob'
  });

  const { primaryKeyColumn } = response.data;

  const itemsFormatted = response.data.items.map((df) => ({
    id: df._id,
    primary_key: df.primaryKey,
    primary_key_column: primaryKeyColumn,
    columns: df.columns,
    source: 'JSON file',
    created_at: df._createdAt,
    updated_at: df._updatedAt,
    display_name_columns: df.display_name_columns
  }));

  const definitionsList = {
    metadata: {
      id: response.data._id,
      name: response.data.name.replace(/\*/g, '')
    },
    content: itemsFormatted
  };
  serveAttachment({ ...response, ...{ data: definitionsList } }, fullname);
};

/**
 * Creates a new token
 * @param name
 * @param userId
 * @param hash
 * @returns {Promise}
 */
export const createToken = (name, userId, hash) =>
  post('/token', { name, userId, hash });

/**
 * @param hash
 * @returns {Promise}
 */
export const deleteToken = (userId, name) =>
  client.delete('/token', { userId, name });

/**
 * @returns {Promise}
 */
export const getTokens = () => get('/token');

/**
 * @returns {Promise}
 */
export const getMaintenanceProperties = () => apiGet('/maintenance');

/**
 * @returns {Promise}
 */
export const saveMaintenanceMessage = async (
  maintenance,
  displayMessage,
  message
) => {
  return apiPost('/maintenance', { maintenance, displayMessage, message });
};

export const getCoverageCheck = async (payload, tenant, product, apiToken) => {
  const formattedPayload = computationV3Payload(payload);
  const { data } = await computationAPIPost(
    `/${tenant}/computation`,
    formattedPayload,
    {
      headers: {
        Authorization: Cookies.get('pmt-jwt'),
        'x-api-key': apiToken
      }
    }
  );

  return formatCoverageCheck(formattedPayload, data, product);
};

/**
 * @returns {Promise}
 */
export const getMaxComplexity = () => apiGet('/max-complexity');
