<template>
  <div>
    <product-export-manager
      :product-id="currentProductId"
      :visible="showProductExportManager"
      :current-export-properties="exportProperties"
      @close="(success) => closeExportWizard(success)"
    ></product-export-manager>
    <UiTable
      :data="products || []"
      :default-sort="defaultSort || { prop: 'updatedAt', order: 'descending' }"
      variant="padded"
      row-key="id"
      @sort-change="onSort"
    >
      <el-table-column
        :label="
          $te(productNameTranslationKey)
            ? $t(productNameTranslationKey)
            : $t('product.product_name')
        "
        prop="product_name"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <div class="ProductName">
            <ProductLink
              class="d-flex align-items-stretch ProductName__Link ui_product_name"
              :product="row"
              :ignore-active-releases="ignoreActiveReleases"
            >
              <span class="product-name">{{ row.name }}</span>
              <span v-if="version(row)" class="product-version">{{
                version(row)
              }}</span>
              <UiLockIcon v-if="row.locked" class="ProductName__Icon" />
            </ProductLink>
          </div>
          <el-tooltip
            v-if="
              productValidations[row.id] &&
              productValidations[row.id].isValid === false
            "
          >
            <i class="el-icon-warning-outline"></i>
            <template slot="content">
              <div
                v-for="(error, index) of productValidations[
                  row.id
                ].errors.slice(0, 5)"
                :key="index"
              >
                {{
                  $t(`product.validation.${error.code}`, {
                    property: error.property,
                    definition: error.definition,
                  })
                }}
              </div>
              <div v-if="productValidations[row.id].errors.length > 5">
                <i>
                  <br />
                  And
                  {{ productValidations[row.id].errors.length - 5 }}
                  more...</i
                >
              </div>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isElligibleColumn(productTypes.QUESTIONNAIRE, true)"
        :label="$t('product.type')"
        :min-width="15"
        prop="subtype"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span :title="scope.row.subtype">{{ scope.row.subtype }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Team"
        prop="team"
        class-name="ui_product_author"
        :min-width="20"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span :title="getTeam(scope.row)">{{ getTeam(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isElligibleColumn(productTypes.QUESTIONNAIRE, false)"
        :label="$t('common.entity')"
        prop="author"
        class-name="ui_product_author"
        :min-width="20"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.author }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="isElligibleColumn(productTypes.QUESTIONNAIRE, false)"
        :label="'API'"
        prop="technical_name"
        class-name="ui_product_API"
        :min-width="20"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span :title="scope.row.technicalName">
            {{ scope.row.technicalName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('common.last_modified')"
        sortable="custom"
        prop="updated_at"
        class-name="ui_product_modified"
        :min-width="20"
      >
        <template slot-scope="scope">
          <UiFormattedDate
            v-if="scope.row.updatedAt"
            :date="getProductModifiedDate(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="isElligibleColumn(productTypes.QUESTIONNAIRE, false)"
        :label="$t('common.releases')"
        sortable
        :sort-method="sortRelease"
        prop="latest_version"
        class-name="ui_product_release"
        :width="240"
      >
        <template slot-scope="scope"
          ><InlineVersionList
            :current="scope.row.version.current"
            :latest="scope.row.version.latest"
            :versions="scope.row.version.list"
            :tenant="tenant"
        /></template>
      </el-table-column>
      <el-table-column
        v-if="!hideActions"
        fixed="right"
        width="100"
        class-name="ui_product_release"
      >
        <template slot-scope="{ row }">
          <UiThreeDotsDropDown
            :items="[
              {
                command: 'export',
                label: $t('action.export'),
                class: 'ui_menu_export',
                icon: 'icon-pencil-1',
              },
              !isReadOnlyMode(row)
                ? {
                    command: 'copy',
                    label: $t('action.copy'),
                    class: 'ui_menu_copy',
                    icon: 'far fa-copy',
                  }
                : null,
              !isReadOnlyMode(row)
                ? {
                    command: 'delete',
                    label: $t('action.delete'),
                    class: 'ui_menu_delete',
                    icon: 'far fa-trash-alt',
                    variant: 'danger',
                  }
                : null,
            ]"
            @command="(command) => handleDropdownCommand(command, row)"
          />
        </template>
      </el-table-column>
    </UiTable>
    <el-dialog
      :title="$t('product.duplicate-product')"
      :visible="copyProductVisible"
      custom-class="copyProductDialog"
      @close="closeCopyProduct()"
    >
      <div>
        <label>{{ $t("product.duplicate-product-placeholder") }}</label>
        <el-input
          v-model="copyProductName"
          @input="checkValidName($event)"
        ></el-input>
      </div>
      <div v-if="availableReleasesForCopy.length > 1">
        <label>{{ $t("product.versions.releases") }}</label>
        <el-select v-model="copyProductRelease">
          <el-option
            v-for="item in availableReleasesForCopy"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCopyProduct()">{{
          $t("action.cancel")
        }}</el-button>
        <el-button type="primary" @click="copyProduct()"
          >{{ $t("action.ok") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
import Vue from "vue";
import { ObserveVisibility } from "vue-observe-visibility";
import { mapActions, mapGetters, mapState } from "vuex";
import semver from "semver";
import * as api from "../api";
import { noop } from "../util";
import { productTypes } from "../const/product";
import ProductLink from "./Product/ProductLink.vue";
import ProductExportManager from "./ProductExportManager.vue";
import InlineVersionList from "./Product/InlineVersionList.vue";
import ProductReleaseMixin from "./Product/ProductReleaseMixin";

Vue.directive("observe-visibility", ObserveVisibility);

export default {
  name: "ProductTable",
  components: { ProductLink, ProductExportManager, InlineVersionList },
  mixins: [ProductReleaseMixin],
  props: {
    products: { type: Array, default: undefined },
    productType: { type: String, default: undefined },
    defaultSort: { type: Object, required: true },
    hideActions: { type: Boolean, default: false },
  },
  data: () => ({
    versions: {},
    productTypes,
    showProductExportManager: false,
    currentProductId: 0,
    exportProperties: {},
    copyProductVisible: false,
    productToCopy: {},
    copyProductName: "",
    copyProductRelease: "initial",
  }),
  computed: {
    ...mapGetters("auth", ["isSuperAdmin", "isGuest", "isMember", "isOwner"]),
    ...mapGetters("maintenance", ["isMaintenance"]),
    ...mapState("auth", ["tenant"]),
    ...mapState("teams", ["teams"]),
    ...mapState("product", ["product"]),
    ...mapState("sharedProperty", ["productValidations"]),

    productNameTranslationKey() {
      return `product.${this.productType.toLowerCase()}_name`;
    },
    defaultTeam() {
      return (
        this.teams &&
        this.tenant &&
        this.teams.find((t) => t.slug === this.tenant)
      );
    },
    availableReleasesForCopy() {
      if (!this.productToCopy.version) return [];
      const releases = this.productToCopy.version.list
        .map((v) => {
          const versionLabel = `${v.version.major}.${v.version.minor}.${v.version.patch}`;
          return {
            label: versionLabel,
            key: v.id,
            ...v.version,
          };
        })
        .sort(this.compareRelease("label"));
      releases.unshift({ label: "initial", key: "initial" });
      return releases;
    },
  },
  methods: {
    ...mapActions("product", [
      "markAsDeleted",
      "createProductFromTemplate",
      "createQuestionnaireFromTemplate",
      "refreshProduct",
    ]),
    getProductModifiedDate(row) {
      const updatedAt = this.activeReleaseUpdatedAt(row.id);
      if (updatedAt !== undefined) {
        return updatedAt;
      }

      return row.updatedAt;
    },
    isReadOnlyMode(row) {
      return this.isGuest(row.team.slug) || this.isMaintenance;
    },
    version(product) {
      if (!this.ignoreActiveReleases) return this.activeRelease(product.id);
      const version = this.determineProductVersion(product);
      return version === "initial" ? "Initial Product" : version;
    },
    closeExportWizard() {
      this.showProductExportManager = false;
    },
    onSort(field, order) {
      this.$emit("sort-change", field, order);
    },
    isElligibleColumn(currentProductType, eq) {
      return eq
        ? currentProductType === this.productType
        : currentProductType !== this.productType;
    },
    customSort(a, b) {
      if ((!a || typeof a !== "string") && b) return -1;
      if (a && (!b || typeof b !== "string")) return 1;
      if ((!a && !b) || (typeof a !== "string" && typeof b !== "string"))
        return 0;

      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
      return 0;
    },
    sortRelease(a, b) {
      const [releasesA] = this.getGroupByMinorVersion(a.version.list);
      const [releasesB] = this.getGroupByMinorVersion(b.version.list);
      return this.customSort(releasesA, releasesB);
    },
    handleDropdownCommand(command, product) {
      switch (command) {
        case "copy":
          this.productToCopy = product;
          this.copyProductName = `${this.productToCopy.name} (Copy)`;
          this.copyProductRelease = "initial";
          this.openCopyProduct();
          return noop;
        case "export":
          this.exportProperties = {
            name: product.name,
            type: product.type,
          };
          this.currentProductId = product.version.latest;

          this.showProductExportManager = true;
          return noop;
        case "delete":
          return this.deleteProduct(product);
        default:
          // eslint-disable-next-line no-console
          return console.warn(`Unknown command ${command}`);
      }
    },
    closeCopyProduct() {
      this.copyProductVisible = false;
    },
    openCopyProduct() {
      this.copyProductVisible = true;
    },
    async copyProduct() {
      try {
        if (this.copyProductName.trim() === "") {
          return;
        }

        const product = this.productToCopy;

        this.$emit("copying-product", true);

        const availableTeams =
          this.teams &&
          this.tenant &&
          this.teams.filter(
            (t) =>
              t.tenant === this.tenant &&
              (this.isMember(t.slug) || this.isOwner(this.defaultTeam.slug))
          );

        const selectedTeam = availableTeams.find(
          (t) => t.tenant === this.tenant
        ).id;

        if (product.type === productTypes.QUESTIONNAIRE) {
          await this.createQuestionnaireFromTemplate({
            name: this.copyProductName,
            template: product.id,
            subtype: product.subtype,
            author: product.author,
            teamId: selectedTeam,
            release: this.copyProductRelease,
          });
        } else {
          await this.createProductFromTemplate({
            name: this.copyProductName,
            template: product.id,
            author: product.author,
            teamId: selectedTeam,
            isCopy: true,
            release: this.copyProductRelease,
          });
        }
        await this.refreshProduct({ productId: this.product.id });
        await this.redirectToCurrentProduct().catch(() => {});
      } catch (err) {
        if (typeof err === "string") {
          if (err.includes("504 ERROR")) {
            await MessageBox.alert(
              `Processing...`,
              this.$t("product.duplicate-pending-504")
            )
              .then(() => {
                setTimeout(() => {
                  window.location.reload(true);
                }, 500);
              })
              .catch((error) => {
                noop(error);
              });
          }
        } else {
          const message = err.message || err.toString();
          await MessageBox.alert(message, this.$t("product.duplicate-failed"));
        }
      } finally {
        this.$emit("copying-product", false);
      }
    },
    async redirectToCurrentProduct() {
      await this.$router.push({
        name: "product-home",
        params: { productId: this.product.id },
      });
    },
    async deleteProduct(product) {
      try {
        await MessageBox.confirm(
          productTypes.TEMPLATE === product.type
            ? this.$t("product.msg-confirm-delete-template")
            : this.$t("product.msg-confirm-delete-product"),
          this.$t("definition-lists.msg-confirm-deletion")
        );
        await this.markAsDeleted({ productId: product.id });
        this.$emit("delete", product.id);
        Message.info(
          this.$t("product.delete_succeeded", {
            type: this.productType,
            name: product.name,
          })
        );
      } catch (err) {
        const infoText =
          err.name === "NotAcceptable"
            ? this.$t("product.delete_failed") +
              this.$t("product.questionnaire_in_use")
            : this.$t("product.delete_failed");
        Message.error(infoText);
      }
    },
    exportProduct(product) {
      api.exportProduct(product.id);
    },
    getTeam(row) {
      return (row.team && row.team.label) || "";
    },
    checkValidName(val) {
      const regex = /[^\w)(\s-]/gi;
      this.copyProductName = val.replace(regex, "").substring(0, 120);
    },
    compareRelease(k = "value") {
      return (a, b) => semver.compare(a[k], b[k]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@axatechlab/assets/scss/_variables";

.description {
  color: $color-mineshaft;
}

.product-name {
  font-weight: 600;
  margin-right: 0.4rem;
}

.product-version .el-icon-warning-outline {
  font-size: 20px;
  font-weight: bold;
}

.ProductName {
  display: flex;
  align-items: center;
  flex: 1;

  &__RowIcon {
    flex: 2rem 0 0;
    width: 2em;
    height: auto;
    margin-right: 0.6rem;
  }

  &__Link {
    display: block !important;
    flex: 1;
    white-space: nowrap;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      margin-top: auto;
      margin-bottom: auto;
      width: 80%;
    }
  }

  &__Icon {
    margin-right: 0.3rem;
    margin-left: auto;
  }
}

::v-deep .copyProductDialog {
  max-width: 30%;

  label {
    display: block;

    &:last-of-type {
      margin-top: 10px;
    }
  }
}
</style>
