<template>
  <div class="ProductTestsSummary product-summary">
    <div class="title d-flex align-items-center ui_label_title">
      <component :is="icon" class="icon mr-2" />
      <div class="label">{{ $t("product.nav.tests") }}</div>
    </div>
    <div slot="reference">
      <div :class="[defaultClass, hasError() ? errorClass : '']">
        <div v-if="!hasError()" class="d-flex no-gutters">
          <div class="col-4">
            <div class="stat-label stat-label--green">
              {{ $t("product.tests.passed") }}
            </div>
            <div class="fat ui_label_passed">{{ passed }}</div>
          </div>
          <div class="col-4">
            <div class="stat-label stat-label--red">
              {{ $t("product.tests.failed") }}
            </div>
            <div class="fat ui_label_failed">{{ failed }}</div>
          </div>
          <!-- <div class="col-4">
            <div class="stat-label">
              {{ $t("product.tests.claim_parameters") }}
            </div>
            <div class="fat ui_label_params">{{ parameters }}</div>
          </div> -->
        </div>
        <div v-if="hasError()">
          {{ $t("product.tests.error_explanation") }} <br />
          <b>{{ error }}</b>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <ProductLink
            name="tests"
            class="all ui_link_all_tests"
            :product="product"
          >
            <i class="icon-keyboard-arrow-right"></i>
            {{ $t("product.nav.all_tests") }}
          </ProductLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckListIcon from "../assets/images/checklist.svg";
import * as api from "../api";
import ProductLink from "./Product/ProductLink";

export default {
  name: "ProductTestsSummary",
  components: {
    ProductLink,
    CheckListIcon,
  },
  props: {
    product: { type: Object, required: true },
    stats: { type: Object, required: true },
  },
  data: () => {
    return {
      dimensions: null,
      defaultClass: "block mt-3",
      errorClass: "testsError",
    };
  },
  computed: {
    icon() {
      return CheckListIcon;
    },
    passed() {
      return this.stats.success;
    },
    failed() {
      return this.stats.total - this.stats.success;
    },
    error() {
      return this.stats.error;
    },
    parameters() {
      const { specification } = this.product;
      return (
        (specification.in ? Object.keys(specification.in).length : 0) +
        (this.dimensions ? Object.keys(this.dimensions).length : 0)
      );
    },
  },
  mounted() {
    // this.fetchDimensions();
  },
  methods: {
    async fetchDimensions() {
      // We need the number of used dimensions in the product
      // This happens to be the number of questions returned for an empty query
      const { data } = await api.executeAPIQuery(
        "cover",
        this.product.id,
        { explain: { limits: true } },
        this.versionId
      );
      this.dimensions = data.questions;
    },
    hasError() {
      return !!this.stats.error;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";

.title {
  position: relative;
  font-size: 150%;
  .icon {
    display: inline-block;
  }
}

.block {
  border: 1px solid rgba(43, 48, 52, 0.1);
  border-radius: 10px;
  padding: 1em 1.5em;
}

.stat-label {
  display: inline;
  font-size: 1.1rem;
  height: 24px;
  font-weight: 600;

  &--green,
  &--red {
    color: white;
    padding: 0.3rem 1rem;
    border: 1px solid transparent;
    border-radius: 12px;
  }

  &--green {
    background-color: #39db85;
    border-color: #39db85;
  }

  &--red {
    background-color: #fc4946;
    border-color: #fc4946;
  }
}

.green {
  color: #39db85;
}

.red {
  color: #fc4946;
}

.fat {
  position: relative;
  font-size: 3em;
}

.all {
  position: relative;
}

.testsError {
  background-color: rgb(253, 226, 226);
  overflow: auto;
}
</style>
