/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createFakeRelease } from '../../domain/model/productRelease';
import { acceptLanguage } from '../../i18n';
import {
  dangerNotification,
  successNotification,
  noticeNotification
} from '../errors';
import { getTenant, rootDispatch } from '../helper';
import types from './productRelease.types';
import feathersClient from '../../feathers';

const productService = () => feathersClient.service('2.0/products');

const reloadProduct = async (dispatch, product) =>
  rootDispatch(dispatch, 'product/fetchProduct', {
    productId: product.id,
    version: product.version.current,
    silent: true
  });

const reloadAfterReleaseCreation = async ({ dispatch, commit, product }) => {
  const { id: productId } = product;
  await reloadProduct(dispatch, product);
  dispatch('fetchProductReleases');
  rootDispatch(dispatch, 'productSnapshot/fetchProductSnapshots');
  commit(types.SWITCH_USER_ACTIVE_PRODUCT_RELEASE_SUCCESS, {
    productId,
    latestId: product.referenceProductVersionId,
    version: product.referenceProductVersionAsString,
  });
};

const updateProduct = async (productId, tenant, commands) => {
  return productService().update(productId, commands, {
    query: { tenant },
    headers: {
      'accept-language': acceptLanguage()
    }
  });
};

const onReleaseChange = async (dispatch, product) => {
  await reloadProduct(dispatch, product);
  dispatch('fetchProductReleases');
  rootDispatch(dispatch, 'productSnapshot/fetchProductSnapshots');
};

export default {
  fetchProductReleases: async ({ commit, rootState }) => {
    const { product } = rootState.product;
    const productId = product && product.version.latest;
    if (!productId) throw new Error('Missing product id');
    commit(types.FETCH_PRODUCT_RELEASES_REQUEST);
    try {
      const tenant = getTenant(rootState);
      const releases = await productService().get(productId, {
        query: { query: 'products.releases', tenant },
        headers: {
          'accept-language': acceptLanguage()
        }
      });
      commit(types.FETCH_PRODUCT_RELEASES_SUCCESS, {
        releases,
        product
      });
    } catch (e) {
      commit(types.FETCH_PRODUCT_RELEASES_ERROR);
    }
  },
  changeProductReleaseStatus: async (
    { dispatch, commit, rootState },
    { productId, releaseId, status, isFallbackRelease }
  ) => {
    if (isFallbackRelease) {
      rootDispatch(
        dispatch,
        'notifications/add',
        noticeNotification(
          'RELEASE_STATUS_CHANGED',
          'Please change the fallback release before making this change'
        )
      );
      return;
    }
    commit(types.CHANGE_PRODUCT_RELEASE_STATUS_REQUEST);
    try {
      const tenant = getTenant(rootState);
      const product = await updateProduct(productId, tenant, [
        {
          name: 'changeProductReleaseStatus',
          data: { release: releaseId, status, tenant }
        }
      ]);
      dispatch('fetchProductReleases');
      commit(types.CHANGE_PRODUCT_RELEASE_STATUS_SUCCESS);
      rootDispatch(dispatch, 'productSnapshot/fetchProductSnapshots');
      // noinspection ES6MissingAwait
      reloadProduct(dispatch, product);
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification('RELEASE_STATUS_CHANGED', 'Release status changed')
      );
    } catch (e) {
      commit(types.CHANGE_PRODUCT_RELEASE_STATUS_ERROR);
    }
  },
  toggleFallbackRelease: async (
    { dispatch, commit, rootState },
    { productId, releaseId }
  ) => {
    commit(types.MARK_AS_FALLBACK_RELEASE_REQUEST);
    try {
      const group = getTenant(rootState);
      await updateProduct(productId, group, [
        {
          name: 'toggleFallbackRelease',
          data: { release: releaseId, group }
        }
      ]);
      dispatch('fetchProductReleases');
      commit(types.MARK_AS_FALLBACK_RELEASE_SUCCESS);
      rootDispatch(dispatch, 'productSnapshot/fetchProductSnapshots');
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification('RELEASE_TOGGLE_FALLBACK', 'Release fallback saved')
      );
    } catch (e) {
      commit(types.MARK_AS_FALLBACK_RELEASE_ERROR);
    }
  },
  lambdaRetry: async (
    { dispatch, commit, rootState },
    { id: releaseId, productId }
  ) => {
    commit(types.RETRY_LAMBDA_PUBLICATION_REQUEST);
    try {
      const group = getTenant(rootState);
      await updateProduct(productId, group, [
        {
          name: 'retryLambdaPublication',
          data: { release: releaseId, group }
        }
      ]);
      dispatch('fetchProductReleases');
      commit(types.RETRY_LAMBDA_PUBLICATION_SUCCESS);
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification(
          'RELEASE_RETRY_LAMBDA',
          'Lambda publication will be retry'
        )
      );
    } catch (e) {
      commit(types.RETRY_LAMBDA_PUBLICATION_ERROR);
    }
  },
  removeProductRelease: async (
    { dispatch, commit, rootState },
    { productId, releaseId }
  ) => {
    commit(types.REMOVE_PRODUCT_RELEASE_REQUEST);
    try {
      const tenant = getTenant(rootState);
      await updateProduct(productId, tenant, [
        { name: 'removeProductRelease', data: { release: releaseId, tenant } }
      ]);
      dispatch('fetchProductReleases');
      rootDispatch(dispatch, 'productSnapshot/fetchProductSnapshots');
      commit(types.REMOVE_PRODUCT_RELEASE_SUCCESS);
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification('RELEASE_REMOVED', 'Release removed')
      );
    } catch (e) {
      commit(types.REMOVE_PRODUCT_RELEASE_ERROR);
      rootDispatch(
        dispatch,
        'notifications/add',
        dangerNotification('RELEASE_REMOVED_FAILED', 'Unable to remove release')
      );
    }
  },
  releaseProduct: async (
    { dispatch, commit, state, rootState },
    { productId, major, minor, patch }
  ) => {
    if (state.isCreating) throw new Error('Creation in progress');
    if (!rootState.product.product) throw new Error('Product not loaded');
    const fakeRelease = createFakeRelease(productId, {
      name: rootState.product.product.name,
      major,
      minor,
      patch
    });
    commit(types.CREATE_PRODUCT_RELEASE_REQUEST, fakeRelease);
    try {
      const tenant = getTenant(rootState);
      const product = await updateProduct(productId, tenant, [
        {
          name: 'releaseProduct',
          data: {
            id: fakeRelease.id,
            version: { major, minor, patch },
            tenant
          }
        }
      ]);
      product.referenceProductVersionId = rootState.product.product.version.latest; 
      product.referenceProductVersionAsString = fakeRelease.versionAsString; 

      await reloadAfterReleaseCreation({ dispatch, commit, product });
      commit(types.CREATE_PRODUCT_RELEASE_SUCCESS);
      rootDispatch(
        dispatch,
        'notifications/add',
        successNotification('RELEASE_CREATED', 'Release created')
      );
    } catch (e) {
      commit(types.CREATE_PRODUCT_RELEASE_ERROR);
      rootDispatch(dispatch, 'uiLoading/hide');
      rootDispatch(
        dispatch,
        'notifications/add',
        dangerNotification(
          'RELEASE_CREATED_FAILED',
          'Unable to create the release'
        )
      );
    }
  },
  switchUserActiveProductRelease: async (
    { dispatch, commit, rootState },
    { productId, releaseId }
  ) => {
    commit(types.SWITCH_USER_ACTIVE_PRODUCT_RELEASE_REQUEST, {
      productId
    });
    try {
      const tenant = getTenant(rootState);
      const product = await updateProduct(productId, tenant, [
        {
          name: 'switchUserActiveProductRelease',
          data: { id: releaseId, tenant }
        }
      ]);
      commit(types.SWITCH_USER_ACTIVE_PRODUCT_RELEASE_SUCCESS, {
        productId: product.id,
        latestId: product.version.latest,
        version: product.version.current
      });
      await onReleaseChange(dispatch, product);
    } catch (e) {
      commit(types.SWITCH_USER_ACTIVE_PRODUCT_RELEASE_ERROR, {
        productId
      });
    }
  },
  // This action is only used to set the current release in Product.vue
  // to switch the UI active release use switchUserActiveProductRelease action
  setUserActiveProductRelease: async (
    { commit },
    { productId, shadowProductId, version }
  ) => {
    commit(types.SWITCH_USER_ACTIVE_PRODUCT_RELEASE_SUCCESS, {
      productId: shadowProductId,
      latestId: productId,
      version
    });
  }
};
