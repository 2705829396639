/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('productRelease', [
      'releaseProduct',
      'switchUserActiveProductRelease'
    ]),
    async createRelease(
      major,
      minor,
      patch,
      { updateRoute } = { updateRoute: true }
    ) {
      await this.releaseProduct({
        productId: this.product.id,
        major,
        minor,
        patch
      });
      if (updateRoute)
        await this.updateReleaseVersionRequestParameter({
          major,
          minor,
          patch
        });
    },
    async switchCurrentActiveRelease(
      releaseId,
      version,
      productId,
      { updateRoute } = { updateRoute: false }
    ) {
      await this.switchUserActiveProductRelease({
        productId,
        releaseId
      });
      if (updateRoute) await this.updateReleaseVersionRequestParameter(version);
    },
    async updateReleaseVersionRequestParameter(version) {
      if (version.major === 0 && version.minor === 0 && version.patch === 0) {
        // @todo remove when the latest product is converted to shadow product.
        await this.$router
          .push({
            query: {
              version: 'initial'
            }
          })
          .catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
          });
        window.location.reload();
      } else {
        await this.$router
          .push({
            query: {
              version: `${version.major}.${version.minor}.${version.patch}`
            }
          })
          .catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
          });
      }
    }
  }
};
