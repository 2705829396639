<template>
  <div v-selector.view class="px-5 pt-2 pb-5 d-flex flex-row">
    <div class="grid" :data-product-type="realProductType">
      <ProductInfo
        :product="product"
        :editable="false"
        :has-external-link="true"
      />
      <ProductRulesSummary :product="product" />
      <ProductDimensionsSummary
        :product="product"
        :source-language="sourceLanguage"
      />
      <ProductInputsSummary :product="product" />
      <ProductTestsSummary
        v-if="!isQuestionnaire"
        :product="product"
        :stats="stats"
      />
      <ProductReleasesSummary v-if="isProduct" :product="product" />
      <ProductDocumentsSummary
        v-if="copilotActivated && !isGuest(tenant) && !isQuestionnaire"
        :product="product"
      />
      <ProductAppsSummary
        v-if="!isQuestionnaire"
        :product="product"
        :stats="stats"
      />

      <div v-if="isQuestionnaire" class="relatedProducts">
        <div class="title d-flex ui_label_title">
          <div class="label">
            {{ $t("product.questionnaire_related_products") }}
          </div>
        </div>
        <ProductTable
          :products="relatedProducts"
          product-type="PRODUCT"
          :default-sort="{ prop: 'id', order: 'descending' }"
          :hide-actions="true"
          :ignore-active-releases="true"
          @sort-change="(field, order) => $emit('sort-change', field, order)"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapActions, mapGetters } from "vuex";
import ProductReleasesSummary from "../components/ProductReleases/Summary/ProductReleasesSummary.vue";
import ProductRulesSummary from "../components/ProductRulesSummary.vue";
import ProductDimensionsSummary from "../components/ProductDimensionsSummary.vue";
import ProductInputsSummary from "../components/ProductInputsSummary.vue";
import ProductTestsSummary from "../components/ProductTestsSummary.vue";
import ProductAppsSummary from "../components/ProductAppsSummary.vue";
import ProductDocumentsSummary from "../components/ProductDocumentsSummary.vue";
import ProductInfo from "../components/ProductInfo.vue";
import ProductTable from "../components/ProductTable.vue";

import { productTypes } from "../const/product";
import I18nMixin from "../components/I18nMixin";

export default {
  name: "ProductHome",
  components: {
    ProductReleasesSummary,
    ProductRulesSummary,
    ProductDimensionsSummary,
    ProductInputsSummary,
    ProductTestsSummary,
    ProductAppsSummary,
    ProductDocumentsSummary,
    ProductInfo,
    ProductTable,
  },
  mixins: [I18nMixin],
  props: {
    stats: { type: Object, required: true },
  },
  data() {
    return {
      relatedProducts: [],
    };
  },
  computed: {
    ...mapState("auth", ["tenant"]),
    ...mapState("product", ["product"]),
    ...mapGetters("product", ["copilotActivated"]),
    ...mapGetters("auth", ["isGuest"]),
    isQuestionnaire() {
      return [
        productTypes.QUESTIONNAIRE,
        productTypes.SHADOW_QUESTIONNAIRE,
      ].includes(this.product.type);
    },
    isProduct() {
      return [productTypes.PRODUCT, productTypes.SHADOW_PRODUCT].includes(
        this.product.type
      );
    },
    realProductType() {
      return this.isQuestionnaire
        ? productTypes.QUESTIONNAIRE
        : productTypes.PRODUCT;
    },
  },
  watch: {
    $route(to) {
      if (to.params.productId !== this.product.id) {
        this.initComponent(to.params.productId);
      }
    },
    "product.id": {
      async handler(productId) {
        if (
          ["SHADOW_QUESTIONNAIRE", "QUESTIONNAIRE"].includes(this.product.type)
        ) {
          this.relatedProducts = await this.getRelatedProductsByQuestionnaire({
            productId,
          });
        }
      },
    },
  },
  mounted() {
    const productId = this.$route.params.productId || this.product.id;
    this.initComponent(productId);
  },
  methods: {
    ...mapActions("product", [
      "getRelatedProductsByQuestionnaire",
      "fetchProductComplexity",
      "setProductConfigActiveTab",
    ]),
    async initComponent() {
      if (this.isQuestionnaire) {
        this.relatedProducts = await this.getRelatedProductsByQuestionnaire({
          productId: this.product.version.latest,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  width: 100%;

  grid-template-columns: repeat(3, 1fr);

  grid-template-areas:
    "overview rules dimensions"
    "inputs tests releases"
    "documents apps releases";

  grid-gap: 2rem;
  margin-top: 1rem;

  .ProductInfo {
    grid-area: overview;
  }

  .ProductRulesSummary {
    grid-area: rules;
  }

  .ProductDimensionsSummary {
    grid-area: dimensions;
  }

  .ProductInputsSummary {
    grid-area: inputs;
  }

  .ProductTestsSummary {
    grid-area: tests;
  }

  .ProductDocumentsSummary {
    grid-area: documents;
  }

  .ProductAppsSummary {
    grid-area: apps;
  }

  .ProductReleasesSummary {
    grid-area: releases;
  }

  .relatedProducts {
    margin-top: 1rem;
    grid-column: 1 / 3;

    .title {
      .label {
        font-size: 150%;
      }
    }
  }

  &[data-product-type="QUESTIONNAIRE"] {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "overview rules"
      "inputs dimensions";
  }
}
</style>
