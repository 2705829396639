<template>
  <ul class="SearchResult">
    <li v-for="item in results" :key="item.hash" class="SearchResult__Item">
      <ResultItemNode
        v-if="item.type === 'node'"
        :item="item"
        @click="handleFocus"
      />
      <ResultItemRule
        v-else-if="item.type === 'rule'"
        :item="item"
        @click="handleFocus"
      />
      <ResultItemProperty v-else :item="item" @click="handleFocus" />
    </li>
  </ul>
</template>

<script>
import ResultItemNode from "./ResultItemNode";
import ResultItemProperty from "./ResultItemProperty";
import ResultItemRule from "./ResultItemRule";

export default {
  name: "SearchResult",
  components: {
    ResultItemNode,
    ResultItemProperty,
    ResultItemRule,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    handleFocus: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchResult {
  width: 100%;
  padding: 0;

  &__Item {
    list-style: none;
    transition: all 220ms ease-in-out;
    border-bottom: 1px solid rgba(0, 0, 143, 0.1);
    margin-top: 10px;
  }
}
</style>
