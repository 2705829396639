/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import axios from 'axios';
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
import types from './productCustomization.types';

import { configureClient } from '../../api/helpers';

export const settings = { baseURL: `${window.__env__.VUE_APP_API_URL}/` };

const client = axios.create(settings);
configureClient(client, settings);

export default {
  fetchProductCustomizations: async (
    { commit, rootState },
    { productId, version = null, apiToken }
  ) => {
    commit(types.FETCH_PRODUCT_CUSTOMIZATIONS_REQUEST);
    try {
      const customizations =
        (
          await client.get(
            `/api/customization/tenants/${rootState.auth.tenant}/${productId}${
              version ? `?version=${version}` : ''
            }`,
            {
              headers: {
                'x-api-key': apiToken
              }
            }
          )
        )?.data || [];
      commit(types.FETCH_PRODUCT_CUSTOMIZATIONS_SUCCESS, {
        customizations
      });
    } catch (e) {
      commit(types.FETCH_PRODUCT_CUSTOMIZATIONS_ERROR);
    }
  }
};
