<template>
  <div
    class="shortcut-container"
    :style="{
      pointerEvents: 'none',
    }"
    :class="{
      row: flexDirection === 'row',
      column: flexDirection === 'column',
    }"
  >
    <div
      v-if="keyboardShortcutsEnabled"
      class="keys"
      :class="{ inactive: !active }"
    >
      <i
        v-if="loading"
        slot="prefix"
        class="el-icon-loading el-input__icon"
      ></i>
      <template v-else>
        <template v-for="modifier of modifiers">
          <div :key="modifier" class="key">
            <span>
              {{ modifier.toUpperCase() }}
            </span>
          </div>
          +
        </template>
        <div class="key">
          <span>
            {{ label }}
          </span>
        </div>
      </template>
    </div>
    <div class="content" :class="{ multiline: multilineContent }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyboardShortcut",
  inject: ["kbShortcutsEnabled"],
  props: {
    keyCode: {
      type: Number,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    flexDirection: {
      type: String,
      default: "row",
    },
    exact: {
      type: Boolean,
      default: false,
    },
    prevent: {
      type: Boolean,
      default: () => false,
    },
    stop: {
      type: Boolean,
      default: () => false,
    },
    modifiers: {
      type: Array,
      default: () => [],
    },
    multilineContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      eventListener: false,
      keyboardShortcutsEnabled: this.kbShortcutsEnabled.val,
    };
  },
  watch: {
    active: {
      handler() {
        if (this.active) {
          if (!this.eventListener) {
            window.addEventListener("keyup", this.keyEvent);
            this.eventListener = true;
          }
        } else if (this.eventListener) {
          window.removeEventListener("keyup", this.keyEvent);
          this.eventListener = false;
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    if (this.eventListener) {
      window.removeEventListener("keyup", this.keyEvent);
    }
  },
  methods: {
    keyEvent(e) {
      if (this.keyboardShortcutsEnabled && this.active) {
        if (e.keyCode === this.keyCode) {
          if (this.isExact(e)) {
            if (this.prevent) {
              if (e.preventDefault) {
                e.preventDefault();
              }
            }
            if (this.stop) {
              e.stopPropagation();
            }
            this.$emit("action", e);
          }
        }
      }
    },
    isExact(e) {
      if (this.exact) {
        return (
          e.shiftKey === this.modifiers.includes("shift") &&
          e.ctrlKey === this.modifiers.includes("ctrl") &&
          e.metaKey === this.modifiers.includes("meta")
        );
      }
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.shortcut-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  pointer-events: none;

  .keys {
    position: relative;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-right: 8px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
  }

  .key {
    text-align: center;
    min-width: 12px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: scale(0.8);
    border-radius: var(--border-radius-small);
  }

  .inactive {
    opacity: 0.1;
  }

  .content {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
    &.multiline {
      white-space: break-spaces;
      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      flex-shrink: 1;
    }
  }
}
</style>
