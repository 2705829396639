<template>
  <div v-if="!isReadOnly" class="PropertyMetadataOperation">
    <UiCircularButton
      v-if="isEditing(entry) && hasPendingChange()"
      variant="success"
      icon="el-icon-check"
      @click="handleSave()"
    ></UiCircularButton>
    <UiCircularButton
      v-if="isEditing(entry)"
      variant="danger"
      icon="el-icon-close"
      @click="handleClose()"
    />
    <UiCircularButton
      v-if="canRemove(entry) && !isEditing(entry)"
      @click="handleRemove()"
    >
      <i class="icon-bin-1" />
    </UiCircularButton>
  </div>
</template>

<script>
export default {
  name: "PropertyMetadataOperation",
  props: {
    entry: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Function,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    canRemove: {
      type: Function,
      required: true,
    },
    hasPendingChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleEditing() {
      this.$emit("editing", this.entry);
    },
    handleSave() {
      this.$emit("save", this.entry);
    },
    handleClose() {
      this.$emit("close", this.entry);
    },
    handleRemove() {
      this.$emit("remove", this.entry);
    },
  },
};
</script>

<style lang="scss" scoped>
.PropertyMetadataOperation {
  margin-left: auto;
}
</style>
