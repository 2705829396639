<template>
  <div :class="classNames">
    <span v-if="readonly && !locked">READONLY</span>
    <span v-if="locked"><UiLockIcon /> LOCKED</span>
  </div>
</template>

<script>
export default {
  name: "ReadOnlyLabel",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classNames() {
      const { readonly, locked } = this;
      return {
        ReadOnlyLabel: true,
        "ReadOnlyLabel--Readonly": readonly,
        "ReadOnlyLabel--Locked": locked,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ReadOnlyLabel {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
  color: #999;
  font-size: 11px;

  .icon::v-deep {
    margin-top: -2px;
  }
}
</style>
