<template>
  <UiBaseLayout v-selector.view>
    <div class="center-table">
      <UiPaddedArea class="p-0">
        <UiToolbar>
          <div class="flex-spacer" />
          <b-input-group class="search-group">
            <b-form-input
              v-model="searchStr"
              class="search-input"
              data-cy="tests-search"
              :placeholder="$t(`user.search_user`)"
            />
            <b-icon icon="search" class="search-input-icon"></b-icon>
          </b-input-group>
          <div class="flex-spacer" />
        </UiToolbar>
      </UiPaddedArea>
      <UiLoadingArea :is-loading="isLoading">
        <transition v-enter-active mode="out-in" class="animated fadeIn">
          <UserTable
            :users="allUsers || []"
            :roles="roles || []"
            :teams="teams || []"
            :fetch-users="fetchUsers"
          ></UserTable>
        </transition>
      </UiLoadingArea>
    </div>
  </UiBaseLayout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { MessageBox } from "element-ui";
import HomeIcon from "../../assets/images/home.svg";
import Loading from "../Loading";
import UserTable from "./UserTable";

export default {
  name: "Users",
  components: {
    Loading,
    UserTable,
  },
  props: {
    onClickInvite: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    searchStr: null,
    allUsers: [],
    isLoading: false,
  }),

  computed: {
    ...mapState("users", ["error", "users", "roles"]),
    ...mapState("teams", ["teams"]),
    defaultIcon() {
      return HomeIcon;
    },
  },
  watch: {
    users: {
      handler(val) {
        this.allUsers = [].concat(val);
      },
    },
    searchStr: {
      handler(val) {
        this.filterUserList(val);
      },
    },
    error: {
      immediate: true,
      async handler(error) {
        if (error) {
          MessageBox(error);
          this.setError(undefined);
        }
      },
    },
  },
  async mounted() {
    if (!this.users) await this.fetchUsers();
    if (!this.roles) await this.getRoles();
  },
  methods: {
    ...mapActions("users", ["getUsers", "getRoles", "setError"]),
    ...mapActions("teams", ["loadTeams"]),
    async fetchUsers() {
      this.isLoading = true;
      await this.getUsers();
      this.allUsers = [].concat(this.users);
      this.filterUserList(this.searchStr);
      this.isLoading = false;
    },
    roleById(roleId) {
      return this.roles.find((role) => role.id === roleId);
    },
    filterUserList(val) {
      if (!val) {
        this.allUsers = this.users;
        return;
      }
      const str = val.toLowerCase();
      this.allUsers = (this.users || []).filter((user) => {
        const teamIncludeStr = (team) =>
          team.label.toLowerCase().includes(str) ||
          this.roleById(team.UserTeam.tenant_role_id)
            .label.toLowerCase()
            .includes(str);

        return (
          user.email.toLowerCase().includes(str) ||
          user.teams.some(teamIncludeStr)
        );
      });
    },
  },
};
</script>