<template>
  <el-input
    v-if="current || mode === 'form'"
    ref="input"
    v-model="value"
    class="question"
    :data-cy="`input-${question.id}`"
    :clearable="mode === 'form'"
    :disabled="question.loading"
    :placeholder="$t('enter-monetary-amount')"
    inputmode="numeric"
    pattern="[0-9]*"
    @input="
      $emit('show');
      setValue(substitute($event));
    "
    @keydown.enter.exact.native.prevent.stop="
      setValue(substitute($event.target.value));
      submit(true);
    "
    @clear="submitClear"
    @keydown.tab.native.capture="captureTab(substitute($event))"
    @focus="$emit('focus')"
    @blur="setValueOnBlur($event)"
  >
    <i
      v-if="question.loading"
      slot="prefix"
      class="el-icon-loading el-input__icon"
    ></i>
  </el-input>
</template>
<script>
import captureTabMixin from "./mixins/captureTabMixin";
import inputMixin from "./mixins/inputMixin";

export default {
  name: "InputMonetaryComponent",
  mixins: [captureTabMixin, inputMixin],
  methods: {
    setValueOnBlur(event) {
      if (this.mode === "form") {
        this.setValue(event.target.value);
        this.submit(false);
      }
    },
    async focusInput() {
      await this.$nextTick();
      if (this.$refs.input) {
        this.$refs.input.$el
          .querySelector("input")
          .focus({ preventScroll: true });
      }
    },
    substitute(val) {
      return val.replace(/,/g, ".");
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  max-width: 100%;
}
</style>
