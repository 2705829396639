<template>
  <div v-if="count > 4">
    <ProductReleaseLink :product="product" type="all">
      {{ count - 4 }} others releases
    </ProductReleaseLink>
  </div>
</template>

<script>
import ProductReleaseLink from "../../Product/ProductReleaseLink";
import ProductSnapshotMixin from "../ProductSnapshotMixin";

export default {
  name: "ProductReleasesSummaryLink",
  components: { ProductReleaseLink },
  mixins: [ProductSnapshotMixin],
  props: {
    count: { type: Number, required: true },
    product: { type: Object, required: true },
  },
};
</script>

<style scoped lang="scss">
@import "@axatechlab/assets/scss/_variables";
.List {
  padding: 0;
  margin: 0;

  &__Item {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.Version {
  display: flex;
  align-items: center;
  line-height: 1.8rem;
  &__Name {
    flex: 1;
    margin: 0 0.4rem;
  }

  &__Number {
  }
}
</style>
