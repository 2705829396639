import Question from './question.model';

export default class DateQuestion extends Question {
  type = 'datetime';

  responseDisplay() {
    if (this.response) {
      const d = new Date(
        Number(this.response.id) - new Date().getTimezoneOffset() * 60 * 1000
      );
      return `${d.toISOString().slice(0, 10)} ${d
        .toLocaleTimeString()
        .slice(0, 6)}`;
    }
    return undefined;
  }
}
