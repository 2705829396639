/**
 * Copyright 2020 AXA Team Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as Role from '../../domain/service/role';

const isSuperAdmin = (user) => {
  if (!user || !user.teams) return false;
  const { teams } = user;
  return teams.some(({ UserTeam }) =>
    Role.isSuperAdmin(UserTeam.tenant_role_id)
  );
};

const isByTeam = (state, team, callback) => {
  if (!state.user) {
    return false;
  }

  const { teams = [] } = state.user;
  // team param can either be the team ID or the team slug.
  return teams.some(({ id, slug, UserTeam }) =>
    team
      ? (team === id || team === slug) && callback(UserTeam.team_role_id)
      : callback(UserTeam.team_role_id)
  );
};

const getters = {
  isLoggedIn: (state) => {
    return !!state.user;
  },
  isSuperAdmin: (state) => (user) => {
    if (!state.user && !user) return false;
    return isSuperAdmin(user || state.user);
  },

  isAdmin: (state) => (team) =>
    isSuperAdmin(state.user) || isByTeam(state, team, Role.isAdmin),
  isOwner: (state) => (team) =>
    isSuperAdmin(state.user) || isByTeam(state, team, Role.isOwner),
  isMember: (state) => (team) =>
    isSuperAdmin(state.user) || isByTeam(state, team, Role.isMember),
  isGuest: (state) => (team) => isByTeam(state, team, Role.isGuest),
  getUser: (state) => state.user,
  getUserPreferences: (state) => {
    if (state.user) {
      const preferences = state.user.profiles.find(
        (profile) => profile.path === 'ui.apps.preferences'
      );
      if (preferences) {
        try {
          return JSON.parse(preferences.value);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          return {};
        }
      }
    }
    return {};
  },
  getAccessToken: (state) => state.access_token
};

export default getters;
