<template>
  <div class="Shell">
    <Terminal
      ref="shell"
      class="Shell__Wrapper"
      :banner="banner"
      :shell_input="send_to_terminal"
      :commands="commands"
      @shell_output="prompt"
      @close="$emit('close')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Terminal from "./Shell/Terminal";
import shellCommands from "../shell";

export default {
  name: "Shell",
  components: { Terminal },
  data() {
    return {
      send_to_terminal: "",
      banner: {
        helpHeader: 'Enter "help" for more information.',
        sign: "PMT Shell $",
      },
      commands: shellCommands,
    };
  },
  computed: {
    ...mapState("auth", ["tenant"]),
    ...mapState("product", ["product"]),
  },
  methods: {
    prompt(value) {
      let args = value.split(" ").filter((val) => val);
      const cmd = args[0].toLowerCase();
      args = args.splice(1);

      const command = this.commands.find((c) => c.name === cmd);
      if (!command) {
        this.send_to_terminal =
          'Command not found, type "help" to see available commands';
      }
    },
    focus() {
      this.$refs.shell.$refs.cmd.focus();
    },
  },
};
</script>

<style lang="scss">
.Shell {
  &__Wrapper {
  }
}
</style>
