<template>
  <div v-selector.view class="container">
    <div class="d-flex align-items-center justify-content-between">
      <h3 class="my-4">{{ $t("product.terms.terms") }}</h3>
    </div>
    <div class="row">
      <div
        v-for="property in termsData.propertyKeys"
        :key="property.key"
        class="col-12 col-md-6 col-lg-4"
      >
        <property-card
          :product="product"
          :property="property"
          :definition-list-id="getDefinitionListId(property)"
          :terms="termsData.terms[property]"
          :unmatched-terms="termsData.unmatchedTerms[property]"
          :definition-list="getDefinitionList(property)"
          :source-language="sourceLanguage"
          @fetchData="fetchData"
        ></property-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapActions, mapState } from "vuex";
import PropertyCard from "../components/PropertyCard.vue";
import I18nMixin from "../components/I18nMixin";

export default {
  name: "ProductTermsIndex",
  components: { PropertyCard },
  mixins: [I18nMixin],
  data() {
    return {
      termsData: {},
    };
  },

  computed: {
    ...mapState("definitions", ["definitions"]),
    ...mapState("auth", ["tenant"]),
    ...mapState("product", ["product"]),
    ...mapState("productProperty", {
      productDimensions: "dimensions",
    }),
  },
  watch: {
    product() {
      this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
    this.fetchDefinitions();
  },
  methods: {
    ...mapActions("definitions", ["fetchDefinitions"]),
    ...mapActions("productProperty", ["fetchDimensions"]),
        ...mapActions("product", ["refreshProduct"]),
    getDefinitionListId(property) {
      const dlIds = [
        this.termsData.properties[property].definition_list_id || 0,
        this.termsData.properties[property].definitionListId || 0,
      ];
      return dlIds.reduce((acc, curval) => {
        return curval !== 0 ? curval : acc;
      }, 0);
    },
    getDefinitionList(property) {
      const definitionListId = this.getDefinitionListId(property);
      const defList = this.definitions.find(
        (df) => df._id === definitionListId
      );
      return defList || {};
    },
    async fetchData(options=null) {

      if(options && options.fetchProductRequired){
        this.refreshProduct({
          productId: this.product.id,
        });
      }

      await this.fetchDimensions(this.product.id).then(() => {
        this.termsData = this.productDimensions;
      });
    },
  },
};
</script>

<style scoped>
.card-columns {
  column-count: 3;
}
</style>
