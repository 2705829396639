/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { JSONOperation } from '@axatechlab/cc-json-operations';

export default {
  dimensions(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'DIMENSION')
      : [];
  },
  inputs(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'INPUT')
      : [];
  },
  computes(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'COMPUTED')
      : [];
  },
  outputs(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'OUTPUT')
      : [];
  },
  units(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'UNIT')
      : [];
  },
  endorsements(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'ENDORSEMENT')
      : [];
  },
  metadata(state) {
    return state.properties
      ? state.properties.filter((p) => p.__type === 'METADATA').map(p => ({...p,protected: p.protected || p.name.startsWith('_')}))
      : [];
  },

  unusedProperties(state, getters, rootState) {
    const extractVarsFromExpression = (expr) => {
      const regex = /[+/-\s*()]/gi;
      return expr
        .replace(regex, '!')
        .replace(/!+/g, '!')
        .split('!')
        .filter((v) => Number.isNaN(Number.parseFloat(v)));
    };

    const handleVariableNameUsageHandler = (graphElement) => {
      const getVariablesName = (subject) => {
        const matches = [];
        const varRegExp = /\$([^\s]+)/g;
        let match = varRegExp.exec(subject);
        while (match != null) {
          matches.push(match[1]);
          match = varRegExp.exec(subject);
        }
        return matches;
      };
      let result = [];
      if (graphElement.type === 'ask') {
        result.push(graphElement.value);
      }
      if (graphElement.expressionStr) {
        if (Array.isArray(graphElement.expressionStr)) {
          graphElement.expressionStr.forEach((elm) => {
            result = result.concat(getVariablesName(elm));
          });
        } else {
          result = result.concat(getVariablesName(graphElement.expressionStr));
        }
      }
      return result;
    };

    const propsToCheck = {
      dimensions: (graphElement) => {
        const result = [];
        if (graphElement.type === 'enum' && graphElement.key) {
          result.push(graphElement.key);
        }
        if (
          graphElement.type === 'insuranceOutput' &&
          graphElement.scopes &&
          graphElement.scopes.target
        ) {
          if (graphElement.scopes.target.test === 'matchQuery') {
            result.push(graphElement.scopes.target.args[0]);
          } else {
            graphElement.scopes.target.args.forEach((elm) =>
              result.push(elm.args[0])
            );
          }
        }
        return result;
      },
      in: (graphElement) => {
        return handleVariableNameUsageHandler(graphElement);
      },
      computes: (graphElement) => {
        return handleVariableNameUsageHandler(graphElement);
      },
      out: (graphElement) => {
        return (
          ['insuranceOutput', 'textOutput', 'booleanOutput'].includes(
            graphElement.type
          ) && graphElement.key
        );
      },
      units: (graphElement) => {
        return (
          graphElement.type === 'insuranceOutput' &&
          graphElement.scopes &&
          graphElement.scopes.unit
        );
      },
      endorsements: (graphElement) => {
        if (graphElement.options && graphElement.options.combination) {
          const result = [];
          Object.keys(graphElement.options.combination).forEach(
            (endorsement) => {
              if (endorsement) result.push(endorsement);
            }
          );
          return result;
        }
        return false;
      }
    };

    const usedProps = {};
    const findProperties = (graphElement) => {
      if (graphElement.terms) {
        graphElement.terms.forEach((elm) => {
          findProperties(elm);
        });
      }
      // eslint-disable-next-line consistent-return

      Object.keys(propsToCheck).forEach((prop) => {
        if (!usedProps[prop]) usedProps[prop] = new Set();
        const result = propsToCheck[prop](graphElement);
        if (result) {
          if (Array.isArray(result)) {
            result.forEach((pr) => usedProps[prop].add(pr));
          } else {
            usedProps[prop].add(result);
          }
        }
      });
    };
    const unusedProps = {};
    const { product } = rootState.product;
    if (!product) return unusedProps;

    product.rules.forEach((rule) => {
      findProperties(rule.graph);
    });
    Object.keys(propsToCheck).forEach((p) => {
      if (product.specification[p]) {
        unusedProps[p] = Object.keys(product.specification[p]).filter(
          (elm) => !usedProps[p].has(elm)
        );
      }
    });

    let allInputs = [];
    const computedVars = state.properties.filter(
      (p) => p.__type === 'COMPUTED' && !unusedProps.computes.includes(p.name)
    );

    for (let i = 0; i < computedVars.length; i += 1) {
      const computeProp = computedVars[i];
      if (computeProp.metadata.expression) {
        const response = JSONOperation.toString(
          computeProp.metadata.expression.startsWith('{') &&
            computeProp.metadata.expression.endsWith('}')
            ? JSON.parse(computeProp.metadata.expression)
            : computeProp.metadata.expression
        );
        const { status, result } = response;
        if (status === 'SUCCESS') {
          const localInputs = extractVarsFromExpression(result);
          allInputs = allInputs
            .concat(localInputs)
            .map((p) => p.replace(/\$/, ''));
        }
      }
    }
    const unusedInput = (unusedProps.in || []).filter((pName) => {
      return !allInputs.includes(pName);
    });

    return { ...unusedProps, in: unusedInput };
  }
};
