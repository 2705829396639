/**
 * Copyright 2020 AXA Group Operations S.A.
 *
 * Licensed under the Apache License, Version 2.0 (the "License")
 * you may not use this file except in compliance with the License
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Question from './question.model';
import Answer from './answer.model';

export default class BooleanQuestion extends Question {
  type = 'boolean';

  answers = [
    new Answer({
      id: 'true',
      displayName: 'true'
    }),
    new Answer({
      id: 'false',
      displayName: 'false'
    })
  ];

  possibleAnswers = ['true', 'false'];

  makeAnswer(a) {
    if (a !== undefined && a !== null) {
      if (typeof a !== 'object') {
        if (typeof a !== 'string') {
          // eslint-disable-next-line no-param-reassign
          a = a ? 'true' : 'false';
        }
        const response = this.answers.find((ans) => ans.id === a.toLowerCase());
        if (response) {
          return response;
        }
      }
      if (a.id) {
        const response = this.answers.find((ans) => ans.id === a.id);
        if (response) {
          return response;
        }
      }
    }
    return undefined;
  }
}
