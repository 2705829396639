import axios from 'axios';
import { configureClient } from './helpers';

export const settings = { baseURL: `${window.__env__.VUE_APP_API_URL}/` };

const client = axios.create(settings);
configureClient(client, settings);

const commandClientUrl = (tenant, viewName, queryName) => {
  const tenantPath = tenant ? `/tenants/${tenant}` : '';
  const isCopilot = viewName.split('/')[0] === 'copilot';
  if (!isCopilot) {
    return `${window.__env__.VUE_APP_API_URL}${tenantPath}/api/editor/${viewName}/${queryName}`;
  }
  return `${
    window.__env__.VUE_APP_COPILOT_URL || window.__env__.VUE_APP_API_URL
  }/${viewName}/${queryName}`;
};

export const commandClient = async (tenant, viewName, commandName, payload) => {
  const { status, data } = await client.post(
    commandClientUrl(tenant, viewName, commandName),
    payload
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export const commandClientForm = async (
  tenant,
  viewName,
  commandName,
  formData
) => {
  if (!(formData instanceof FormData)) {
    throw new Error('Expected FormData as payload');
  }
  const { status, data } = await client.post(
    commandClientUrl(tenant, viewName, commandName),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  if (status === 200) return data;
  // @todo better error handling
  throw new Error('Todo better error handling');
};

export default { commandClient, commandClientForm };
