<template>
  <div v-selector.view class="app">
    <ComplexityChecker type="blocker"></ComplexityChecker>

    <div v-if="showApps" class="row">
      <router-view :product="product"></router-view>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { productTypes } from "../const/product";
import ComplexityChecker from "../components/Product/ComplexityChecker.vue";

export default {
  name: "ProductApps",
  components: {
    ComplexityChecker,
  },
  props: { product: { type: Object, required: true } },
  computed: {
    showApps() {
      return this.product.version.list.length > 0 || productTypes.TEMPLATE === this.product.type;
    },
  },
  async mounted() {
    if (!this.showApps) {
      MessageBox.alert(
        this.$t("product.versions.releaseMandatory"),
        {
          callback: () =>
            this.$router.push({
              name: "product-releases",
              params: { productId: this.product.id },
              query: this.$route.query,
            })
        }
      );
    }
  },
};
</script>

<style scoped lang="scss">
.row,
.app {
  min-height: calc(100% - 48px);
  margin-top: 12px;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;

  .row {
    flex: 1;
  }
}

.close {
  padding: 1em;
}
</style>
