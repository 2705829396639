<template>
  <el-radio-group
    v-if="current || mode === 'form'"
    ref="input"
    v-model="value"
    :data-cy="`input-${question.id}`"
    class="question boolean-question"
    :style="{ flexFlow: `${mode === 'widget' ? 'column' : 'row'} nowrap` }"
    @keydown.enter.exact.native.prevent.stop="submit(true)"
    @change="
      $emit('show');
      setValue($event);
    "
    @keydown.tab.native.capture="captureTab($event)"
    @focus="$emit('focus')"
  >
    <template v-for="(answer, index) in sortedAnswers">
      <el-radio-button
        :key="`${index + 1}-keyboard`"
        :ref="answer.id"
        :label="answer.id"
        :disabled="question.loading"
        :value="answer.id"
        style="flex: 1"
        :data-cy="`answer-${question.id}-${answer.id}`"
        border
        @click.native.prevent.capture="
          $emit('show');
          setValue(answer.id);
          onClick($event);
        "
      >
        <keyboard-shortcut
          :key-code="index + 49"
          :label="`${index + 1}`"
          :loading="question.loading && question.response.id === answer.id"
          :active="current"
          @action="
            $emit('show');
            setValue(answer.id);
            submit();
          "
        >
          <span class="center">{{ $t(answer.displayName) }}</span>
        </keyboard-shortcut>
      </el-radio-button>
    </template>
    <el-radio-button
      v-if="mode === 'form'"
      :key="`3-keyboard`"
      ref="na"
      class="radio-button"
      :disabled="question.loading"
      :label="undefined"
      :value="undefined"
      style="flex: 1"
      :data-cy="`answer-${question.id}-na`"
      border
      @click.native.prevent.capture="
        $emit('show');
        setValue(undefined);
        onClickClear($event);
      "
    >
      <keyboard-shortcut
        :key-code="52"
        :label="`52`"
        :loading="question.loading && question.response.id === answer.id"
        :active="current"
        @action="
          $emit('show');
          setValue(undefined);
          submitClear();
        "
      >
        <span class="center">N/A</span>
      </keyboard-shortcut>
    </el-radio-button>
  </el-radio-group>
</template>
<script>
import KeyboardShortcut from "./KeyboardShortcut.vue";
import captureTabMixin from "./mixins/captureTabMixin";
import inputMixin from "./mixins/inputMixin";

export default {
  name: "InputBooleanComponent",
  components: { KeyboardShortcut },
  mixins: [captureTabMixin, inputMixin],
  computed: {
    sortedAnswers() {
      if (this.question.answers) {
        return [...this.question.answers].sort((a, b) =>
          a.displayName.toString().localeCompare(b.displayName)
        );
      }
      return [];
    }
  },
  methods: {
    async focusInput() {
      await this.$nextTick();
      const refs = this.$refs[
        this.value || (this.question.answers && this.question.answers[0].id)
      ];
      if (refs && refs[0]) {
        refs[0].$el.focus({
          preventScroll: true
        });
      }
    },
    onClick(e) {
      // ElementUI uses a faake MouseEevent on keyboard navigation in radio-groups
      // This is a workaround to submit only on REAL click
      if (
        (e.toElement && e.toElement.nodeName === "SPAN") ||
        (e.target && e.target.nodeName === "SPAN")
      ) {
        this.submit();
      }
    },
    onClickClear(e) {
      // ElementUI uses a faake MouseEevent on keyboard navigation in radio-groups
      // This is a workaround to submit only on REAL click
      if (
        (e.toElement && e.toElement.nodeName === "SPAN") ||
        (e.target && e.target.nodeName === "SPAN")
      ) {
        this.submitClear();
      }
    }
  }
};
</script>

<style lang="scss">
// @import "../theme/radio.css";
// @import "../theme/radio-group.css";
// @import "../theme/radio-button.css";
</style>

<style lang="scss" scoped>
.button-enum {
  text-align: left;
  width: 100%;
}
.question {
  max-width: 100%;
}

.boolean-question {
  display: flex;
  justify-content: stretch;
  width: 175px;
  max-width: 175px;

  & > * {
    margin: 4px !important;
  }

  ::v-deep .el-radio-button__inner {
    width: 100%;
    max-width: 100%;
    border: solid 1px rgb(220, 223, 230);
    border-radius: var(--border-radius-base, 4px);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 12px 0;
  }

  .center {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}
</style>
